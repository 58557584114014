import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getAllCourses, getCoursesByIds } from "../../../redux/coursesSlice";
import { toast } from "react-toastify";
import LoadingButton from "../../../shared/comp/LoadingButton";
import EachCourseSem from "./EachCourseSem";

const AddSemester = ({ groupCode, majorCourses, setMajorCourses, onClose }) => {
  const [semesterSelections, setSemesterSelections] = useState(
    majorCourses || {}
  );
  const majorCoursesIds = majorCourses ? Object.keys(majorCourses) : [];
  const initialCourses = useSelector((state) =>
    getCoursesByIds(state, majorCoursesIds)
  );
  const [loading, setLoading] = useState(false);
  const allCourses = useSelector(getAllCourses);
  const courses = allCourses.filter(
    (course) => course.info.status === "published"
  );

  const sortedCourses = courses.slice().sort((a, b) => {
    const semesterA =
      semesterSelections[a.info.courseId]?.suggestedSem || Infinity;
    const semesterB =
      semesterSelections[b.info.courseId]?.suggestedSem || Infinity;
    return semesterA - semesterB;
  });

  const sendData = () => {
    setLoading(true);
    setMajorCourses(semesterSelections);
    onClose();
    toast("Submit Data To Register Changes");
  };

  return (
    <Box className="w-full h-full p-5 pb-20">
      <Box className="h-full border rounded-md overflow-auto">
        {/* Header Row */}
        <Box
          className="flex h-12 bg-gray-200 items-center px-4 font-semibold sticky top-0"
          sx={{
            backgroundColor: "gray.200",
            zIndex: 1,
          }}
        >
          <Box className="w-1/12">Image</Box>
          <Box className="w-1/12">Group Code</Box>
          <Box className="w-2/12">Title</Box>
          <Box className="w-2/12">Name</Box>
          <Box className="w-2/12">Categories</Box>
          <Box className="w-2/12 text-center">Suggested Semester</Box>
          <Box className="w-2/12 text-center">Select Course</Box>
        </Box>

        {/* Data Rows */}
        {sortedCourses.map((course) => (
          <EachCourseSem
            key={course.info.courseId}
            course={course}
            semesterSelections={semesterSelections}
            setSemesterSelections={setSemesterSelections}
          />
        ))}
      </Box>
      <div className="flex items-center justify-center mt-4">
        <div onClick={sendData}>
          <LoadingButton
            loading={loading}
            name={"Set courses for major"}
            disabled={
              JSON.stringify(semesterSelections) ===
              JSON.stringify(majorCourses)
            }
          />
        </div>
      </div>
    </Box>
  );
};

export default AddSemester;
