// icons.js
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/free-solid-svg-icons";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SchoolIcon from "@mui/icons-material/School";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LockClockIcon from "@mui/icons-material/LockClock";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TableViewIcon from "@mui/icons-material/TableView";
import CategoryIcon from "@mui/icons-material/Category";
import TranslateIcon from "@mui/icons-material/Translate";
import BiotechIcon from "@mui/icons-material/Biotech";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import SourceIcon from "@mui/icons-material/Source";
import PersonIcon from '@mui/icons-material/Person';
const icons = {
  PersonIcon,
  PeopleAltIcon,
  FontAwesomeIcon,
  faPenNib,
  WorkspacesIcon,
  SchoolIcon,
  CastForEducationIcon,
  AttachmentIcon,
  AssessmentIcon,
  NotificationsIcon,
  LibraryBooksIcon,
  LockClockIcon,
  PeopleOutlineIcon,
  AssignmentIcon,
  TableViewIcon,
  CategoryIcon,
  TranslateIcon,
  BiotechIcon,
  KeyboardIcon,
  HistoryEduIcon,
  SourceIcon
};

export default icons
