import { useEffect, useState } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useLocation, useParams } from "react-router-dom";
import ShowModalGroupButton from "../../sharedComp/ShowModalGroupButton";
const ImageShow = ({ imageData }) => {
  const images = Object.values(imageData.files);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // useEffect(() => {
  //   const getUrl = async () => {
  //     console.log(imageData.file.path.image);
  //     const imageBlob = await getUrlBlob(imageData.file.path.image);
  //     // console.log(imageBlob);
  //     const url = URL.createObjectURL(imageBlob);
  //     setImageUrl(url);
  //   };
  //   getUrl();
  // }, [imageData.file.path.image]);

  return (
    <div>
      {images && (
        <div>
          <div className="m-auto w-96">
            <Swiper navigation={true} modules={[Navigation]}>
              {images?.map((image, index) => (
                <SwiperSlide>
                  <div className="flex flex-col items-center">
                    <img
                      className="w-96"
                      key={image?.name + index}
                      src={image?.url}
                      alt={image?.name}
                    />
                    <p>{`${image?.name} (${image?.number})`} </p>
                  </div>
                  {/* <Button onClick={openModal}>View</Button>
        <ViewFile isOpen={isModalOpen} onClose={closeModal}>
          <PdfShow path={pdf?.path?.pdf} pagesCount={pdf?.pagesCount} />
        </ViewFile> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Show Metadata
      </Button>
      <ShowMetadata
        props={{ ...imageData.info }}
        modalControl={{ open, handleClose }}
      /> */}
      <div className="flex justify-center mt-8">
        <ShowModalGroupButton
          itemId={imageData.info.imageId}
          type={"image"}
          itemCreatorId={imageData.info.creatorId}
          groupCode={imageData.info.groupCode}
        />
      </div>
    </div>
  );
};

export default ImageShow;
