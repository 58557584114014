import { MaterialReactTable } from "material-react-table";
import { Box, Button } from "@mui/material";

import ShowModalGroupButton from "../../sharedComp/ShowModalGroupButton";
import { useState } from "react";

const TableShow = ({ table }) => {
  const data = table.data;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addedChangeToHeader = table?.headerData?.map((item) => ({
    ...item,
    enableSorting: true,
  }));
  // const [columns, setColumns] = useState(addedChangeToHeader);

  return (
    <div className="w-11/12 m-auto" dir={table.info.direction || "ltr"}>
      <MaterialReactTable
        // autoResetPageIndex={false}
        enableMultiSort
        enableSorting={true}
        columns={addedChangeToHeader?.map((column, index) => ({
          ...column,
          header: (
            <Box display="flex" alignItems="center" key={index}>
              {column.header}
            </Box>
          ),
        }))}
        data={data}
        getRowId={(row) => row.id}
        initialState={{ columnVisibility: { id: false } }}
      />
      <ShowModalGroupButton
        itemId={table.info.tableId}
        type={"table"}
        itemCreatorId={table.info.creatorId}
        groupCode={table.info.groupCode}
      />
    </div>
  );
};

export default TableShow;
