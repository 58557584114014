import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const tablesAdapter = createEntityAdapter();

const initialState = tablesAdapter.getInitialState();

const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    addtables: tablesAdapter.setAll,
    createEmptyTable: tablesAdapter.setOne,
    updateImages: (state, { payload }) => {
      const { tableId, images } = payload;
      const entity = state.entities[tableId];
      entity.files.images = images;
    },
    updateVideoId: (state, { payload }) => {
      const { tableId, video } = payload;
      const entity = state.entities[tableId];
      entity.files.video = video;
    },
    updateTable: tablesAdapter.updateOne,
    updateTableStatus: (state, { payload }) => {
      const { tableId, status } = payload;
      state.entities[tableId].info.status = status;
    },
  },
});

export const {
  addtables,
  createEmptyTable,
  removeNotPublishedTable,
  updatePdfId,
  updateVideoId,
  updateImages,
  updateTable,
  updateTableStatus,
} = tablesSlice.actions;
export const getAllTables = (state) =>
  tablesAdapter
    .getSelectors()
    .selectAll(state.tables)
    .sort((a, b) => b.info.createAt - a.info.createAt);

export const getTableById = (state, tableId) =>
  tablesAdapter.getSelectors().selectById(state.tables, tableId);

export const getTableEntities = (state) =>
  tablesAdapter.getSelectors().selectEntities(state.tables);

export const getTablesByIds = createSelector(
  [getTableEntities, (state, ids) => ids],
  (entities, ids) => ids.map((id) => entities[id])
);
export default tablesSlice.reducer;
