import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import ViewFile from "../../../layout/ViewFile";
import PuShowAllCourses from "./PuShowAllCourse";
import PuShowAllInstructors from "./PuShowAllInstructors";

const ALLPublicInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [comp, setComp] = useState("");

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div>
        <Typography>Select courses that must be shown at home page</Typography>
        <Button
          onClick={() => {
            setIsOpen(true);
            setComp(<PuShowAllCourses />);
          }}
        >
          show all courses
        </Button>
        <ViewFile
          isOpen={isOpen}
          onClose={onClose}
          title={"Select Home Page Courses"}
        >
          {comp}
        </ViewFile>
      </div>
      <div>
        <Typography>
          Select instructor that must be shown at home page
        </Typography>
        <Button
          onClick={() => {
            setIsOpen(true);
            setComp(<PuShowAllInstructors />);
          }}
        >
          show all instructor
        </Button>
        <ViewFile
          isOpen={isOpen}
          onClose={onClose}
          title={"Select Home Page Courses"}
        >
          {comp}
        </ViewFile>
      </div>
    </div>
  );
};

export default ALLPublicInfo;
