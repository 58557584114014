import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCourseById } from "../../../redux/coursesSlice";
import ReactQuill from "react-quill";
import { getUserById, getUsersByIds } from "../../../redux/usersSlice";
import { getLessonsByIds } from "../../../redux/lessonsSlice";
import TimeShow from "../../../shared/utils/TimeShow";
import ViewAddedResources from "../sharedComp/showResources/ViewAddedResources";

const ReviewCourse = () => {
  const { courseId } = useParams();
  const course = useSelector((state) => getCourseById(state, courseId));

  const { title, explain, createAt, editAt, status } = course.info;
  const { duration, durationUnit, prerequisites, level, unit } = course.general;
  // const { courseCreator, instructors } = course.author;
  const assignedStudents = course.assignedStudents
    ? Object.keys(course.assignedStudents)
    : [];
  console.log(course.info.creatorId);
  const courseCreator = useSelector((state) =>
    getUserById(state, course.info.creatorId)
  );

  const instructorsId = course.assignedInstructor
    ? Object.keys(course.assignedInstructor)
    : [];

  const instructorsArr = useSelector((state) =>
    getUsersByIds(state, instructorsId)
  );

  const assignedLessons = course.assignedLessons
    ? Object.keys(course.assignedLessons)
    : [];
  const StuAsFull = useSelector((state) =>
    getUsersByIds(state, assignedStudents)
  );
  const lsnAsFull = useSelector((state) =>
    getLessonsByIds(state, assignedLessons)
  );
  console.log(StuAsFull);
  return (
    <Card className="w-11/12 mx-auto my-4">
      <CardContent>
        {/* Group One */}
        <Typography variant="h5" component="div" className="text-center">
          {title}
        </Typography>
        <div className="flex">
          <div className="w-2/3">
            <Grid container spacing={1} className="pt-8">
              <Grid item xs={6}>
                <Typography variant="body2">Course ID: {courseId}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Publish Date: <TimeShow date={new Date(createAt)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Edit At: <TimeShow date={new Date(editAt)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="flex gap-x-3">
                  Create At:
                  <TimeShow date={new Date(createAt)} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Status: {status}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Necessity: {course?.general?.necessity}
                </Typography>
              </Grid>
            </Grid>
            {/* Group Two */}
            <Typography variant="h6" component="div" className="pt-8">
              Course Details
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Duration: {duration} {durationUnit}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Prerequisites: {prerequisites}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Level: {level}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Unit: {unit}</Typography>
              </Grid>
            </Grid>
            {/* Group Three */}
            {/* <Typography variant="h6" component="div" className="pt-8">
              Pricing & Dates
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Regular Price: {course?.pricing?.regularPrice}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Sale Price: {course?.pricing?.salePrice}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Start Date: {course?.pricing?.startDate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  End Date: {course?.pricing?.endDate}
                </Typography>
              </Grid>
            </Grid> */}
            <Typography variant="h6" component="div" className="pt-8">
              Course Creators
            </Typography>
            <Grid container spacing={1} className="mt-2">
              <Grid item xs={6}>
                <Typography variant="body2">
                  Course Creator: {courseCreator?.profile?.name}{" "}
                  {courseCreator?.profile?.family}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Instructors:{" "}
                  {instructorsArr?.map((ins) => (
                    <span>
                      {ins?.profile?.name} {ins?.profile?.family}
                    </span>
                  ))}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" component="div" className="pt-8">
              Assigned Students
            </Typography>
            <Grid container spacing={2} className="mt-2">
              {StuAsFull.map((student) => (
                <Grid item xs={6}>
                  {/* <Typography variant="body2">{student.email}</Typography> */}
                  <div className="text-xs text-slate-500" variant="body2">
                    {student?.profile?.email}
                  </div>
                </Grid>
              ))}
            </Grid>
            <Typography variant="h6" component="div" className="pt-8">
              Assigned Lessons
            </Typography>
            <Grid container spacing={1} className="mt-2">
              {lsnAsFull.map((lesson) => (
                <Grid item xs={6}>
                  {/* <Typography variant="body2">{lesson.title}</Typography> */}
                  <div variant="body2" className="text-xs text-slate-500">
                    {lesson?.info?.title}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="w-1/3">
            <img
              src={course?.files?.courseImage?.url}
              alt={title}
              className="w-full h-64 object-contain"
            />
          </div>
        </div>
        {/* Group Four */}
        <Typography variant="h4" className="text-center">
          {course.info?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Explain: <ReactQuill value={explain} readOnly={true} theme="bubble" />
        </Typography>
        <ViewAddedResources content={course} />
      </CardContent>
    </Card>
  );
};

export default ReviewCourse;
