import React from 'react';
import ReactDOM from 'react-dom/client';
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import './index.css';
import App from './App';
import "./css/layout.css"
import "./css/home.css"
import "./css/utils.css"
import "./css/library.css"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './redux/store';


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>

    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
    {/* </CourseContextProvider>
    </UserContextProvider> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

