import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Login from "./pages/auth/login/Login"
import AdminPanel from './panels/layout/adminLayout/AdminPanel';
import ChangePassword from './pages/auth/ChangePassword';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { db, userAuthChange } from './firebase.config';
import { getUserProfile } from './redux/userSlice';
import { get, onValue, ref, set, update } from 'firebase/database';
import StudentPanel from './panels/layout/studentLayout/StudentPanel';
import ViewFile from './layout/ViewFile';
import { addgroups } from './redux/groupsSlice';
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandlerComp from './errorHandler/ErrorHandlerComp';
import { toast } from 'react-toastify';
import InstructorPanel from './panels/layout/instructorLayout/InstructorPanel';
import axios from 'axios';
import NotFound from './pages/NotFound';
import About from './pages/InformationPages/About';
import TermsOfService from './pages/InformationPages/TermsOfService';
import PrivacyPolicy from './pages/InformationPages/PrivacyPolicy';
import GeneralPanel from './pages/GeneralPanel';
import LeadInstructorPanel from './panels/layout/leadInstructorLayout/LeadInstructorPanel';
import Home from './pages/home/Home';
import { addPublicInfo } from './redux/publicInfoSlice';
import LoadingOverlay from './shared/LoadingOverlay';
import { addCourses } from './redux/coursesSlice';
import { addUsers } from './redux/usersSlice';
import { setImages } from './redux/allImagesSlice';
import { setPdfs } from './redux/allPdfsSlice';
import { setVideos } from './redux/allVideosSlice';
import { addtables } from './redux/tablesSlice';
import StaffPanel from './panels/layout/staffLayout/StaffPanel';
// import InstructorPanel from './panels/instructor/instructorLayout/InstructorPanel';


function App() {
  const [loading, isLoading] = useState(true)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const profile = user?.profile
  // window.addEventListener('unhandledrejection', (event) => {
  //   console.error('Unhandled promise rejection:', event.promise, 'reason:', event.reason);
  //   // Handle the error globally
  // });





  useEffect(() => {
    const unsubscribe = userAuthChange(async (currentUser) => {
      // console.log(user.currentUser.stsTokenManager.refreshToken, user.currentUser.stsTokenManager.accessToken)

      //------use custom claim in client side
      // const idTokenResult = await currentUser.getIdTokenResult();
      // console.log('Custom claims:', idTokenResult.claims);
      // const createData = async () => {
      //   const currentuser = getUser()
      //   console.log(currentuser)
      //   const usersRef = ref(db, "/users/kwXxdmxvwYP0goeVhNVyKetpQov1")
      //   await set(usersRef, {
      //     profile: {
      //       name: "kourosh",
      //       family: "masoumi",
      //       email: "admin@cyrusuniversity.org",
      //       role: "admin",
      //       uid: "kwXxdmxvwYP0goeVhNVyKetpQov1",
      //       photoUrl: "",
      //       createAt: serverTimestamp(),
      //       lastLogin: serverTimestamp()
      //     }, address: {},
      //   })
      // }
      // createData()
      //temp admin creator
      if (!currentUser) return

      //?
      if (!profile?.email) {
        dispatch(getUserProfile(currentUser))
      }
    })
    return unsubscribe
  }, [dispatch, profile?.email])

  useEffect(() => {
    const resRef = ref(db, "/users");
    return onValue(
      resRef,
      (snap) => {
        if (snap.val()) {
          dispatch(addUsers(snap.val()));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const groupRef = ref(db, "/groups");
    return onValue(groupRef, (snap) => {
      const groupsArray = [];
      if (snap.val()) {
        Object.keys(snap.val()).forEach((key) => {
          groupsArray.push({
            id: key,
            ...snap.val()[key],
          });
        });
        dispatch(addgroups(groupsArray));
      }
    },
      (error) => {
        toast.error(error.message);
        console.log(error);
      });
  }, [dispatch]);



  useEffect(() => {
    const resRef = ref(db, "/courses");
    return onValue(
      resRef,
      (snap) => {
        const coursesInGroup = Object.values(snap.val());

        const courses = coursesInGroup
          .map((g) => {
            if (g) return Object.values(g);
          })
          .flat()
          .filter((course) => course);
        courses && dispatch(addCourses(courses));
      },
      []
    );
  });


  useEffect(() => {
    const resRef = ref(db, "/resources");
    return onValue(
      resRef,
      (snap) => {
        let images = {};
        let videos = {};
        let pdfs = {};
        let tables = {};
        const resource = snap.val();
        images = resource.images;
        videos = resource.video;
        pdfs = resource.pdf;
        tables = resource.table;

        images && dispatch(setImages(images));
        pdfs && dispatch(setPdfs(pdfs));
        videos && dispatch(setVideos(videos));
        tables && dispatch(addtables(tables));
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorHandlerComp} onReset={() => {
      window.location.href = '/';
    }}>
      <Router>
        <Routes>
          {/* <Route path='/viewfile' element={<ViewFile />} /> */}
          {user?.profile?.role === "admin" && <Route path='/adminpanel/*' element={<AdminPanel />} />}
          {user?.profile?.role === "student" && <Route path='/studentpanel/*' element={<StudentPanel />} />}
          {user?.profile?.role === "instructor" && <Route path='/instructorpanel/*' element={<InstructorPanel />} />}
          {user?.profile?.role === "leadInstructor" && <Route path='/leadInstructorpanel/*' element={<LeadInstructorPanel />} />}
          {user?.profile?.role === "staff" && <Route path='/staffpanel/*' element={<StaffPanel />} />}
          <Route path="/login" element={<Login />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path='/*' element={<GeneralPanel />} />
          {/* <Route element={<Layout />}>
          <Route path='/register' element={<Register />} />
          <Route index element={<Home />} />
          <Route path='/resourses/*' element={<Resourses />} />
        </Route> */}
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;


// const ccccc = async () => {
//   if (currentUser) {
//     const userRef = ref(db, "/users/" + currentUser.uid)
//     await set(userRef, {
//       profile: {
//         name: "koroush",
//         family: "masoumi",
//         email: "admin@cyrusuniversity.org",
//         role: "admin",
//         photoUrl: "",
//         uid: "MzVec4ZBdgXstVEjUnnqrQ1KCNn1"
//       }
//     })
//   }
// }
// ccccc()