import React, { useEffect, useState } from "react";
import {
  Paper,
  Button,
  Box,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AttachFile,
  Image,
  VideoLibrary,
  TableChart,
} from "@mui/icons-material";
import ShowResources from "../../resourses/ShowResources";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoItem from "../../resourses/LogoItem";
import { useSelector } from "react-redux";
import { selectPdfById } from "../../../../redux/allPdfsSlice";
import { selectVideoById } from "../../../../redux/allVideosSlice";
import { selectImageById } from "../../../../redux/allImagesSlice";
import ShowAllTables from "../../resourses/tables/ShowAllTables";
import { getTableById } from "../../../../redux/tablesSlice";
import TableShow from "../../resourses/showFile/TableShow";

const EachResource = ({ resource }) => {
  const [selectTableRs, setSelectTableRs] = useState(false);

  const pdf = useSelector((state) => selectPdfById(state, resource.id));
  const video = useSelector((state) => selectVideoById(state, resource.id));
  const image = useSelector((state) => selectImageById(state, resource.id));
  const table = useSelector((state) => getTableById(state, resource.id));

  const [itemProps, setItemProps] = useState({
    src: "",
    item: "",
    id: "",
    type: "",
  });

  useEffect(() => {
    if (pdf) {
      setItemProps({
        src: pdf.file?.url.image,
        item: pdf,
        id: pdf.info.pdfId,
        type: "pdf",
      });
    } else if (image) {
      setItemProps({
        src: image?.files && Object.values(image?.files)[0].url,
        item: image,
        id: image.info.imageId,
        type: "image",
      });
    } else if (video) {
      setItemProps({
        src: video.file?.url.image,
        item: video,
        id: video.info.videoId,
        type: "video",
      });
    }
  }, [image, pdf, video]);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {resource.id && (
        <Paper
          elevation={1}
          sx={{
            padding: 5,
            marginBottom: "20px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {table ? (
            <TableShow table={table} />
          ) : video ? (
            <LogoItem
              src={itemProps.src}
              size={800}
              item={itemProps.item}
              id={itemProps.id}
              type={itemProps.type}
            />
          ) : (
            <LogoItem
              src={itemProps.src}
              size={800}
              item={itemProps.item}
              id={itemProps.id}
              type={itemProps.type}
            />
          )}

          <Modal
            open={open}
            aria-labelledby="modal-file"
            aria-describedby="modal-file"
            sx={{ zIndex: "20" }}
          >
            <div>
              <Box
                sx={{
                  position: "relative",
                  right: "0px",
                  margin: "auto",
                  // transform: "translate(-10%, -10%)",
                  width: "100%",
                  height: "100vh",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                  overflowY: "auto",
                }}
              >
                {selectTableRs ? <ShowAllTables /> : <ShowResources />}
                <Button
                  sx={{ position: "absolute", top: "10px", left: "10px" }}
                  onClick={handleClose}
                >
                  Back to Lesson
                </Button>
              </Box>
            </div>
          </Modal>
        </Paper>
      )}
    </>
  );
};

export default EachResource;
