import React, { useState } from "react";
import { Box, Typography, Button, Stack, Avatar } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getAllCourses } from "../../../redux/coursesSlice";
import { sendHomeData } from "../../../firebase.config";
import { toast } from "react-toastify";
import LoadingButton from "../../../shared/comp/LoadingButton";

const PuShowAllCourse = () => {
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const courses = useSelector(getAllCourses);
  const toggleCourseCheck = (course) => {
    setCheckedItems((prevCheckedItems) => {
      const isChecked = prevCheckedItems.some(
        (item) => item?.info?.courseId === course?.info?.courseId
      );
      if (isChecked) {
        // Uncheck: Filter out the item
        return prevCheckedItems.filter(
          (item) => !(item?.info?.courseId === course?.info?.courseId)
        );
      } else {
        // Check: Add the item
        return [...prevCheckedItems, course];
      }
    });
  };

  const isChecked = (courseId) => {
    return checkedItems.some((item) => item?.info?.courseId === courseId);
  };

  const sendData = async () => {
    try {
      setLoading(true);
      await sendHomeData(checkedItems, "/publicInfo/homeCourses");
      toast.success("saved data successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="w-full">
      <Box className="h-96 border rounded-md overflow-auto">
        {/* Header Row */}
        <Box className="flex h-12 bg-gray-200 items-center px-4 font-semibold">
          <Box className="w-1/12">Image</Box>
          <Box className="w-2/12">Title</Box>
          <Box className="w-2/12">Name</Box>
          <Box className="w-2/12">Categories</Box>
          <Box className="w-2/12">Group Code</Box>
          <Box className="w-2/12 text-center">Select</Box>
        </Box>

        {/* Data Rows */}
        {courses.map((course) => (
          <Box
            key={course.info.courseId}
            className="flex h-12 border-b items-center px-4 hover:bg-gray-100"
          >
            {/* Image */}
            <Box className="w-1/12">
              <Avatar
                src={course.files.courseImage.url}
                alt={course.info.title}
                variant="rounded"
                className="h-10 w-10"
              />
            </Box>

            {/* Title */}
            <Box className="w-2/12">
              <Typography variant="body2">{course.info.title}</Typography>
            </Box>

            {/* Name */}
            <Box className="w-2/12">
              <Typography variant="body2">{course.info.name}</Typography>
            </Box>

            {/* Categories */}
            <Box className="w-2/12">
              <Typography variant="body2">{course.categoriesName}</Typography>
            </Box>

            {/* Group Code */}
            <Box className="w-2/12">
              <Typography variant="body2">{course.info.groupCode}</Typography>
            </Box>

            {/* Toggle Button */}
            <Box className="w-2/12 text-center">
              <Button
                variant="contained"
                color={isChecked(course.info.courseId) ? "success" : "primary"}
                onClick={() => toggleCourseCheck(course)}
                size="small"
                className="capitalize"
                startIcon={
                  isChecked(course.info.courseId) ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )
                }
              >
                {isChecked(course.info.courseId) ? "Checked" : "Check"}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <div className="flex items-center justify-center mt-4 ">
        <div onClick={sendData}>
          <LoadingButton
            loading={loading}
            name={"set home page courses"}
            disabled={checkedItems?.length === 0}
          />
        </div>
      </div>
    </Box>
  );
};

export default PuShowAllCourse;
