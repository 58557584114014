import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getCoursesByIds } from "../../redux/coursesSlice";
import { useSelector } from "react-redux";
import CourseRowSt from "./CourseRowSt";

const ShowCourses = () => {
  //   const { courseId } = useParams();
  const user = useSelector((state) => state.user);

  // const course = useSelector((state) => getCourseById(state, courseId));
  const courses = useSelector((state) =>
    getCoursesByIds(
      state,
      user.assignedCourses && Object.keys(user.assignedCourses)
    )
  );
  // const courses = [
  //   {
  //     id: 1,
  //     instructor: "John Doe",
  //     categories: "Programming",
  //     publishDate: "2023-01-01",
  //     duration: "10 hours",
  //     prerequisites: "Basic programming knowledge",
  //     level: "Intermediate",
  //     unit: "Unit 1",
  //     grade: "A",
  //     status: "Completed",
  //   },
  //   // Add more courses as needed
  // ];
  return (
    <Container>
      <TableContainer component={Paper} className="mt-8">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Instructor</TableCell>
              {/* <TableCell>Categories</TableCell> */}
              {/* <TableCell>Publish Date</TableCell> */}
              <TableCell>Duration</TableCell>
              {/* <TableCell>Prerequisites</TableCell> */}
              <TableCell>Level</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>View Course</TableCell>
              <TableCell>Course Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses?.map((course, index) => (
              <CourseRowSt course={course} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ShowCourses;
