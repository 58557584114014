import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getLessonById,
  updateLessonStatus,
  uploadResourceId,
} from "../../../redux/lessonsSlice";
import { useDispatch } from "react-redux";
import {
  addResourceToLCADB,
  createEmptyLessonInDb,
  deleteRealItem,
  deleteStorageRecursively,
} from "../../../firebase.config";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  getCourseById,
  uploadResourceIdCourse,
} from "../../../redux/coursesSlice";
import {
  getAssignmentById,
  uploadResourceIdAssignment,
} from "../../../redux/assignmentsSlice";
const ShowModalGroupButton = ({ itemId, type, itemCreatorId, groupCode }) => {
  const user = useSelector((state) => state.user);
  const role = user.profile.role;
  const { lessonId, resourceId, courseId, assignmentId } = useParams();
  const relatedId = lessonId || courseId || assignmentId;
  const location = useLocation();
  const lesson = useSelector((state) => getLessonById(state, relatedId));
  const course = useSelector((state) => getCourseById(state, relatedId));
  const assignment = useSelector((state) =>
    getAssignmentById(state, relatedId)
  );
  let sectionPart = "";

  if (lessonId) {
    sectionPart = "lesson";
  } else if (courseId) {
    sectionPart = "course";
  } else if (assignmentId) {
    sectionPart = "assignment";
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alreadyExistResource =
    (lesson?.resources &&
      Object.values(lesson?.resources).find(
        (resource) => resource.id === itemId
      )) ||
    (course?.resources &&
      Object.values(course?.resources).find(
        (resource) => resource.id === itemId
      )) ||
    (assignment?.resources &&
      Object.values(assignment?.resources).find(
        (resource) => resource.id === itemId
      ));
  const addResourceToLesson = async () => {
    const resourceData = { type, id: itemId, resourceId };
    // if (lesson.info.status === "temp") {
    //   dispatch(updateLessonStatus({ lessonId, status: "draft" }));
    //   await createEmptyLessonInDb({ lesson });
    // }

    try {
      let groupCode;
      if (lessonId) {
        groupCode = lesson.info.groupCode;
        dispatch(
          uploadResourceId({
            lessonId,
            resourceId,
            item: resourceData,
          })
        );
      } else if (courseId) {
        groupCode = course.info.groupCode;
        dispatch(
          uploadResourceIdCourse({
            courseId,
            resourceId,
            item: resourceData,
          })
        );
      } else if (assignmentId) {
        groupCode = assignment.info.groupCode;
        dispatch(
          uploadResourceIdAssignment({
            assignmentId,
            resourceId,
            item: resourceData,
          })
        );
      }
      await addResourceToLCADB({
        resourceData,
        sectionId: relatedId,
        sectionName: sectionPart + "s",
        groupCode,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const reqToRemove = async () => {
    if (!itemId || !type) throw new Error("id not found");
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await deleteRealItem(`/resources/${type}/${itemId}`);
        await deleteStorageRecursively(`/resources/${type}/${itemId}`);
        toast.success("successfully deleted");
        navigate(`/${user.profile.role}panel/resources/${type}`);
      } catch (error) {
        toast.error(error.message);
      }

      // if (response.status === 200) {
      //   // setPdfsData((prev) => prev.filter((pdf) => pdf.id !== item.id));
      //   toast.success(response.message);
      // } else {
      //   toast.error(response.message);
      // }
    }
  };
  const goToEditItem = () => {
    navigate(
      `/${user.profile.role}panel/resources/${type}/addnew${type}/${itemId}`
    );
  };

  return (
    <div>
      {location.pathname.includes(
        `/${user.profile.role}panel/addnew${sectionPart}/${relatedId}/${resourceId}`
      ) ||
      location.pathname.includes(
        `/${user.profile.role}panel/edit${sectionPart}/${relatedId}/${resourceId}`
      ) ? (
        <Button onClick={addResourceToLesson}>
          {alreadyExistResource
            ? `${sectionPart} resource ${alreadyExistResource.resourceId}`
            : " set as resource"}
        </Button>
      ) : (
        ""
      )}

      {location.pathname.includes(`/adminpanel/resources/`) &&
        ((role === "instructor" && user.profile.uid === itemCreatorId) ||
          role === "admin" ||
          (role === "leadInstructor" &&
            user.profile.uid === itemCreatorId)) && (
          <>
            <button onClick={goToEditItem}>
              Edit <EditIcon size="md" />
            </button>
            <button onClick={reqToRemove}>
              <DeleteIcon className="text-cu-red" />
            </button>
          </>
        )}
    </div>
  );
};

export default ShowModalGroupButton;
