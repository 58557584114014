import { IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";

const TextAreaNotificationComp = ({ props }) => {
  const [inputErr, setInputErr] = useState(false);

  const {
    title,
    setTitle,
    inputDirection,
    setInputDirection,
    text,
    setText,
    textDirection,
    setTextDirection,
  } = props;

  return (
    <div>
      <div className="relative">
        <TextField
          className="w-full"
          sx={{ marginBottom: "10px" }}
          size="small"
          label="Title"
          value={title}
          dir={inputDirection}
          onChange={(e) => {
            setTitle(e.target.value);
            setInputErr(e.target.value.length < 4);
            // dispatch(addLessonTitle(e.target.value));
          }}
          error={inputErr}
          helperText={
            inputErr ? `Title must be at least 4 characters long` : ""
          }
          InputProps={{
            style: {
              paddingRight: "40px", // Adjust the padding as needed
            },
          }}
        />

        {inputDirection === "ltr" ? (
          <IconButton
            color="primary"
            sx={{ position: "absolute", top: 0, right: 0, fontSize: "14px" }}
            onClick={() => {
              setInputDirection("rtl");
            }}
          >
            <FormatTextdirectionLToRIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setInputDirection("ltr");
            }}
            color="primary"
            sx={{ position: "absolute", top: 0, right: 0, fontSize: "14px" }}
          >
            <FormatTextdirectionRToLIcon />
          </IconButton>
        )}
      </div>
      <div className="relative">
        <TextField
          label="Text"
          className="w-full border"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          multiline
          rows={20}
          style={{ whiteSpace: "pre-wrap" }}
          dir={textDirection}
          InputProps={{
            style: {
              paddingRight: "40px", // Adjust the padding as needed
            },
          }}
        />

        {textDirection === "ltr" ? (
          <IconButton
            color="primary"
            sx={{ position: "absolute", top: 0, right: 0, fontSize: "14px" }}
            onClick={() => {
              setTextDirection("rtl");
            }}
          >
            <FormatTextdirectionLToRIcon />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            sx={{ position: "absolute", top: 0, right: 0, fontSize: "14px" }}
            onClick={() => {
              setTextDirection("ltr");
            }}
          >
            <FormatTextdirectionRToLIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default TextAreaNotificationComp;
