import React from "react";
import { useSelector } from "react-redux";
import { getCourseById } from "../../../redux/coursesSlice";

const EachCourseDemo = ({ courseId }) => {
  const course = useSelector((state) => getCourseById(state, courseId));

  return (
    <div className="flex justify-between">
      <p>{courseId}</p>
      <p>{course.info.title}</p>
    </div>
  );
};

export default EachCourseDemo;
