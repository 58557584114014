import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { getDocument } from "pdfjs-dist/build/pdf";
import { v4 as uuidv4 } from "uuid";

import { pdfjs } from "react-pdf";
import {
  deleteStorageRecursively,
  updatePdfRealPath,
  updateRegisteryForm,
} from "../../../../firebase.config";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserById } from "../../../../redux/usersSlice";
import CircularPercentage from "../CircularPercentage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const EachPdf = ({ file, stPath, realPath }) => {
  const [fileSent, setFileSent] = useState(false);
  const [progressBarContent, setProgressBarContent] = useState("");
  const storage = getStorage();
  const canvasRef = useRef(null);
  let renderTask = useRef(null);
  const replacedString = file.name.replace(/[#$\/\[\].\s]/g, "_");

  useEffect(() => {
    if (!file) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const saveFile = async () => {
      const innerUrl = URL.createObjectURL(file);
      // const loadPdf = async () => {
      const pdf = await getDocument(innerUrl).promise;
      const numPages = pdf.numPages;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      //   const scale = Math.min(200 / viewport.height);
      //   const scaledViewport = page.getViewport({ scale });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      if (renderTask.current) {
        renderTask.current.cancel();
      }
      renderTask.current = page.render(renderContext);
      await renderTask.current.promise
        .then(() => {
          renderTask.current = null;
        })
        .catch((error) => {
          if (error.name !== "RenderingCancelledException") {
            console.error("Render task failed:", error);
          }
        });

      const imgDataUrl = await canvas.toDataURL("image/png");
      const imageRes = await fetch(imgDataUrl);
      const imageBlob = await imageRes.blob();
      const imageUrl = await storeImage(imageBlob);
      const pdfUrl = await storeFile({ imageUrl, numPages });

      // setList((prev) => [
      //   ...prev,
      //   {
      //     url: { image: imageUrl, pdf: pdfUrl },
      //     pagesCount: numPages,
      //     name: replacedString,
      //     path: {
      //       pdf: path + replacedString + "/pdf",
      //       image: path + replacedString + "/image",
      //     },
      //   },
      // ]);
      // }, 2000);
    };
    saveFile();
    return () => {
      if (renderTask.current) {
        renderTask.current.cancel();
      }
    };
  }, [file]);

  const storeImage = (imageBlob) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `${stPath}/${replacedString}/image`);
      const uploadTask = uploadBytesResumable(storageRef, imageBlob);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("start to upload image");
        },
        (error) => {
          reject(error);
        },
        () => {
          console.log("image successfully uploaded");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };
  const storeFile = ({ imageUrl, numPages }) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const storageRef = ref(storage, `${stPath}/${replacedString}/pdf`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBarContent(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          // toast("file successfully uploaded to storage");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            updatePdfRealPath({
              pdf: {
                url: { image: imageUrl, pdf: downloadURL },
                pagesCount: numPages,
                name: replacedString,
                path: {
                  pdf: `${stPath}/${replacedString}/pdf`,
                  image: `${stPath}/${replacedString}/image`,
                },
              },
              path: realPath,
            }).then(() => {
              setFileSent(true);
            });
          });
        }
      );
    });
  };

  return (
    <div>
      {file && (
        <div className={`m-auto text-center mt-2`}>
          {/* {progressBarContent === 100 && (
            <span
              className="text-red-600 cursor-pointer"
              onClick={() => removeOneSelectedFile()}
            >
              X
            </span>
          )} */}

          <div>
            <canvas
              ref={canvasRef}
              className="border rounded-md m-auto w-full hidden"
            ></canvas>
            {replacedString}
          </div>

          <div
            className={`w-full rounded-full mt-2 ${
              !progressBarContent ? "hidden" : "block"
            } `}
          >
            {fileSent ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CircularPercentage progress={progressBarContent} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EachPdf;
