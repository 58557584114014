import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
const SelectUserComp = ({ users, selectedUser, setSelectedUser }) => {
  const changeUser = (e) => {
    const user = users.find((user) => user.profile.uid === e.target.value);
    setSelectedUser(user.profile.uid);
  };
  console.log(selectedUser);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="user-select-label">Select User</InputLabel>
      <Select
        labelId="user-select-label"
        onChange={changeUser}
        label="Select User"
        value={selectedUser}
      >
        {users?.map((user, index) => (
          <MenuItem
            key={index}
            value={user.profile?.uid}
            selected={selectedUser === user?.profile.uid}
          >
            {user.profile?.groupCode && user.profile?.groupCode}
            ---
            {user.profile?.email} ---{" "}
            {user.profile?.studentNumber && user.profile?.studentNumber}{" "}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectUserComp;
