import React, { useEffect, useState } from "react";
import { TextField, IconButton, InputAdornment, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { saveAboutMe } from "../../../firebase.config";
import { useSelector } from "react-redux";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import { toast } from "react-toastify";
// import { makeStyles } from "@mui/styles";
// const useStyles = makeStyles({
//   root: {
//     "& .MuiInputBase-input.Mui-disabled": {
//       color: "red",
//     },
//   },
// });
const AboutMe = () => {
  const user = useSelector((state) => state.user);
  const [isEditing, setIsEditing] = useState(false);
  const [aboutMe, setAboutMe] = useState(user.importedInfo?.aboutMe || "");
  const [wordCount, setWordCount] = useState(aboutMe?.split(" ")?.length);
  const [direction, setDirection] = useState("ltr");
  // const classes = useStyles();

  useEffect(() => {
    if (user.importedInfo?.aboutMe) {
      setAboutMe(user.importedInfo?.aboutMe);
    }
  }, [user.importedInfo?.aboutMe]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    try {
      await saveAboutMe({
        uid: user.profile.uid,
        aboutMe,
        groupCode: user.profile.groupCode,
      });
    } catch (error) {
      console.log(error);
      toast.error("there was an error to save info");
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const words = value?.split(" ").filter((word) => word !== "");
    if (words.length <= 300) {
      setAboutMe(value);
      setWordCount(words.length);
    }
  };
  const toggleDirection = () => {
    setDirection((prevDirection) => (prevDirection === "ltr" ? "rtl" : "ltr"));
  };

  return (
    <div>
      <div className="relative">
        <TextField
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            width: "100%",
            mb: 2,
          }}
          multiline
          rows={4}
          value={aboutMe}
          onChange={handleChange}
          disabled={!isEditing}
          dir={direction}
          //   placeholder="your bio is empty"
          //   sx={{
          //   '& .MuiInputBase-input.Mui-disabled': {
          //     color: 'gray', // Change the color as needed
          //   },
          //   }}
          InputProps={{
            style: {
              paddingRight: "40px", // Adjust the padding as needed
            },
          }}
        />
        {!isEditing ? (
          <IconButton
            onClick={handleEditClick}
            color="primary"
            sx={{ position: "absolute", top: 0, right: 0, fontSize: "14px" }}
          >
            About Me
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={toggleDirection}
            color="primary"
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            {direction === "ltr" ? (
              <FormatTextdirectionRToLIcon />
            ) : (
              <FormatTextdirectionLToRIcon />
            )}
          </IconButton>
        )}
      </div>
      {isEditing && (
        <div className="flex justify-between items-center">
          <span>{wordCount} / 300 words</span>
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveClick}
              sx={{ ml: 2 }}
            >
              Save
            </Button>
            <Button
              className="text-blue-500 py-1"
              onClick={() => {
                setIsEditing(false);
                setAboutMe(user.importedInfo?.aboutMe || "");

                setWordCount(aboutMe.split(" ").length);
              }}
              sx={{ ml: 2 }}
            >
              Cancle
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
