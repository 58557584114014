import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState();

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    addCategories: categoriesAdapter.setAll,
    createEmptyCategorie: categoriesAdapter.setOne,
  },
});

export const { addCategories } = categoriesSlice.actions;
export const getAllCategories = (state) =>
  categoriesAdapter.getSelectors().selectAll(state.categories);

export const getCategorieById = (state, categorieId) =>
  categoriesAdapter.getSelectors().selectById(state.categories, categorieId);

export const getCategorieEntities = (state) =>
  categoriesAdapter.getSelectors().selectEntities(state.categories);

export default categoriesSlice.reducer;
