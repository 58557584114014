import React, { useState } from "react";
import EachResource from "./EachResource";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  addNewResource,
  getLessonById,
  uploadResourceId,
} from "../../../../redux/lessonsSlice";
import { useSelector } from "react-redux";
import { getCourseById } from "../../../../redux/coursesSlice";
import { getAssignmentById } from "../../../../redux/assignmentsSlice";

const ViewAddedResources = ({ content }) => {
  // const { lessonId, courseId, assignmentId } = useParams();
  // const lesson = useSelector((state) => getLessonById(state, lessonId));
  // const course = useSelector((state) => getCourseById(state, courseId));
  // const assignment = useSelector((state) =>
  //   getAssignmentById(state, assignmentId)
  // );

  // const content = lesson || course || assignment;
  return (
    <div>
      {content?.resources &&
        Object.values(content.resources).map((resource) => (
          <EachResource resource={resource} />
        ))}
    </div>
  );
};

export default ViewAddedResources;
