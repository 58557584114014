import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLock,
  faMagnifyingGlass,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import NavbarUserStatus from "./NavbarUserStatus";

import cyrusUniLogo from "../../images/logoTextWhite.png";
import { Typography } from "@mui/material";
const Navbar = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //     const getUserInfo = async () => {
  //         if (!currentUser) return
  //         const userInfo = await getDocumentUser(currentUser.uid);
  //         console.log(userInfo)
  //         setUserDoc(userInfo)
  //     };
  //     getUserInfo();
  // }, []);

  return (
    <div className="myc-navbar h-10 m-auto">
      <div className="mx-auto max-w-6xl flex justify-between items-center h-full">
        {/* <select
          className="w-60 border border-gray-500 ml-5"
          defaultValue="select language"
        >
          <option value="english">Select Language</option>
          <option value="english">English</option>
          <option value="persian">Persian</option>
          <option value="france">French</option>
        </select> */}
        <div className="flex items-center gap-5 ms-12 md:hidden">
          <img src={cyrusUniLogo} alt="logo" className="contain h-10 " />
          {/* <Typography variant="h5" className="text-slate-300">
            |
          </Typography>
          <Typography variant="h5">{title}</Typography> */}
        </div>
        <div className="text-white hidden md:flex items-center">
          <FontAwesomeIcon className="pr-1" icon={faEnvelope} />
          <span className="pr-2">office@cyrusuniversity.org</span>
          <FontAwesomeIcon className="pr-1" icon={faPhone} />
          <span className="pr-2">+1 (408) 767-2333</span>
        </div>
        {/* <div className='flex items-center'>
                    <img className='h-16 cursor-pointer' onClick={() => navigate("/")} src={cyrusUniLogo} alt="not available" />
                </div> */}

        {/* <div className="myc-socialMedia flex justify-between">
          <button>
            <FontAwesomeIcon icon={faYoutube} />
          </button>
          <button>
            <FontAwesomeIcon icon={faTwitter} />
          </button>
          <button>
            <FontAwesomeIcon icon={faFacebookF} />
          </button>
          <button>
            <FontAwesomeIcon icon={faInstagram} />
          </button>
        </div> */}
        <NavbarUserStatus />
      </div>
    </div>
  );
};

export default Navbar;
