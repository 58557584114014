import React, { useState } from "react";

import { updateAddress } from "../../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../../redux/usersSlice";
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
const RegisterAddress = () => {
  const { uid } = useParams();
  const newUser = useSelector((state) => getUserById(state, uid));
  const address = newUser.address;
  const groupCode = newUser.profile.groupCode;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(address);
  const [sendingPost, setSendingPost] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateAddress({ uid, addressInfo: formData, groupCode });
      toast.success("successfully update address");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>Country:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="country"
          label="Country"
          value={formData?.country}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>Street:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="street"
          label="Street"
          value={formData?.street}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>City:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="city"
          label="City"
          value={formData?.city}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>State:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="state"
          label="State"
          value={formData?.state}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>ZIP Code:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="zipCode"
          label="ZIP Code"
          value={formData?.zipCode}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>Unit:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="unit"
          label="Unit"
          value={formData?.unit}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>Email Address:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="email"
          label="Email"
          value={formData?.email}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>Phone Number:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="phoneNumber"
          label="Phone Number"
          value={formData?.phoneNumber}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pt: 2 }}>
        <Typography sx={{ width: "11rem" }}>Home Contact Number:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="homeContactNumber"
          label="Home Contact Number"
          value={formData?.homeContactNumber}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
        <Button type="submit" variant="contained" color="primary">
          Register Address
        </Button>
        {sendingPost && (
          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RegisterAddress;
