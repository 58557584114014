import React from "react";
import { Box, Typography, Paper, Container, Button } from "@mui/material";
import ReleaseDateCounter from "../../shared/utils/ReleaseDateCounter";
import ReactQuill from "react-quill";
import ViewAddedResources from "../shared/sharedComp/showResources/ViewAddedResources";
import ShowCourseContent from "./ShowCourseContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const ShowLesson = ({ lesson, changeComp, courseId }) => {
  const backToCourse = () => {
    changeComp(
      <ShowCourseContent changeComp={changeComp} courseId={courseId} />
    );
  };

  return lesson?.releaseDate?.date < Date.now() ||
    lesson?.releaseDate?.immediately ? (
    <Container>
      <div className="flex items-center h-14 gap-5 border-b-2">
        <Button onClick={backToCourse} sx={{ color: "#002868" }}>
          <ArrowBackIcon />
        </Button>
        <div className="h-full flex items-center">
          <Typography variant="h6" align="center" gutterBottom>
            {lesson?.info?.title}
          </Typography>
        </div>
      </div>
      <Paper elevation={0} sx={{ padding: 4, marginTop: 4 }}>
        <Box sx={{ marginBottom: 4 }}>
          <ReactQuill
            value={lesson?.info?.explain}
            readOnly={true}
            theme="bubble"
          />
        </Box>
        <ViewAddedResources content={lesson} />
      </Paper>
    </Container>
  ) : (
    <ReleaseDateCounter date={lesson?.releaseDate?.date} />
  );
};

export default ShowLesson;
