import { useState } from "react";

import {
  deleteStorageRecursively,
  removeSinleRealPath,
} from "../../../../firebase.config";
import { toast } from "react-toastify";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { IconButton } from "@mui/material";
import ViewFile from "../../../../layout/ViewFile";
import PdfShow from "../../../shared/resourses/showFile/PdfShow";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputMultiplePdf from "./InputMultiplePdf";

const UploadMultiplePdf = ({ realPath, stPath, pdfsInDb }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const removePdf = async (name) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await deleteStorageRecursively(`${stPath}/${name}`);
        await removeSinleRealPath(`${realPath}/${name}`);
        toast.success("successfully deleted");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  return (
    <div>
      <InputMultiplePdf stPath={stPath} realPath={realPath} />

      <div className="m-auto w-96">
        <Swiper navigation={true} modules={[Navigation]} spaceBetween={30}>
          {pdfsInDb.map((pdf, index) => (
            <SwiperSlide key={index} className="border">
              <div className="w-full flex items-center justify-end">
                <IconButton
                  onClick={() => removePdf(pdf.name)}
                  sx={{ color: "red" }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </div>
              <div className="relative flex flex-col items-center  rounded p-3 mx-16 group">
                <img
                  src={pdf?.url?.image}
                  alt={pdf?.name}
                  className="contain"
                />
                <p className="text-center">
                  {`${pdf?.name} (${pdf?.pagesCount} pages) `}{" "}
                </p>
                <div
                  onClick={openModal}
                  className="absolute inset-0 flex items-center justify-center bg-slate-500 bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                >
                  {/* <IconButton> */}
                  <VisibilityIcon
                    className="text-white"
                    sx={{ fontSize: "40px" }}
                  />
                  {/* </IconButton> */}
                </div>
              </div>

              <ViewFile
                isOpen={isModalOpen}
                onClose={closeModal}
                title={"Registeration Form"}
              >
                <PdfShow pdf={{ file: { ...pdf }, info: { ...pdf } }} />
              </ViewFile>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default UploadMultiplePdf;
