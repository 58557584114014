import React, { useCallback, useMemo } from "react";
import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { db } from "../../../firebase.config";
import CourseRow from "./CourseRow";
import { get, onValue, ref, update } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getAllCourses, getCoursesByIds } from "../../../redux/coursesSlice";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import SearchItems from "../sharedComp/SearchItems";
const ShowAllCourses = () => {
  const user = useSelector((state) => state.user);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [coursesStatus, setCoursesStatus] = useState("published");
  const navigate = useNavigate();
  const [sortCourses, setSortedCourses] = useState([]);
  const [sortType, setSortType] = useState("publishDate");
  const [order, setOrder] = useState(true);
  const courses = useSelector(getAllCourses);

  useEffect(() => {
    setFilteredCourses(
      courses.filter((course) => course.info?.status === "published")
    );
  }, [courses]);

  useEffect(() => {
    setSortedCourses((prev) => {
      if (!prev?.length) return;
      if (order) {
        const sortedData = prev.sort((a, b) =>
          b.info[sortType]
            .toString()
            .localeCompare(a.info[sortType].toString(), undefined, {
              numeric: true,
            })
        );
        return sortedData;
      } else {
        const sortedData = prev.sort((a, b) =>
          a.info[sortType]
            .toString()
            .localeCompare(b.info[sortType].toString(), undefined, {
              numeric: true,
            })
        );
        return sortedData;
      }
    });
  }, [sortType, order]);

  const addNewCourse = async () => {
    let courseId;
    const updateRef = ref(db, `/managementInfo/courseInformation`);
    const getRef = ref(db, `/managementInfo/courseInformation/courseIdCounter`);
    try {
      let snap = await get(getRef);
      if (!snap.exists()) {
        await update(updateRef, { courseIdCounter: 1 });
        courseId = "course_1";
      } else {
        let lastCourseNum = await snap.val();
        lastCourseNum += 1;
        await update(updateRef, { courseIdCounter: lastCourseNum });
        courseId = "course_" + lastCourseNum;
      }
      user.profile.role === "admin" &&
        navigate(`/adminpanel/addnewcourse/${courseId}`);
      user.profile.role === "instructor" &&
        navigate(`/instructorpanel/addnewcourse/${courseId}`);
    } catch (error) {
      toast.error(error.message, "problem assign course id");
    }
  };
  const clickOnSorting = (e) => {
    if (sortType === e.target.value) {
      setOrder((prev) => !prev);
    } else {
      setSortType(e.target.value);
      setOrder(true);
    }
  };

  const publishOrDraft = (e) => {
    setCoursesStatus(e.target.value);
    const arrOFilteredLessons = courses.filter(
      (course) => course.info?.status === e.target.value
    );
    setFilteredCourses(arrOFilteredLessons);
  };

  const searchCourses = useCallback((e) => {
    setFilteredCourses(
      courses.filter(
        (course) =>
          course.info.title.toLocaleLowerCase().includes(e.target.value) &&
          course.info.status === coursesStatus
      )
    );
  }, []);

  return (
    <div className="w-7xl m-auto mt-10 flex flex-col mx-10">
      <div className="mb-5">
        Courses:
        <IconButton
          onClick={() => {
            addNewCourse();
          }}
          className="ms-3"
        >
          <AddCircleIcon className="text-blue-500" />
        </IconButton>
      </div>
      <div className="flex gap-x-2 mb-2">
        <button
          onClick={publishOrDraft}
          value={"published"}
          className={coursesStatus === "published" && "text-blue-500"}
        >
          Published
        </button>
        <span>|</span>
        <button
          onClick={publishOrDraft}
          value={"draft"}
          className={coursesStatus === "draft" && "text-blue-500"}
        >
          Draft
        </button>
        <SearchItems searchFunc={searchCourses} />
      </div>
      <table className="table-auto">
        <thead>
          <tr className="flex items-center border py-3">
            <th className="w-1/12">{/* <button>Course ID</button> */}</th>
            <th className="w-1/6">
              <button>Course Code</button>
            </th>
            <th className="w-1/6">
              <button>Course Name</button>
            </th>
            <th className="w-1/6">
              <button>Categories</button>
            </th>
            <th className="w-1/6">
              <button>Instructors</button>
            </th>

            <th className="w-1/12">Creation Date</th>
            <th className="w-1/12">Group</th>

            {/* <th className="ml-auto w-1/12">

            </th> */}
          </tr>
        </thead>
        <tbody className="border">
          {filteredCourses?.map((item, index) => {
            return <CourseRow key={`itemname${index}`} item={item} />;
          })}
        </tbody>
      </table>

      {/* <div className='h-52  flex'>

      </div> */}
    </div>
  );
};

export default ShowAllCourses;
