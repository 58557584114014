import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase.config";
import { getLessonById } from "../../../redux/lessonsSlice";
import { useSelector } from "react-redux";
import { getCourseById } from "../../../redux/coursesSlice";
import EachCourseAssigned from "./EachCourseDemo";

const CourseAssign = ({ lessonId }) => {
  const lesson = useSelector((state) => getLessonById(state, lessonId));

  return (
    <div className="flex flex-col gap-y-3">
      <p>Assigned Course</p>
      <div className="flex justify-between border-b ">
        <p className="me-8">Id</p>
        <p>Title</p>
      </div>
      {lesson.assignedCourses ? (
        <div>
          {Object.keys(lesson.assignedCourses).map((courseId) => (
            <EachCourseAssigned courseId={courseId} />
          ))}
        </div>
      ) : (
        <p className="text-center">there isn't any assigned course</p>
      )}
    </div>
  );
};

export default CourseAssign;
