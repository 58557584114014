import React from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Phone, Email, LocationOn } from "@mui/icons-material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
const Contact = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        We'd love to hear from you! Reach out to us with any questions or
        feedback.
      </Typography>

      {/* Icons Section */}
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4, mb: 5 }}>
        <Grid item xs={12} xl={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Phone fontSize="large" color="primary" />
            <Typography variant="h6" sx={{ mt: 1 }}>
              +1 (408) 767-2333
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Email fontSize="large" color="primary" />
            <Typography variant="h6" sx={{ mt: 1 }}>
              office@cyrusuniversity.org
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <LocationOn fontSize="large" color="primary" />
            <Typography variant="h6" sx={{ mt: 1 }}>
              8339 Church St #211Gilroy, CA 95020, USA
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <QueryBuilderIcon fontSize="large" color="primary" />
            <Typography variant="h6" sx={{ mt: 1 }}>
              Monday-Friday, 8am - 6pm
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Contact Form */}
      {/* <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              fullWidth
              variant="outlined"
              required
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              fullWidth
              variant="outlined"
              required
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              required
              type="email"
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              fullWidth
              variant="outlined"
              required
              multiline
              rows={4}
              color="primary"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 3 }}
          >
            <Button variant="contained" color="primary" size="large">
              Send Message
            </Button>
          </Grid>
        </Grid>
      </Box> */}
    </Container>
  );
};

export default Contact;
