import { deleteObject, getStorage, ref } from "firebase/storage";

import React, { useState } from "react";
import { toast } from "react-toastify";
import { deleteRealItem } from "../../../../firebase.config";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { selectImageById } from "../../../../redux/allImagesSlice";
const EachImage = ({ file, imagesId }) => {
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const image = useSelector((state) => selectImageById(state, imagesId));
  const removeOneSelectedFile = () => {
    const storageRef = ref(storage, file.path);
    if (!image.info.groupCode) throw new Error("image group not found");
    if (window.confirm("are you want to proceed removing image ?")) {
      setLoading(true);
      deleteObject(storageRef)
        .then(() => {
          console.log(imagesId, file.name);
          if (!imagesId || !file.name) {
            toast.error(
              "something went wrong, image id or file name not found"
            );
            return;
          }
          deleteRealItem(`/resources/images/${imagesId}/files/${file.name}`);
          toast("file deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="text-center w-44">
      {" "}
      <span
        style={{ cursor: "pointer" }}
        className="text-red-600"
        onClick={(e) => removeOneSelectedFile()}
      >
        {loading ? <CircularProgress size={20} sx={{ color: "red" }} /> : "X"}
      </span>
      <img
        className="border object-contain rounded-md"
        src={file.url}
        alt={file?.name}
      />
      <span>{file.name}</span>
    </div>
  );
};

export default EachImage;
