import { Button, FormHelperText, TextField } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";

import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import Quill's CSS
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";
import "./styles.css";
import { v4 as uuidv4 } from "uuid";
import { sendImagesToDb } from "../../../firebase.config";
import "./styles.css";
// const Font = Quill.import("formats/font");
// Font.whitelist = ["arial", "roboto", "courier", "times-new-roman"];
// Quill.register(Font, true);

// const icons = Quill.import("ui/icons");
// icons["pdf"] = "pdf";
// icons["audio"] = "audio";
// var Size = ReactQuill.Quill.import("attributors/style/size");
// Size.whitelist = ["12px", "16px", "18px"];
// ReactQuill.Quill.register(Size, true);

const font = ReactQuill.Quill.import("attributors/style/font");
font.whitelist = ["Arial", "Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
ReactQuill.Quill.register(font, true);

const storage = getStorage();
const TextAreaComponent = ({ props }) => {
  // const [progressBarContent, setProgressBarContent] = useState("");
  const [inputErr, setInputErr] = useState(false);
  const {
    explain,
    // textDirection,
    setTitle,
    title,
    setName,
    name,
    nameDirection,
    setNameDirection,
    inputDirection,
    setInputDirection,
    setExplain,
    groupCode,
    // setTextDirection,
  } = props;

  const quillRef = useRef(null);

  const handleFileUpload = useCallback((file, type) => {
    const imageId = uuidv4();
    console.log(imageId);
    if (!imageId || !groupCode) {
      toast.error("please specify group code");
      return;
    }
    const storageRef = ref(
      storage,
      `/resources/images/${imageId}/${file.name}`
    );

    //save in realtime
    const uploadTask = uploadBytesResumable(storageRef, file);
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
        // quill.insertText(range.index, progress);\
        // quill.formatLine(range.index, "header", "1");
        // quill.clipboard.dangerouslyPasteHTML(
        //   range.index,
        // `
        // <div
        //   className={bg-slate-200 mt-2 w-96}
        // >
        // "<div class={w-96 bg-slate-500 } >dgssdg</div>"
        // </div>`
        // );
      },
      (error) => {
        toast.error(`error in upload image${error}`);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          quill.insertEmbed(range.index, type, downloadURL);
          const replacedString = file.name.replace(/[#$\/\[\].\s]/g, "_");
          const image = {
            info: {
              category: "",
              explain: "",
              name: "",
              imageId,
            },
            files: {
              [replacedString]: {
                url: downloadURL,
                path: `/resources/images/${imageId}/${replacedString}`,
                number: 1,
                name: replacedString,
              },
            },
          };
          sendImagesToDb(image, groupCode);
        });
      }
    );
  }, []);

  const handleImageUpload = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = () => handleFileUpload(input.files[0], "image");
  }, [handleFileUpload]);

  const modules = {
    toolbar: {
      container: [
        [{ font: font.whitelist }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ direction: "rtl" }],
        ["image"],
      ],
      handlers: {
        image: handleImageUpload,
        // video: handleVideoUpload,
        // audio: handleAudioUpload,
        // pdf: handlePdfUpload,
      },
    },
  };

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "list",
    "bullet",
    "align",
    "link",
    "image",
    "video",
    "audio",
    "pdf",
    "direction",
  ];

  return (
    <div>
      {" "}
      <div className="flex w-full">
        {/* <input
          className="w-full border rounded-md mb-5  px-2 py-2"
          type="text"
          placeholder="Add Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            // dispatch(addLessonTitle(e.target.value));
          }}
          dir={inputDirection}
        /> */}
        <TextField
          className="w-full"
          sx={{ marginBottom: "10px" }}
          size="small"
          label="Code"
          value={title}
          dir={inputDirection}
          onChange={(e) => {
            setTitle(e.target.value);
            setInputErr(e.target.value.length < 4);
            // dispatch(addLessonTitle(e.target.value));
          }}
          error={inputErr}
          helperText={
            inputErr ? `Title must be at least 4 characters long` : ""
          }
        />
        {/* {inputErr && (
          <FormHelperText
            inputErr
          >{`Input must be at least 4 characters long`}</FormHelperText>
        )} */}
        <div>
          {inputDirection === "ltr" ? (
            <Button
              onClick={() => {
                setInputDirection("rtl");
              }}
            >
              <FormatTextdirectionLToRIcon />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setInputDirection("ltr");
              }}
            >
              <FormatTextdirectionRToLIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="flex w-full">
        <TextField
          className="w-full"
          sx={{ marginBottom: "10px" }}
          size="small"
          label="Name"
          value={name}
          dir={nameDirection}
          onChange={(e) => {
            setName(e.target.value);
            // setInputErr(e.target.value.length < 4);
            // dispatch(addLessonName(e.target.value));
          }}
          // error={inputErr}
          // helperText={
          //   inputErr ? `Name must be at least 4 characters long` : ""
          // }
        />
        {/* {inputErr && (
          <FormHelperText
            inputErr
          >{`Input must be at least 4 characters long`}</FormHelperText>
        )} */}
        <div>
          {nameDirection === "ltr" ? (
            <Button
              onClick={() => {
                setNameDirection("rtl");
              }}
            >
              <FormatTextdirectionLToRIcon />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setNameDirection("ltr");
              }}
            >
              <FormatTextdirectionRToLIcon />
            </Button>
          )}
        </div>
      </div>
      <div>
        <ReactQuill
          theme="snow"
          className="h-96 mb-20"
          value={explain}
          onChange={setExplain}
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
      </div>
      {/* <div className="flex flex-col">
        <textarea
          className="w-full h-80 border"
          value={explain}
          onChange={(e) => {
            setExplain(e.target.value);
          }}
          style={{ whiteSpace: "pre-wrap" }}
          dir={textDirection}
        ></textarea>
        <div className="self-end">
          {textDirection === "ltr" ? (
            <Button
              onClick={() => {
                setTextDirection("rtl");
              }}
            >
              <FormatTextdirectionLToRIcon />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setTextDirection("ltr");
              }}
            >
              <FormatTextdirectionRToLIcon />
            </Button>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default TextAreaComponent;
