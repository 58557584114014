import React from "react";
import { useParams } from "react-router-dom";
import AddTable from "./AddTable";

const EditTable = () => {
  const { tableId } = useParams();
  return (
    <div>
      <AddTable />
    </div>
  );
};

export default EditTable;
