import { Button } from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingButton = ({ loading, disabled, name }) => {
  return (
    <div>
      {!loading ? (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={disabled}
          //
          sx={{
            backgroundColor: "#002868",
            color: "white",
            "&:hover": {
              backgroundColor: "#002868",
            },
            width: "100%",
            marginTop: "25px",
          }}
          color="primary"
        >
          {name}
        </Button>
      ) : (
        <Button
          fullWidth
          variant="contained"
          disabled
          sx={{ marginTop: "25px" }}
        >
          <CircularProgress sx={{ color: "#aaa" }} />
        </Button>
      )}
    </div>
  );
};

export default LoadingButton;
