import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";

const ReleaseDateCounter = ({ date }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const timesec = date / 1000 - Date.now() / 1000;
    setTimeLeft(timesec);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${days}d ${hours}h ${minutes}m ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}s`;
  };

  return (
    <Box
      display="flex "
      justifyContent="center"
      alignItems="center"
      className="text-cu-blue"
    >
      {/* <p className="text-center">will publish at:</p> */}
      <span style={{ fontSize: "12px" }}>{formatTime(timeLeft)}</span>
      <LockIcon className="text-cu-red" />
    </Box>
  );
};

export default ReleaseDateCounter;
