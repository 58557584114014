import React, { useEffect } from "react";
import UploadMultiplePdf from "../../sharedComp/multiplePdfU/UploadMultiplePdf";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getCourseById,
  updateCourseStatus,
} from "../../../../redux/coursesSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createEmptyCourseInDb } from "../../../../firebase.config";

const CourseMaterials = ({ groupCode }) => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const user = useSelector((state) => state.user);
  let course = useSelector((state) => getCourseById(state, courseId));
  const pdfsInDb = course?.materials ? Object.values(course.materials) : [];
  useEffect(() => {
    if (!groupCode || !courseId || !course) return;
    if (course?.info?.status !== "temp") return;
    if (!course) return;
    const myf = async () => {
      dispatch(updateCourseStatus({ courseId, status: "draft" }));
      await createEmptyCourseInDb({ course });
      console.log("create empty course");
    };
    myf();
  }, []);

  return (
    <div>
      {!groupCode ? (
        <p className="text-center mt-3 text-cu-red">
          please specify groupcode fisrt
        </p>
      ) : (
        <UploadMultiplePdf
          pdfsInDb={pdfsInDb}
          stPath={`/courses/${courseId}/materials`}
          realPath={`/courses/${groupCode}/${courseId}/materials`}
        />
      )}
    </div>
  );
};

export default CourseMaterials;
