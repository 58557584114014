import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const pdfAdaptor = createEntityAdapter();

const initialState = pdfAdaptor.getInitialState({
  loading: false,
  error: null,
});

const allPdfSlice = createSlice({
  name: "allPdfs",
  initialState,
  reducers: {
    setPdfs: pdfAdaptor.setAll,
  },
  setPdfLoading: (state, action) => {
    state.loading = action.payload;
  },
  setAllPdfError: (state, action) => {
    state.error = action.payload;
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchPdfs.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(fetchPdfs.fulfilled, (state, action) => {
  //       state.loading = false;
  //       pdfAdaptor.setAll(state, action.payload);
  //     })
  //     .addCase(fetchPdfs.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
});

export const { setPdfs, setPdfLoading, setAllPdfError } = allPdfSlice.actions;
export const selectAllPdfs = (state) =>
  pdfAdaptor
    .getSelectors()
    .selectAll(state.allPdfs)
    .sort((a, b) => b.info.createAt - a.info.createAt);
// .sort((a, b) => b.info.name.localeCompare(a.info.name));
export const selectPdfById = (state, pdfId) =>
  pdfAdaptor.getSelectors().selectById(state.allPdfs, pdfId);
export default allPdfSlice.reducer;
