// StyledComponents.js
import { styled } from "@mui/material/styles";
import { ListItemButton, List } from "@mui/material";

export const SelectedListItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: "#002868",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#003a99",
  },
  "&.Mui-selected": {
    color: "#bf0a30",
    borderLeft: "8px solid #003a99",
    "&:hover": {
      backgroundColor: "#004bb3",
    },
  },
}));

export const ListContainer = styled(List)(({ theme }) => ({
  height: "100vh",
  backgroundColor: "#002868",
  color: "#fff",
}));
