import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupById } from "../../../redux/groupsSlice";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import PdfShow from "../resourses/showFile/PdfShow";
import { getUserById } from "../../../redux/usersSlice";
import LogoItem from "../resourses/LogoItem";

const ReviewGroup = () => {
  let { groupId } = useParams();
  const group = useSelector((state) => getGroupById(state, groupId));
  const leadInstructor = useSelector((state) =>
    getUserById(state, group?.leadInstructor)
  );

  return (
    <div>
      <Card className="w-11/12 mx-auto mb-4">
        {/* <img
        src={course.files.courseImage.url}
        alt={title}
        className="w-full h-64 object-contain"
      /> */}
        <CardContent>
          {/* Group One */}
          <Typography
            variant="h3"
            component="div"
            className="text-center"
            sx={{ fontWeight: "600" }}
          >
            {group?.englishName}
          </Typography>
          <Typography variant="h6" component="div" className="pt-3 ">
            Major Details
          </Typography>
          <Grid container spacing={1} className="pt-10">
            <Grid item xs={6}>
              <Typography variant="body2">group ID: {group?.id}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                Persian Name : {group?.persianName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                leadInstructor : {leadInstructor?.profile?.name}{" "}
                {leadInstructor?.profile?.family}
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography variant="body2">color : {group.color}</Typography>
            </Grid> */}
          </Grid>
          {group?.catalog && (
            <div className="flex items-center justify-center mt-5">
              <LogoItem
                src={group.catalog.file.url.image}
                size={300}
                item={group.catalog}
                type={"pdf"}
              />
            </div>
          )}
        </CardContent>
        {/* <ViewAddedResources /> */}
      </Card>
    </div>
  );
};

export default ReviewGroup;
