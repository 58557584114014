import React, { useState } from "react";
import { changePassword } from "../../firebase.config";
import { toast } from "react-toastify";
import { ReactComponent as PostLoadingComp } from "../../images/svg/postsvgLoading.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Paper,
} from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
import cyrusUniLogo from "../../images/logo.webp";
const ChangePassword = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const reqToChangePass = async () => {
    try {
      if (newPassword !== repeatNewPassword) {
        toast.error("Your Password And Repeated Password Are Different");
        return;
      }
      if (newPassword.length < 8) {
        toast.error("Minimum password length is 8 characters");
        return;
      }

      setLoading(true);
      await changePassword({
        email: user.profile.email,
        password: oldPassword,
        newPassword,
      });
      toast.success("Password successfully changed");
      if (user.profile.role === "student") {
        navigate("/studentpanel/profile");
      } else if (user.profile.role === "admin") {
        navigate("/adminpanel");
      } else if (user.profile.role === "instructor") {
        navigate("/instructorpanel/profile");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 5,
        }}
        elevation={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img className="contain" src={cyrusUniLogo} alt="Logo" />
          <Typography variant="h5" component="h2" sx={{ mb: 1, mt: 7 }}>
            Change Password
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Old Password"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            size="small"
            // sx={{ mb: 1 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            size="small"
            // sx={{ mb: 1 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Repeat New Password"
            type="password"
            value={repeatNewPassword}
            onChange={(e) => setRepeatNewPassword(e.target.value)}
            size="small"
            // sx={{ mb: 1 }}
          />
          <Button
            onClick={reqToChangePass}
            loading={loading}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!oldPassword || !newPassword || !repeatNewPassword}
            sx={{ mt: 2 }}
          >
            {loading && <PostLoadingComp />}
            Change Password
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ChangePassword;
