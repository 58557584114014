import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { assignAssignmentToLesson, db } from "../../../firebase.config";

const LessonAssign = ({ assignmentId }) => {
  const [assignedCourse, setAssignedCourse] = useState([]);
  // const lesson = useSelector((state)=>state.createEditLesson.info.)

  useEffect(() => {
    const lessonIdInCourse = ref(
      db,
      `assignments/${assignmentId}/assignedLessons`
    );
    return onValue(lessonIdInCourse, (snap) => {
      //   if (!snap.exists()) throw new Error("any lesson assigned to lesson yet");
      if (snap.val()) {
        setAssignedCourse(Object.values(snap.val()));
      }
    });
  }, []);
  console.log(assignedCourse);

  return (
    <div className="flex flex-col gap-y-3">
      <p>Assigned Lesson</p>
      <div className="flex justify-between border-b ">
        <p className="me-8">Id</p>
        <p>Title</p>
      </div>
      {assignedCourse.length ? (
        <div>
          {assignedCourse.map((lesson) => (
            <div className="flex justify-between">
              <p>{lesson.lessonId}</p>
              <p>{lesson.title}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">there isn't any assigned lesson</p>
      )}
    </div>
  );
};

export default LessonAssign;
