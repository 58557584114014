import { Typography, Box, Button, Paper, Container } from "@mui/material";
import ReleaseDateCounter from "../../shared/utils/ReleaseDateCounter";
import ReactQuill from "react-quill";
import ShowCourseContent from "./ShowCourseContent";
import ViewAddedResources from "../shared/sharedComp/showResources/ViewAddedResources";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ShowAssignment = ({ assignments, changeComp }) => {
  const backToCourse = () => {
    changeComp(<ShowCourseContent changeComp={changeComp} />);
  };
  console.log(assignments);
  return assignments.map((assignment) =>
    assignment?.releaseDate?.date < Date.now() ||
    assignment?.releaseDate?.immediately ? (
      <Container>
        <div className="flex items-center h-14 gap-5 border-b-2">
          <Button onClick={backToCourse} sx={{ color: "#002868" }}>
            <ArrowBackIcon />
          </Button>
          <div className="h-full flex items-center">
            <Typography variant="h6" align="center" gutterBottom>
              {assignment?.info?.title}
            </Typography>
          </div>
        </div>
        <Paper elevation={0} sx={{ padding: 4, marginTop: 4 }}>
          <Box sx={{ marginBottom: 4 }}>
            <ReactQuill
              value={assignment?.info?.explain}
              readOnly={true}
              theme="bubble"
            />
          </Box>
          <ViewAddedResources content={assignment} />
        </Paper>
      </Container>
    ) : (
      <ReleaseDateCounter date={assignment?.releaseDate?.date} />
    )
  );
};

export default ShowAssignment;
