import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAssignmentsByIds } from "../../../redux/assignmentsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EachAssignmentContentDrip = ({ assignment, setAssignmentDateList }) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(assignment?.releaseDate.date)
  );
  const [immediately, setImmediately] = useState(
    assignment?.releaseDate.immediately
  );

  const changePublishType = (e) => {
    let currentImmediate;
    if (e.target.value === "immediately") {
      setImmediately(true);
      currentImmediate = true;
    } else {
      setImmediately(false);
      currentImmediate = false;
    }
    setAssignmentDateList((prev) => {
      const assignmentInList = prev?.find(
        (ll) => ll.id === assignment.info.assignmentId
      );

      if (
        assignmentInList &&
        currentImmediate === assignment.releaseDate.immediately
      ) {
        return prev?.filter((ll) => ll.id !== assignment.info.assignmentId);
      } else if (
        !assignmentInList &&
        currentImmediate !== assignment.releaseDate.immediately
      ) {
        return [
          ...prev,
          {
            id: assignment.info.assignmentId,
            date: Date.now(selectedDate),
            immediately: currentImmediate,
          },
        ];
      } else {
        return prev;
      }
    });
  };

  const changeDate = (date) => {
    if (!immediately) {
      setSelectedDate(new Date(date));
      setAssignmentDateList((prev) => {
        const assignmentInList = prev?.find(
          (ll) => ll.id === assignment.info.assignmentId
        );

        if (
          assignmentInList &&
          date.getTime() === assignment.releaseDate.date
        ) {
          return prev?.filter((ll) => ll.id !== assignment.info.assignmentId);
        } else if (
          assignmentInList &&
          date.getTime() !== assignment.releaseDate.date
        ) {
          return prev?.map((ll) =>
            ll.id === assignment.info.assignmentId
              ? {
                  ...ll,
                  date: date.getTime(),
                }
              : ll
          );
        } else if (
          !assignmentInList &&
          date.getTime() !== assignment?.releaseDate.date
        ) {
          return [
            ...prev,
            {
              id: assignment.info.assignmentId,
              date: date.getTime(),
              immediately,
            },
          ];
        } else {
          return prev;
        }
      });
    }
  };

  console.log(typeof immediately);
  return (
    <div className="flex justify-between py-3 ">
      <div className="flex justify-around w-96">
        <p>{assignment.info.assignmentId}</p>
        <p>{assignment.info.title}</p>
      </div>
      <div className="w-96 flex justify-between">
        <select
          onChange={changePublishType}
          defaultValue={immediately ? "immediately" : "specificDate"}
        >
          <option value="immediately">Immediately</option>
          <option value="specificDate">Specific Date</option>
        </select>
        {!immediately && (
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              changeDate(date);
            }}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="Pp"
          />
        )}
      </div>
    </div>
  );
};

export default EachAssignmentContentDrip;
