import React, { useState } from "react";
import InputImageComp from "./InputImageComp";
import { db } from "../../../../firebase.config";
import { ref as realRef, set } from "firebase/database";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { deleteObject, getStorage, ref } from "firebase/storage";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../../redux/usersSlice";

const ProfileImage = () => {
  const { uid } = useParams();
  const newUser = useSelector((state) => getUserById(state, uid));
  const profile = newUser?.profile;
  // const [profileUrl, setProfileUrl] = useState("");
  const storage = getStorage();
  // const [fileInMemory, setFileInMemory] = useState("");
  const [changeImage, setChangeImage] = useState(false);

  const getImageUrl = ({ url, path }) => {
    // dispatch(addPhotoUrl({ url, path }));
    setChangeImage(false);
  };

  const removePhoto = () => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      if (!profile.role || !profile.uid || !profile.groupCode)
        throw new Error("role or id is not defined");
      const storageRefImage = ref(
        storage,
        `members/${profile.role}/${profile.uid}/images/profile`
      );
      deleteObject(storageRefImage).then(() => {
        const docRef = realRef(db, `/users/${profile.uid}/profile/photoUrl`);

        set(docRef, {
          path: "",
          url: "",
        });
        // dispatch(addPhotoUrl({ url: "", path: "" }));
        setChangeImage(true);
        toast.success("image succefully deleted");
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-2 items-center w-full">
      {profile?.photoUrl?.url && !changeImage ? (
        <div className="flex flex-col items-center">
          <img
            src={profile?.photoUrl?.url}
            width={300}
            height={300}
            alt={profile?.name}
            className="rounded-xl"
          />
          <div className="mt-3">
            <button
              className="mycu-btnBlue me-2"
              onClick={() => setChangeImage((prev) => !prev)}
            >
              Change profile Image
            </button>
            <button className="mycu-btnRed" onClick={removePhoto}>
              Remove Photo
            </button>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <InputImageComp
            path={`members/${profile?.role}/${profile?.uid}/images/profile`}
            unique={true}
            getImageUrl={getImageUrl}
            dbPath={`/users/${profile?.uid}/profile/photoUrl`}
          />
          {profile?.photoUrl?.url && changeImage && (
            <button
              className="mycu-btnBlue"
              onClick={() => setChangeImage(false)}
            >
              Cancle
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileImage;
