import React, { useEffect, useState } from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { IconButton, TextField } from "@mui/material";
const AddNamesInput = ({ placeholder, setParentList, parentList }) => {
  // const [list, setList] = useState([])
  const [inputField, setInputField] = useState("");

  const addToList = () => {
    // setList((prev) => ([...prev, inputField]))
    setParentList((prev) => [...prev, inputField]);
    setInputField("");
  };
  const removeField = (field) => {
    // const newList = list.filter((l) => l !== field)
    setParentList((prev) => prev.filter((l) => l !== field));
    // setList(newList)
  };
  // useEffect(() => {
  //     // console.log(setParentList((prev) => { console.log(prev) }))
  //     setParentList((prev) => ([...prev, inputField]))
  // }, [list])

  return (
    <div className="flex">
      <TextField
        variant="outlined"
        type="text"
        label={placeholder}
        value={inputField}
        onChange={(e) => setInputField(e.target.value)}
        size="small"
      />

      <IconButton disabled={!inputField} onClick={addToList} className="ms-2">
        <AddCircleOutlinedIcon className="text-blue-500" />
      </IconButton>

      {parentList?.map((field, index) => (
        <div className="flex ms-4 items-center" key={field + index}>
          <p className="me-1">{field}</p>
          <button
            onClick={() => {
              removeField(field);
            }}
            // className="text-red-600 border rounded-full w-6 h-6 border-red-600 flex items-center justify-center"
          >
            <RemoveCircleOutlinedIcon className="text-cu-red" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default AddNamesInput;
