import React, { useRef, useState } from "react";

const AvestaKeyboard = () => {
  const iframeRef = useRef();
  // const [iframeHeight, setIframeHeight] = useState("500px")
  // window.addEventListener("message", (e) => {
  //     console.log(e.origin, e.data)
  //     // if (e.source === "https://parsiban-ab634.firebaseapp.com/") {
  //     var message = e.data;
  //     let iheight = message.height + 200 + 'px';
  //     console.log(iheight)
  //     setIframeHeight(iheight)
  //     // }
  // }, false)
  return (
    <div>
      <iframe
        title="pubmed"
        ref={iframeRef}
        src="https://avesta-keyboard.web.app/"
        style={{ height: "600px" }}
        className="w-11/12 m-auto"
      ></iframe>
    </div>
  );
};

export default AvestaKeyboard;
