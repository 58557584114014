import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import GroupRow from "./GroupRow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getAllGroups } from "../../../redux/groupsSlice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import { getSingleUser } from "../../../firebase.config";
import { addManyUsers } from "../../../redux/usersSlice";

const ShowAllGroups = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const role = user?.profile?.role;
  const groups = useSelector(getAllGroups);
  const dispatch = useDispatch();

  const addNewGroup = () => {
    navigate("/adminpanel/addnewgroup");
  };

  useEffect(() => {
    if (role !== "student" && role !== "instructor") return;
    console.log(role);

    const getAllLeadInstructors = async () => {
      const promiseUser = groups.map((group) => {
        if (group?.leadInstructor?.length > 3) {
          return getSingleUser({
            groupCode: group.id,
            uid: group.leadInstructor,
          });
        }
      });

      const leadInstructorsUnfiltered = await Promise.all(promiseUser);
      const leadInstructors = leadInstructorsUnfiltered.filter(
        (leadInstructor) => leadInstructor !== undefined
      );
      dispatch(addManyUsers(leadInstructors));
    };
    getAllLeadInstructors();
  }, [groups, role]);

  return (
    <div className="w-7xl m-auto mt-10 flex flex-col mx-10">
      <div className="mb-5 ">
        Groups:
        <IconButton onClick={addNewGroup} className="ms-3">
          <AddCircleIcon className="text-blue-500" />
        </IconButton>
      </div>

      <table className="table-auto ">
        <thead>
          <tr className="flex items-center border py-3">
            {/* <th className="flex px-3 py-2 gap-3">
              <button>Profile</button>
            </th> */}
            <th className="w-1/5">
              <button>Color</button>
            </th>
            {/* <th className="w-1/5">
              <button>Code</button>
            </th> */}
            <th className="w-1/5">
              <button>Name</button>
            </th>
            <th className="w-1/5">
              <button>نام</button>
            </th>
            <th className="w-1/5">
              <button>Lead Instructor</button>
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {groups?.map((item, index) => {
            return <GroupRow key={`itemname${index}`} item={item} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ShowAllGroups;
