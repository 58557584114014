import React, { useEffect, useState } from "react";
import { createEmptyCourseInDb } from "../../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import {
  addOtherInfo,
  getCourseById,
  updateCourseStatus,
} from "../../../../redux/coursesSlice";
import { useParams } from "react-router-dom";
import InputImageStorage from "./InputImageStorage";
import { Button } from "@mui/material";

const CourseImageSettings = () => {
  const { courseId } = useParams();
  const currentCourse = useSelector((state) => getCourseById(state, courseId));
  const files = currentCourse?.files;
  const dispatch = useDispatch();
  // const [imageUrl, setImageUrl] = useState(currentCourse.files.courseImage);
  //   const [fileInMemory, setFileInMemory] = useState("");
  const [changeImage, setChangeImage] = useState(false);

  const getImageUrl = async (imageInfo) => {
    // setImageUrl(imageInfo);
    if (currentCourse.info.status === "temp") {
      dispatch(updateCourseStatus({ courseId, status: "draft" }));
      await createEmptyCourseInDb({ course: currentCourse });
    }
    dispatch(
      addOtherInfo({
        id: courseId,
        changes: { files: { courseImage: { ...imageInfo } } },
      })
    );
  };

  // const uploadPhoto = () => {
  // useEffect(() => {
  //     // if (!fileInMemory) return
  //     // console.log("ran")
  // }, [dispatch, fileInMemory, profile.uid, profileUrl])
  // }
  // useEffect(() => {
  //   if (!imageUrl) return;
  //   dispatch(addCourseImage(imageUrl));
  // }, [dispatch, imageUrl]);

  return (
    <div className="flex flex-col gap-y-2  w-full">
      {files?.courseImage && !changeImage ? (
        <div className="w-72 flex flex-col justify-center items-center">
          <img
            className="contain"
            src={files?.courseImage?.url}
            alt={"course image"}
          />
          <Button className="mycu-btnBlue" onClick={() => setChangeImage(true)}>
            Change Image
          </Button>
        </div>
      ) : (
        <>
          <InputImageStorage
            path={`courses/${courseId}/images/courseImage`}
            getImageUrl={getImageUrl}
            // unique={true}
            // dbPath={`courses/${newCourse.info.courseId}/images/courseImage`}
          />
        </>
      )}
      {files?.courseImage && changeImage && (
        <Button className="mycu-btnBlue" onClick={() => setChangeImage(false)}>
          Cancle
        </Button>
      )}
    </div>
  );
};

export default CourseImageSettings;
