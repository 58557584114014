// import { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getStorage } from "firebase/storage";
// import {
//   db,
//   deleteStorageRecursively,
//   removeSinleRealPath,
//   updateRegisteryForm,
// } from "../../../../firebase.config";
// import { toast } from "react-toastify";
// import { useParams } from "react-router-dom";
// import { getUserById } from "../../../../redux/usersSlice";
// import InputMultiplePdf from "../../../shared/sharedComp/multiplePdf/InputMultiplePdf";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import { Button } from "@mui/material";
// import ViewFile from "../../../../layout/ViewFile";
// import PdfShow from "../../../shared/resourses/showFile/PdfShow";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import PreviewIcon from "@mui/icons-material/Preview";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

// const RegisterationForm = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const menuRef = useRef(null);

//   const openMenu = () => {
//     setMenuOpen(true);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         closeMenu();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const { uid } = useParams();
//   const user = useSelector((state) => getUserById(state, uid));
//   const profile = user.profile;
//   const { role, groupCode } = { ...profile };
//   const registeryDocs = user?.documents?.registeryForm;
//   const existedPdf = registeryDocs ? Object.values(registeryDocs) : [];
//   const [changeImage, setChangeImage] = useState(false);
//   const storage = getStorage();
//   const storagePath = `/members/${role}/${uid}/registeryForm/`;
//   const [list, setList] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const openModal = () => {
//     document.body.style.overflowY = "hidden";
//     setIsModalOpen(true);
//   };
//   const closeModal = () => {
//     document.body.style.overflowY = "scroll";
//     setIsModalOpen(false);
//   };

//   // useEffect(() => {
//   //   if (!registerationForm.image || !registerationForm.pdf) return;
//   //   dispatch(updateRegisteryForm({ uid, urls: registerationForm }));
//   // }, [dispatch, registerationForm, uid]);

//   // const uploadForm = () => {
//   //   dispatch(updateRegisterForm({ uid, urls: registerationForm }));
//   // };

//   // const getNumberOfPages = () => {};

//   // const getFilePath = () => {};

//   const removePdf = async (name) => {
//     if (window.confirm("Are you sure you want to delete this file?")) {
//       await deleteStorageRecursively(
//         `members/${role}/${uid}/${name}/registerationForm/`
//       );
//       await removeSinleRealPath(
//         "/users/" + uid + "/documents/registeryForm/" + name
//       );
//     }
//   };
//   const viewPdf = () => {};

//   const getList = (pdfsList) => {
//     const objList = pdfsList.reduce((acc, curr) => {
//       const replacedString = curr.name.replace(/[#$\/\[\].\s]/g, "_");
//       acc[replacedString] = curr;
//       return acc;
//     }, {});
//     setList(objList);
//   };

//   const sendToReal = async () => {
//     try {
//       const res = await updateRegisteryForm({ uid, pdfs: list, groupCode });
//       setChangeImage(false);
//       toast.success(res.message);
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };

//   return (
//     <div>
//       <Button
//         onClick={() => {
//           setChangeImage(true);
//         }}
//       >
//         Add New <AddCircleIcon className="text-blue-500" />
//       </Button>
//       {existedPdf.length && !changeImage ? (
//         <div className="m-auto w-60">
//           <Swiper navigation={true} modules={[Navigation]}>
//             {existedPdf.map((pdf, index) => (
//               <SwiperSlide>
//                 <div className="flex flex-col items-center border rounded p-3">
//                   <img
//                     key={pdf?.name + index}
//                     src={pdf?.url?.image}
//                     alt={pdf?.name}
//                     className="contain"
//                   />
//                   <p>{`${pdf?.name} (${pdf?.pagesCount})`} </p>
//                 </div>

//                 <Button
//                   onClick={() => removePdf(pdf.name)}
//                   sx={{ color: "red" }}
//                 >
//                   Remove
//                 </Button>
//                 <Button onClick={openModal}>View</Button>
//                 <ViewFile
//                   isOpen={isModalOpen}
//                   onClose={closeModal}
//                   title={"Registeration Form"}
//                 >
//                   <PdfShow pdf={{ file: { ...pdf }, info: { ...pdf } }} />
//                 </ViewFile>
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//       ) : (
//         <divs>
//           <InputMultiplePdf path={storagePath} getList={getList} />
//           <Button disabled={!Object.values(list).length} onClick={sendToReal}>
//             Submit
//           </Button>
//           {existedPdf.length && (
//             <Button
//               onClick={() => {
//                 setChangeImage(false);
//               }}
//             >
//               Cancel
//             </Button>
//           )}
//         </divs>
//       )}
//     </div>
//   );
// };

// export default RegisterationForm;

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStorage } from "firebase/storage";
import {
  db,
  deleteStorageRecursively,
  removeSinleRealPath,
  updateRegisteryForm,
} from "../../../../firebase.config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../../redux/usersSlice";
import InputMultiplePdf from "../../../shared/sharedComp/multiplePdf/InputMultiplePdf";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Button, IconButton, styled } from "@mui/material";
import ViewFile from "../../../../layout/ViewFile";
import PdfShow from "../../../shared/resourses/showFile/PdfShow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

const RegisterationForm = () => {
  const { uid } = useParams();
  const user = useSelector((state) => getUserById(state, uid));
  const profile = user.profile;
  const { role, groupCode } = { ...profile };
  const registeryDocs = user?.documents?.registeryForm;
  const existedPdf = registeryDocs ? Object.values(registeryDocs) : [];
  const [changeImage, setChangeImage] = useState(false);
  const storage = getStorage();
  const storagePath = `/members/${role}/${uid}/registeryForm/`;
  // const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    // document.body.style.overflowY = "hidden";
    setIsModalOpen(true);
  };
  const closeModal = () => {
    // document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   if (!registerationForm.image || !registerationForm.pdf) return;
  //   dispatch(updateRegisteryForm({ uid, urls: registerationForm }));
  // }, [dispatch, registerationForm, uid]);

  // const uploadForm = () => {
  //   dispatch(updateRegisterForm({ uid, urls: registerationForm }));
  // };

  // const getNumberOfPages = () => {};

  // const getFilePath = () => {};

  const removePdf = async (name) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await deleteStorageRecursively(
          `members/${role}/${uid}/registeryForm/${name}`
        );
        await removeSinleRealPath(
          `/users/${uid}/documents/registeryForm/${name}`
        );
        toast.success("successfully deleted");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };
  // const viewPdf = () => {};

  // const getList = (pdfsList) => {
  //   const objList = pdfsList.reduce((acc, curr) => {
  //     const replacedString = curr.name.replace(/[#$\/\[\].\s]/g, "_");
  //     acc[replacedString] = curr;
  //     return acc;
  //   }, {});
  //   setList(objList);
  // };

  // const sendToReal = async () => {
  //   try {
  //     const res = await updateRegisteryForm({ uid, pdfs: list, groupCode });
  //     setChangeImage(false);
  //     toast.success(res.message);
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  return (
    <div>
      <InputMultiplePdf path={storagePath} />

      <div className="m-auto w-96">
        <Swiper navigation={true} modules={[Navigation]} spaceBetween={30}>
          {existedPdf.map((pdf, index) => (
            <SwiperSlide key={index} className="border">
              <div className="w-full flex items-center justify-end">
                <IconButton
                  onClick={() => removePdf(pdf.name)}
                  sx={{ color: "red" }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </div>
              <div className="relative flex flex-col items-center  rounded p-3 mx-16 group">
                <img
                  src={pdf?.url?.image}
                  alt={pdf?.name}
                  className="contain"
                />
                <p className="text-center">
                  {`${pdf?.name} (${pdf?.pagesCount} pages) `}{" "}
                </p>
                <div
                  onClick={openModal}
                  className="absolute inset-0 flex items-center justify-center bg-slate-500 bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                >
                  {/* <IconButton> */}
                  <VisibilityIcon
                    className="text-white"
                    sx={{ fontSize: "40px" }}
                  />
                  {/* </IconButton> */}
                </div>
              </div>

              <ViewFile
                isOpen={isModalOpen}
                onClose={closeModal}
                title={"Registeration Form"}
              >
                <PdfShow pdf={{ file: { ...pdf }, info: { ...pdf } }} />
              </ViewFile>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* <Button disabled={!Object.values(list).length} onClick={sendToReal}>
            Submit
          </Button> */}
      {/* {existedPdf.length && (
            <Button
              onClick={() => {
                setChangeImage(false);
              }}
            >
              Cancel
            </Button>
          )} */}
    </div>
  );
};

export default RegisterationForm;
