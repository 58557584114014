import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const lessonsAdapter = createEntityAdapter();

const initialState = lessonsAdapter.getInitialState();

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    addlessons: lessonsAdapter.setAll,
    createEmptyLesson: lessonsAdapter.setOne,
    removeNotPublishedLesson: lessonsAdapter.removeOne,
    updatePdfId: (state, { payload }) => {
      const { lessonId, pdf } = payload;
      const entity = state.entities[lessonId];
      entity.files.pdf = pdf;
    },
    uploadResourceId: (state, { payload }) => {
      const { lessonId, resourceId, item } = payload;
      const entity = state.entities[lessonId];
      entity.resources[resourceId] = item;
    },
    addNewResource: (state, { payload }) => {
      const { lessonId, item } = payload;
      const entity = state.entities[lessonId];
      entity.resources[item.resourceId] = item;
    },
    updateImages: (state, { payload }) => {
      const { lessonId, images } = payload;
      const entity = state.entities[lessonId];
      entity.files.images = images;
    },
    updateVideoId: (state, { payload }) => {
      const { lessonId, video } = payload;
      const entity = state.entities[lessonId];
      entity.files.video = video;
    },
    addLessonInfo: lessonsAdapter.updateOne,
    updateLessonStatus: (state, { payload }) => {
      const { lessonId, status } = payload;
      state.entities[lessonId].info.status = status;
    },
  },
});

export const {
  addlessons,
  createEmptyLesson,
  removeNotPublishedLesson,
  updatePdfId,
  updateVideoId,
  updateImages,
  addLessonInfo,
  updateLessonStatus,
  uploadResourceId,
  addNewResource,
} = lessonsSlice.actions;
export const getAllLessons = (state) =>
  lessonsAdapter
    .getSelectors()
    .selectAll(state.lessons)
    .sort((a, b) => b.info.rowId - a.info.rowId);

export const getLessonById = (state, lessonId) =>
  lessonsAdapter.getSelectors().selectById(state.lessons, lessonId);

export const getLessonEntities = (state) =>
  lessonsAdapter.getSelectors().selectEntities(state.lessons);

export const getLessonsByIds = createSelector(
  [getLessonEntities, (state, ids) => ids],
  (entities, ids) =>
    ids
      .map((id) => entities[id])
      .sort((a, b) => b.info.createAt - a.info.createAt)
);

export const getLessonsByGroupcode = (state, groupCode) => {
  const lessons = Object.values(state.lessons.entities);
  return lessons
    .filter((lesson) => lesson.info.groupCode === groupCode)
    .sort((a, b) => b.info.createAt - a.info.createAt);
};
export default lessonsSlice.reducer;
