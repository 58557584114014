import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const videoAdaptor = createEntityAdapter();

const initialState = videoAdaptor.getInitialState({
  loading: false,
  error: null,
});

const allVideoSlice = createSlice({
  name: "allVideos",
  initialState,
  reducers: {
    setVideos: videoAdaptor.setAll,
  },
  setVideoLoading: (state, action) => {
    state.loading = action.payload;
  },
  setAllVideoError: (state, action) => {
    state.error = action.payload;
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchVideos.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(fetchVideos.fulfilled, (state, action) => {
  //       state.loading = false;
  //       videioAdaptor.setAll(state, action.payload);
  //     })
  //     .addCase(fetchVideos.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
});

export const { setVideos, setVideoLoading, setAllVideoError } =
  allVideoSlice.actions;

export const selectAllVideos = (state) =>
  videoAdaptor
    .getSelectors()
    .selectAll(state.allVideos)
    .sort((a, b) => Number(b.info.createAt) - Number(a.info.createAt));
// .sort((a, b) => b.info.name.localeCompare(a.info.name));
export const selectVideoById = (state, videoId) =>
  videoAdaptor.getSelectors().selectById(state.allVideos, videoId);
export default allVideoSlice.reducer;
