import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatIcon from "@mui/icons-material/Chat";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Snackbar,
  Typography,
} from "@mui/material";
import { onChildAdded, onChildChanged, onValue, ref } from "firebase/database";
import { db, signOutUser } from "../../../firebase.config";
import { timeAgo } from "../../../shared/utils/timeAgo";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logoutUser } from "../../../redux/userSlice";
import { Grid } from "react-loader-spinner";
import EachNotification from "./EachNotification";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { gapi } from "gapi-script";
// import { google } from "googleapis";

const AuthenticatedUserNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Ref for the dropdown menu

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [notifications, setNotifications] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [newUpdatedNot, setNewUpdatedNot] = useState([]);
  const user = useSelector((state) => state.user);
  const profile = user?.profile;
  const navigate = useNavigate();
  const messageButtonRef = useRef(null);
  const dispatch = useDispatch();

  const getNotification = () => {
    setOpenNotification(true);
  };

  const showMessages = () => {
    setOpenMessage((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenMessage(false);
  };

  // useEffect(() => {
  //   const notificationRef = ref(db, `notification/users/${user.profile.uid}`);
  //   return onValue(notificationRef, (snap) => {
  //     if (!snap.val()) return;
  //     const unsortedNotifications = Object.values(snap.val());

  //     const sortedNotifications = unsortedNotifications.sort(
  //       (a, b) => b.createAt - a.createAt
  //     );
  //     setNotifications(sortedNotifications);
  //   });
  // }, [user.profile.uid]);

  // useEffect(() => {
  //   const notificationRef = ref(db, `/notification/users/${user.profile.uid}`);
  //   return onChildAdded(notificationRef, (snap) => {
  //     const newData = snap.val();
  //     const key = snap.key;
  //     if (!snap.val().seen) {
  //       setNewUpdatedNot((prevData) => [...prevData, { key, ...newData }]);
  //       setTimeout(() => {
  //         setNewUpdatedNot((prevData) =>
  //           prevData.filter((item) => item.key !== key)
  //         );
  //       }, 10000); // 10000 milliseconds = 10 seconds
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const notificationRef = ref(db, `notification/users/${user.profile.uid}`);

    let previousKeys = new Set();

    const listener = onValue(notificationRef, (snap) => {
      if (!snap.val()) return;

      const unsortedNotifications = Object.entries(snap.val()).map(
        ([key, value]) => ({
          key,
          ...value,
        })
      );
      const sortedNotifications = unsortedNotifications.sort(
        (a, b) => b.createAt - a.createAt
      );

      // Update all notifications
      setNotifications(sortedNotifications);

      // Detect newly added notifications (based on unseen data or missing keys)
      const newNotifications = sortedNotifications.filter(
        (notification) =>
          !previousKeys.has(notification.key) && !notification.seen
      );

      // Add new notifications to the "newUpdatedNot" state
      if (newNotifications.length > 0) {
        setNewUpdatedNot((prevData) => [...prevData, ...newNotifications]);

        // Automatically remove new notifications after 10 seconds
        newNotifications.forEach((notification) => {
          setTimeout(() => {
            setNewUpdatedNot((prevData) =>
              prevData.filter((item) => item.key !== notification.key)
            );
          }, 10000); // 10 seconds
        });
      }

      // Update the set of previous keys
      previousKeys = new Set(
        sortedNotifications.map((notification) => notification.key)
      );
    });

    return () => listener(); // Cleanup listener on unmount
  }, [user.profile.uid]);

  const logout = async () => {
    try {
      if (window.confirm("are you sure you want to sign out")) {
        await signOutUser();
        dispatch(logoutUser());
        toast.success("you sign out from your account");
        navigate("/");
      }
    } catch (error) {
      toast.error(error + "something is wrong");
    }
  };
  const handleClose = () => {
    setNewUpdatedNot([]);
  };

  const getData = () => {
    fetch("https://chat.googleapis.com/v1/spaces", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };
  return (
    <div className="w-full h-12 bg-cu-blue relative flex items-center">
      {/* <div className="h-full absolute top-[2px]"></div> */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 text-center"
        style={{ lineHeight: "3px" }}
        // onClick={getData}
      >
        <h2 className="w-full text-white font-bold text-2xl" onClick={getData}>
          LMS
        </h2>
        <p style={{ color: "white", margin: "auto", fontSize: "12px" }}>
          (learning management system)
        </p>
      </div>
      {newUpdatedNot.length ? (
        <Box sx={{ width: 500 }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            onClose={handleClose}
            message={
              <div className="flex flex-col gap-y-2">
                {newUpdatedNot.map((note) => {
                  return (
                    <div className="border-b-2 border-b-cu-red">
                      <p className="font-bold text-lg ">{note.title}</p>
                      <p className="ps-3 w-96">{note.text}</p>
                    </div>
                  );
                })}
              </div>
            }
            key={"topright"}
          />
        </Box>
      ) : (
        ""
      )}
      <div className="ml-auto flex items-center gap-x-3 pe-2 me-4">
        <Typography className="text-cu-red">
          Ver: {process.env.REACT_APP_VERSION}
        </Typography>
        <IconButton
          aria-label="messages"
          onClick={showMessages}
          ref={messageButtonRef}
        >
          <Badge badgeContent={0} color="primary" showZero>
            <ChatIcon className="text-white" sx={{ fontSize: "25px" }} />
          </Badge>
        </IconButton>
        <div>
          <Popper open={openMessage} anchorEl={messageButtonRef.current}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className="p-2 mt-3 ps-4 pe-4 underline">
                <a
                  target="_blank"
                  href="https://mail.google.com/mail/u/0/#chat/home"
                  className="text-blue-500"
                >
                  Open gmail
                </a>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
        <IconButton aria-label="notification" onClick={getNotification}>
          <Badge
            badgeContent={
              notifications.filter((notification) => !notification.seen).length
            }
            color="primary"
            overlap="circular"
          >
            <NotificationsNoneIcon
              className="text-white"
              sx={{ fontSize: "30px" }}
            />
          </Badge>
        </IconButton>
        {openNotification && (
          <ClickAwayListener
            onClickAway={() => {
              setOpenNotification(false);
            }}
          >
            <div className="absolute top-12 right-2 w-96 h-96 overflow-auto z-20">
              {notifications?.map((notification) => {
                return <EachNotification notification={notification} />;
              })}

              {/* <Collapse in={false}> */}
              {/* </Collapse> */}
              {/* <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              This is an info notification with an informative title.
            </Alert>
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              This is a warning notification with a cautious title.
            </Alert>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              This is an error notification with a scary title.
            </Alert> */}
            </div>
          </ClickAwayListener>
        )}
        <div className="relative">
          {/* Avatar Button */}
          <IconButton onClick={toggleMenu} aria-label="avatar">
            <Avatar src={profile?.photoUrl?.url} alt="User Avatar" />
          </IconButton>

          {/* Profile Dropdown */}
          {menuOpen && (
            <div
              ref={menuRef} // Attach ref to the dropdown
              className="absolute right-0 mt-2 w-80 bg-white border rounded-xl shadow-lg z-20"
            >
              <div className="p-4 flex flex-col gap-2">
                {/* Profile Link */}
                <Link
                  to={
                    profile?.role === "student"
                      ? "/studentpanel/profile"
                      : profile?.role === "admin" ||
                        profile?.role === "instructor"
                      ? "/adminpanel"
                      : profile?.role === "staff"
                      ? "/staffpanel/profile"
                      : "/notfound"
                  }
                  className="text-blue-500 text-sm font-medium hover:underline"
                >
                  Profile
                </Link>

                {/* User Info */}
                <div className="text-gray-800 font-medium text-sm">
                  {profile?.name} {profile?.family}
                </div>
                <div className="text-gray-500 text-xs">{profile?.email}</div>

                {/* Logout Button */}
                <button
                  onClick={logout}
                  className="text-red-500 text-sm font-medium hover:underline"
                >
                  Log Out
                </button>
              </div>
            </div>
          )}
        </div>
        {/* <img
          // src={}
          src={user.profile.photoUrl}
          alt=""
          className="w-12 h-12 border rounded-full object-contain"
        /> */}
      </div>
    </div>
  );
};

export default AuthenticatedUserNav;
// {
//   /* <Button */
// }
// // sx={{
// //     width: '200px',
// //     height: '50px',
// //     backgroundColor: 'primary.main',
// //     '&:hover': {
// //         backgroundColor: 'primary.dark',
// //     },
// // }}
// // >
// // Custom Styled Button
// // </Button>
// import React, { useEffect, useRef, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Badge from "@mui/material/Badge";
// import IconButton from "@mui/material/IconButton";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import ChatIcon from "@mui/icons-material/Chat";
// import {
//   Avatar,
//   Box,
//   Button,
//   ClickAwayListener,
//   Paper,
//   Popper,
//   Snackbar,
//   Typography,
//   MenuItem,
//   MenuList,
// } from "@mui/material";
// import { onChildAdded, onValue, ref } from "firebase/database";
// import { db, signOutUser } from "../../../firebase.config";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import { logoutUser } from "../../../redux/userSlice";

// const AuthenticatedUserNav = () => {
//   const [notifications, setNotifications] = useState([]);
//   const [openNotification, setOpenNotification] = useState(false);
//   const [openMessage, setOpenMessage] = useState(false);
//   const [openProfile, setOpenProfile] = useState(false);
//   const [newUpdatedNot, setNewUpdatedNot] = useState([]);
//   const user = useSelector((state) => state.user);
//   const profile = user?.profile;
//   const navigate = useNavigate();
//   const messageButtonRef = useRef(null);
//   const profileButtonRef = useRef(null);
//   const dispatch = useDispatch();

//   // Fetch notifications from Firebase
//   useEffect(() => {
//     const notificationRef = ref(db, `notification/users/${user.profile.uid}`);
//     return onValue(notificationRef, (snap) => {
//       if (!snap.val()) return;
//       const unsortedNotifications = Object.values(snap.val());
//       const sortedNotifications = unsortedNotifications.sort(
//         (a, b) => b.createAt - a.createAt
//       );
//       setNotifications(sortedNotifications);
//     });
//   }, [user.profile.uid]);

//   // Handle new notifications
//   useEffect(() => {
//     const notificationRef = ref(db, `/notification/users/${user.profile.uid}`);
//     return onChildAdded(notificationRef, (snap) => {
//       const newData = snap.val();
//       const key = snap.key;
//       if (!snap.val().seen) {
//         setNewUpdatedNot((prevData) => [...prevData, { key, ...newData }]);
//         setTimeout(() => {
//           setNewUpdatedNot((prevData) =>
//             prevData.filter((item) => item.key !== key)
//           );
//         }, 10000); // Clear after 10 seconds
//       }
//     });
//   }, []);

//   const handleLogout = async () => {
//     if (window.confirm("Are you sure you want to sign out?")) {
//       try {
//         await signOutUser();
//         dispatch(logoutUser());
//         toast.success("You signed out successfully.");
//         navigate("/");
//       } catch (error) {
//         toast.error("Something went wrong: " + error.message);
//       }
//     }
//   };

//   const toggleProfileMenu = () => {
//     setOpenProfile((prev) => !prev);
//   };

//   const handleCloseProfileMenu = () => {
//     setOpenProfile(false);
//   };

//   return (
//     <div className="w-full h-12 bg-cu-blue flex items-center relative">
//       {/* Centered LMS Title */}
//       <div className="absolute left-1/2 transform -translate-x-1/2 text-center">
//         <Typography variant="h5" className="text-white font-bold">
//           LMS
//         </Typography>
//         <Typography variant="caption" className="text-white">
//           (Learning Management System)
//         </Typography>
//       </div>

//       {/* Right-hand side buttons */}
//       <div className="ml-auto flex items-center gap-x-3 me-4">
//         {/* Version Info */}
//         <Typography variant="body2" color="secondary">
//           Ver: {process.env.REACT_APP_VERSION}
//         </Typography>

//         {/* Chat Icon */}
//         <IconButton
//           aria-label="messages"
//           onClick={() => setOpenMessage((prev) => !prev)}
//           ref={messageButtonRef}
//         >
//           <Badge badgeContent={0} color="primary" showZero>
//             <ChatIcon className="text-white" sx={{ fontSize: "25px" }} />
//           </Badge>
//         </IconButton>

//         {/* Notifications */}
//         <IconButton
//           aria-label="notification"
//           onClick={() => setOpenNotification(true)}
//         >
//           <Badge
//             badgeContent={
//               notifications.filter((notification) => !notification.seen).length
//             }
//             color="primary"
//           >
//             <NotificationsNoneIcon
//               className="text-white"
//               sx={{ fontSize: "30px" }}
//             />
//           </Badge>
//         </IconButton>

//         {/* Profile Dropdown */}
//         <div>
//           <IconButton
//             ref={profileButtonRef}
//             aria-label="profile"
//             onClick={toggleProfileMenu}
//           >
//             <Avatar src={profile?.photoUrl?.url} alt="Profile Picture" />
//           </IconButton>

//           {/* Profile Menu */}
//           <Popper
//             open={openProfile}
//             anchorEl={profileButtonRef.current}
//             placement="bottom-end"
//             disablePortal
//           >
//             <ClickAwayListener onClickAway={handleCloseProfileMenu}>
//               <Paper elevation={3} sx={{ width: 200 }}>
//                 <MenuList>
//                   <MenuItem>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       {profile?.name} {profile?.family}
//                     </Typography>
//                   </MenuItem>
//                   <MenuItem>{profile?.email}</MenuItem>
//                   <MenuItem
//                     component={Link}
//                     to={
//                       profile.role === "student"
//                         ? "/studentpanel/profile"
//                         : profile.role === "admin"
//                         ? "/adminpanel"
//                         : profile.role === "instructor"
//                         ? "/adminpanel"
//                         : profile.role === "staff"
//                         ? "/staffpanel/profile"
//                         : "/notfound"
//                     }
//                   >
//                     Profile
//                   </MenuItem>
//                   <MenuItem onClick={handleLogout} sx={{ color: "red" }}>
//                     Log Out
//                   </MenuItem>
//                 </MenuList>
//               </Paper>
//             </ClickAwayListener>
//           </Popper>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AuthenticatedUserNav;
