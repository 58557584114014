import { get, onValue, ref } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import {
  getAllCourses,
  getCourseById,
  updateCourseStatus,
} from "../../../redux/coursesSlice";
import {
  addPrerequisiteToCourse,
  createEmptyCourseInDb,
} from "../../../firebase.config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
      width: 250,
    },
  },
  disablePortal: true, // Disable the default search functionality
};

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}
function getStyles(course, selectedCourse, theme) {
  return {
    fontWeight: selectedCourse.includes(course)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function PrerequisiteAssign({ groupCode }) {
  const user = useSelector((state) => state.user);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const allCourses = useSelector(getAllCourses);
  const [courses, setCourses] = useState([]);
  const currentCourse = useSelector((state) => getCourseById(state, courseId));
  const prerequisiteCourses = currentCourse?.prerequisiteCourses
    ? Object.keys(currentCourse.prerequisiteCourses)
    : [];
  const theme = useTheme();
  const [selectedCourse, setSelectedCourse] = useState(
    [undefined, ...prerequisiteCourses] || [undefined]
  );

  useEffect(() => {
    if (!groupCode) return;
    const lll = allCourses.filter(
      (course) => course.info.groupCode === groupCode
    );
    setCourses(lll);
  }, [groupCode]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const inputRef = useRef(null);
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    console.log(value);
    setSelectedCourse(() => {
      if (!value.includes(undefined)) {
        return [undefined, ...value];
      } else {
        return value;
      }
    });
  };

  const addPrerequisite = async () => {
    if (currentCourse.info.status === "temp" && user.profile.role === "admin") {
      if (
        window.confirm(
          "After assign lesson course you can change group code anymore, are you willing to proceed ?"
        )
      ) {
        try {
          await createEmptyCourseInDb({ course: currentCourse });
          await addPrerequisiteToCourse({
            groupCode,
            courseId,
            prerequisiteCourses: selectedCourse,
          });
          dispatch(updateCourseStatus({ courseId, status: "draft" }));

          toast.success("changes saved");
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        //add pre

        toast.success("changes saved");
      } catch (error) {
        console.log(error);
      }
    }
  };

  //   const addPrerequisite = () => {};

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = () => {
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    if (!isDropdownOpen) return;
    if (inputRef.current && isDropdownOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [isDropdownOpen]);

  const handleClose = () => {
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    const newCourses = allCourses.filter((course) =>
      course.info.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (searchTerm) {
      setFilteredCourses(newCourses);
    } else {
      setFilteredCourses(courses);
    }
    // if (isDropdownOpen) {
    //   console.log("object");
    //   inputRef.current.focus();
    // }
  }, [courses, searchTerm]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <FormControl className="w-full">
      <Select
        multiple
        displayEmpty
        value={selectedCourse}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 1) {
            return <em>Select Prerequisites For Course</em>;
          }
          const selCrs = courses.filter((course) =>
            selected.includes(course.info.courseId)
          );

          return (
            <div className="text-wrap">
              {selCrs.map(
                (crs) =>
                  crs !== undefined && (
                    <p>
                      {crs.info.title} - {crs.info?.name}
                    </p>
                  )
              )}
            </div>
          );
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {isDropdownOpen && (
          // <div className="px-3">
          <div
            className="px-3"
            onKeyDown={(e) => e.stopPropagation()}
            onClickCapture={stopImmediatePropagation}
          >
            <TextField
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              margin="dense"
              inputRef={inputRef}
            />
          </div>
          // </div>
        )}
        {filteredCourses?.map((course) => (
          <MenuItem
            key={course.info.courseId}
            value={course.info.courseId}
            style={getStyles(course, selectedCourse, theme)}
            // onKeyDown={(e) => e.stopPropagation()}
          >
            <Checkbox
              checked={selectedCourse.indexOf(course.info.courseId) > -1}
            />
            <ListItemText primary={course.info.title} />
          </MenuItem>
        ))}
        <div className="flex w-full items-center justify-center sticky bottom-0 bg-white py-1">
          <Button
            variant="contained"
            color="primary"
            onClick={addPrerequisite}
            disabled={arraysEqual(
              [undefined, ...prerequisiteCourses],
              selectedCourse
            )}
          >
            {" "}
            Save Changes{" "}
          </Button>
        </div>
      </Select>
    </FormControl>
  );
}
