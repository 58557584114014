import React, { useRef } from "react";

const Shahname = () => {
  const iframeRef = useRef();

  // function setIframeHeight() {
  //   const iframe = document.getElementById("myIframe");
  //   iframe.style.height = `${window.innerHeight}px`;
  // }

  // // Set the iframe height on page load
  // window.onload = setIframeHeight;

  // // Adjust the iframe height when the window is resized
  // window.onresize = setIframeHeight;
  return (
    <div>
      <iframe
        id="myIframe"
        title="shahname"
        ref={iframeRef}
        src="https://shahname-f0b3a.web.app/"
        style={{ height: "calc(100vh - 88px)" }}
        className="w-full m-auto mt-2"
      ></iframe>
    </div>
  );
};

export default Shahname;
