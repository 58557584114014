export function timeAgo(timestamp) {
  const now = new Date();
  const diff = now - timestamp;

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;

  if (diff < minute) {
    return "just now";
  } else if (diff < hour) {
    const minutesAgo = Math.floor(diff / minute);
    return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
  } else if (diff < day) {
    const hoursAgo = Math.floor(diff / hour);
    return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  } else {
    const daysAgo = Math.floor(diff / day);
    return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  }
}

// Example usage:
//   const timestamp = new Date('2023-05-10T12:30:00Z'); // Replace with your timestamp
