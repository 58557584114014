import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAssignmentById } from "../../../redux/assignmentsSlice";
import ReactQuill from "react-quill";
import TimeShow from "../../../shared/utils/TimeShow";
import ViewAddedResources from "../sharedComp/showResources/ViewAddedResources";

const ReviewAssignment = () => {
  const { assignmentId } = useParams();
  const assignment = useSelector((state) =>
    getAssignmentById(state, assignmentId)
  );

  const { title, explain, createAt, editAt, status, assignmentCreator } =
    assignment.info;
  const assignedLessons = assignment.assignedLessons
    ? Object.keys(assignment.assignedLessons)
    : [];
  return (
    <Card className="w-11/12 mx-auto my-4">
      {/* <img
        src={lesson.files.lessonImage.url}
        alt={title}
        className="w-full h-64 object-contain"
      /> */}
      <CardContent>
        {/* Group One */}
        <Typography variant="h6" component="div" className="pt-8">
          Assignment Details
        </Typography>
        <Typography variant="h5" component="div" className="text-center">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Explain: <ReactQuill value={explain} readOnly={true} theme="bubble" />
        </Typography>
        <ViewAddedResources content={assignment} />
        <Grid container spacing={1} className="pt-8">
          <Grid item xs={6}>
            <Typography variant="body2">Lesson ID: {assignmentId}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Publish Date: <TimeShow date={new Date(createAt)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Edit At: <TimeShow date={new Date(editAt)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Create At:
              <TimeShow date={new Date(createAt)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Status: {status}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Assignment Creator: {assignmentCreator}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" component="div" className="pt-8">
          Assigned Lessons
        </Typography>
        <Grid container spacing={1} className="mt-2">
          {assignedLessons.map((lesson) => (
            <Grid item xs={6}>
              {/* <Typography variant="body2">{lesson.title}</Typography> */}
              <div variant="body2" className="text-xs text-slate-500">
                {lesson}
              </div>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReviewAssignment;
