// CollapsibleSection.js
import React, { useState } from "react";
import {
  List,
  Collapse,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { SelectedListItemButton } from "./StyledComponent"; // Import styled component here

const CollapsibleSection = ({
  title,
  icon,
  items,
  selectedIndex,
  handleItemClick,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <SelectedListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ color: "white" }}>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </SelectedListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ text, path, icon }, index) => (
            <SelectedListItemButton
              key={text}
              selected={selectedIndex === index}
              onClick={() => handleItemClick(index, path)}
            >
              <Button
                fullWidth
                style={{ justifyContent: "flex-start", color: "#fff" }}
              >
                {icon}
                {text}
              </Button>
            </SelectedListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsibleSection;
