import React, { useState } from "react";
import { Button, Modal, Box, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get, ref, serverTimestamp, update } from "firebase/database";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import { createTableInDb, db } from "../../../../firebase.config";
import { createEmptyTable } from "../../../../redux/tablesSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalTable = ({ open, setOpen }) => {
  const user = useSelector((state) => state.user);
  const [groupCode, setGroupCode] = useState(
    user.profile.role === "instructor" && user.profile.groupCode
  );
  const [formData, setFormData] = useState({
    rows: "",
    columns: "",
    title: "",
    description: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user);

  const AddNewTable = async () => {
    let tableId;
    let lastTableNum;
    if (formData.columns <= 0 || formData.rows <= 0) {
      toast.error("number of row and column must be set properly");
      return;
    }
    const updateRef = ref(db, `/managementInfo/TableInformation`);
    const getRef = ref(db, `/managementInfo/TableInformation/tableIdCounter`);

    try {
      let snap = await get(getRef);
      if (!snap.exists()) {
        await update(updateRef, { tableIdCounter: 1 });
        tableId = "table_1";
      } else {
        lastTableNum = await snap.val();
        lastTableNum += 1;
        await update(updateRef, { tableIdCounter: lastTableNum });
        tableId = "table_" + lastTableNum;
      }
      if (tableId) {
        const arrayOfRanStr = Array.from({ length: formData.columns }, () => {
          const uuid = uuidv4();
          return uuid.replace(/-/g, "").substring(0, 10);
        });

        const headerArr = Array.from(
          { length: formData.columns },
          (_, index) => {
            return {
              accessorKey: arrayOfRanStr[index],
              header: "header",
              enableSorting: false,
            };
          }
        );
        headerArr.unshift({
          accessorKey: "id",
          header: "ID",
          enableEditing: false,
        });
        const dataArr = Array.from({ length: formData.rows }, (_, index) => {
          const obj = arrayOfRanStr.reduce((acc, curr) => {
            acc[curr] = "data" + index;
            return acc;
          }, {});
          obj.id = uuidv4().replace(/-/g, "").substring(0, 10);
          return obj;
        });

        const newTableInitialState = {
          info: {
            tableId,
            // visibility: "Public",
            title: formData.title,
            explain: formData.description,
            createAt: serverTimestamp(),
            editAt: "",
            tableCreator: currentUser.profile.email,
            status: "draft",
            direction: "ltr",
            creatorId: user.profile.uid,
            groupCode,
            // pdfTitle: "",
          },
          headerData: headerArr,
          data: dataArr,
        };
        try {
          await createTableInDb({ table: newTableInitialState });
          dispatch(createEmptyTable({ id: tableId, ...newTableInitialState }));
          handleClose();
          navigate(`/${user.profile.role}panel/tables/addnewtable/${tableId}`);
        } catch (error) {
          console.log(error);
          toast.error(error.message);
        }
      }
    } catch (error) {
      toast.error(error.message, "problem assign table id");
    }
  };

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Modal
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Enter Details
          </Typography>
          {user.profile.role === "admin" && (
            <GroupCodeSection
              groupCode={groupCode}
              setGroupCode={setGroupCode}
            />
          )}
          <div className="flex gap-x-8">
            <TextField
              size="small"
              label="Number of Rows"
              name="rows"
              type="number"
              value={formData.rows}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              size="small"
              label="Number of Columns"
              name="columns"
              type="number"
              value={formData.columns}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </div>
          <TextField
            size="small"
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={8}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={AddNewTable}>
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalTable;
