// import React, { useState } from "react";
// import EditIcon from "@mui/icons-material/Edit";
// import homeImage from "../images/home-image.png";
// import { IconButton, styled } from "@mui/material";
// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

// const EditableImage = () => {
//   const [image, setImage] = useState(homeImage);
//   const [editShow, setEditShow] = useState(true);

//   const onInputFile = (e) => {
//     if (!e.target.files.length) return;
//     const blogImage = URL.createObjectURL(e.target.files[0]);
//     setImage(blogImage);
//     setEditShow(false);
//   };

//   return (
//     <div className="w-full h-full">
//       <div className="absolute top-0 right-0">
//         {editShow ? (
//           <IconButton
//             // onClick={handleEditClick}
//             color="primary"
//           >
//             Edit
//             <EditIcon />
//             <VisuallyHiddenInput
//               type="file"
//               onChange={onInputFile}
//               accept={"image/*"}
//               multiple={false}
//             />
//           </IconButton>
//         ) : (
//           <div>
//             <button>approve</button>
//             <button>revert</button>
//           </div>
//         )}
//       </div>
//       <img src={image} alt="imagehome" />
//     </div>
//   );
// };

// export default EditableImage;

import React, { useEffect, useState } from "react";
import { Box, IconButton, Button, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { sendHomeImage } from "../firebase.config";
import { getPublicInfoById } from "../redux/publicInfoSlice";

const EditButton = styled(Button)({
  position: "absolute",
  top: 8,
  right: 8,
  color: "white !important",
  backgroundColor: "#002868 !important",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
});

export default function EditableImage({ path, id }) {
  const user = useSelector((state) => state.user);
  const mainImage = useSelector((state) => getPublicInfoById(state, id));

  // State to hold the original and temporary image
  const [image, setImage] = useState(""); // Initial image URL
  const [tempImage, setTempImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [inputKey] = useState(false);

  const handleEditClick = () => {
    document.getElementById("imageInput").click(); // Trigger file input
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFile(file);
      setIsEditing(true);
      const imageUrl = URL.createObjectURL(file);
      setTempImage(imageUrl);
    }
  };

  const handleApply = async () => {
    console.log(imgFile, path);
    await sendHomeImage(imgFile, path);
    setImage(tempImage);
    setTempImage(null);
    setIsEditing(false);
  };

  const handleRevert = () => {
    setImgFile(null);
    setTempImage(null);
    setIsEditing(false);
  };

  useEffect(() => {
    if (!mainImage?.url) return;
    setImage(mainImage?.url);
  }, [mainImage?.url]);

  return (
    <div className="w-full h-full">
      <img src={tempImage || image} alt="Editable" className="contain" />
      {user?.profile?.role === "admin" && (
        <div>
          {!isEditing && (
            <EditButton onClick={handleEditClick}>
              Edit
              <EditIcon />
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
                key={inputKey}
              />
            </EditButton>
          )}
          {isEditing && tempImage && (
            <EditButton>
              <Button variant="contained" color="primary" onClick={handleApply}>
                Apply
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleRevert}
              >
                Revert
              </Button>
            </EditButton>
          )}
        </div>
      )}
    </div>
  );
}
