import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import cyrusUniLogo from "../../images/logo.webp";
import CloseIcon from "@mui/icons-material/Close";
const HeaderGuide = () => {
  const aboutRef = useRef();
  const navigate = useNavigate();
  const resourcesRef = useRef();
  //   const resourcesRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <div className="myc-headerGuide shadow-lg bg-white">
      <img
        onClick={() => navigate("/")}
        src={cyrusUniLogo}
        alt="logo"
        className="h-full contain ms-5"
      />
      <div className="hidden md:flex space-x-4 justify-center m-auto">
        <button onClick={() => navigate("/")}>HOME</button>
        <div className="flex flex-col relative myc-headerGuideExBtn">
          <button>RESOURCES</button>
          <div
            ref={resourcesRef}
            className="flex flex-col items-start absolute w-48 bg-white"
          >
            <div>
              <button onClick={() => navigate("/resources/pubmed")}>
                Pubmed
              </button>
            </div>
            <div>
              <button onClick={() => navigate("/resources/library")}>
                Library
              </button>
            </div>
            {/* <div>
                            <button onClick={() => navigate("/resources/library")}>Library</button>
                        </div> */}
            <div>
              <button onClick={() => navigate("/resources/shahname")}>
                Shahname
              </button>
            </div>
            <div>
              <button onClick={() => navigate("/resources/parsibandictionary")}>
                Parsiban Dictionary
              </button>
            </div>
            {/* <div>
              <button onClick={() => navigate("/resources/viseoreport")}>
                VISEO Report
              </button>
            </div> */}
          </div>
        </div>
        {/* <Link>
          <button>COURSES</button>
        </Link> */}
        {/* <Link>
          <button>CONFERENCES</button>
        </Link> */}
        <Link to={"/information/contact"}>
          <button>CONTACT</button>
        </Link>

        <div className="flex flex-col relative myc-headerGuideExBtn">
          <button>ABOUT</button>
          <div
            ref={aboutRef}
            className="flex flex-col items-start absolute w-48 bg-white"
          >
            <div>
              <button onClick={() => navigate("/information/about")}>
                About
              </button>
            </div>
            <div>
              <button onClick={() => navigate("/information/privacyandpolicy")}>
                Privacy And Policy
              </button>
            </div>
            {/* <div>
                            <button onClick={() => navigate("/resources/library")}>Library</button>
                        </div> */}

            {/* <div>
              <button onClick={() => navigate("/information/termsofservice")}>
                Terms Of Service
              </button>
            </div> */}
            {/* <div>
              <button onClick={() => navigate("/resources/viseoreport")}>
                VISEO Report
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              width: "250px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/");
              handleMenuClose();
            }}
          >
            HOME
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate("/resources/pubmed");
              handleMenuClose();
            }}
          >
            Pubmed
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/resources/shahname");
              handleMenuClose();
            }}
          >
            Shahname
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/resources/library");
              handleMenuClose();
            }}
          >
            Library
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/resources/parsibandictionary");
              handleMenuClose();
            }}
          >
            Parsiban Dictionary
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/resources/viseoreport");
              handleMenuClose();
            }}
          >
            VISEO Report
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              navigate("/courses");
              handleMenuClose();
            }}
          >
            COURSES
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate("/conferences");
              handleMenuClose();
            }}
          >
            CONFERENCES
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/information/contact");
              handleMenuClose();
            }}
          >
            CONTACT
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/information/about");
              handleMenuClose();
            }}
          >
            ABOUT
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <CloseIcon />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default HeaderGuide;
// import React, { useState, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Button,
//   Menu,
//   MenuItem,
//   Typography,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";

// const HeaderGuide = () => {
//   const navigate = useNavigate();
//   const resourcesRef = useRef();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [menuOpen, setMenuOpen] = useState(false);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//     setMenuOpen(true);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setMenuOpen(false);
//   };

//   return (
//     <AppBar position="relative">
//       <Toolbar className="flex justify-center">
//         <div className="hidden md:flex space-x-4">
//           <Button color="inherit" onClick={() => navigate("/")}>
//             HOME
//           </Button>
//           <div className="relative">
//             <Button
//               color="inherit"
//               onClick={() => navigate("/resources/library")}
//             >
//               RESOURCES
//             </Button>
//             <div
//               ref={resourcesRef}
//               className="absolute w-48 bg-white shadow-lg mt-2 hidden md:flex flex-col"
//             >
//               <Button onClick={() => navigate("/resources/pubmed")}>
//                 Pubmed
//               </Button>
//               <Button onClick={() => navigate("/resources/shahname")}>
//                 Shahname
//               </Button>
//               <Button onClick={() => navigate("/resources/parsibandictionary")}>
//                 Parsiban Dictionary
//               </Button>
//               <Button onClick={() => navigate("/resources/viseoreport")}>
//                 VISEO Report
//               </Button>
//             </div>
//           </div>
//           <Button color="inherit" component={Link} to="/courses">
//             COURSES
//           </Button>
//           <Button color="inherit" component={Link} to="/conferences">
//             CONFERENCES
//           </Button>
//           <Button color="inherit" component={Link} to="/information/contact">
//             CONTACT
//           </Button>
//           <Button color="inherit" component={Link} to="/information/about">
//             ABOUT
//           </Button>
//         </div>

//       </Toolbar>
//     </AppBar>
//   );
// };

// export default HeaderGuide;
