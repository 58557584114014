import React, { useEffect, useState } from "react";
// import {
//   assignLessonToAssignment,
//   removeLessonFromAssignment,
// } from "../../../firebase.config";
import { toast } from "react-toastify";

const EachAssignmentAssign = ({
  assignment,
  changeList,
  assignedAssignments,
}) => {
  const [assign, setAssign] = useState(false);

  useEffect(() => {
    const ifChecked = Object.keys(assignedAssignments).some(
      (al) => al === assignment.info.assignmentId
    );
    setAssign(ifChecked);
    if (ifChecked) {
      changeList({
        assign: true,
        assignmentId: assignment.info.assignmentId,
      });
    }
  }, []);
  // const [assignedAnother, setAssignedAnother] = useState("");

  const changeAssignAssignment = async (e) => {
    setAssign((prev) => {
      changeList({
        assign: !prev,
        assignmentId: assignment.info.assignmentId,
      });
      return !prev;
    });
  };

  return (
    <p className="flex justify-between">
      <p className="me-5">{assignment.info.assignmentId}</p>
      <p className="text-start">{assignment.info.title}</p>
      <label htmlFor={assignment.info.assignmentId}>
        {assign ? "unassign" : "assign"}
      </label>
      <input
        id={assignment.info.assignmentId}
        type="checkbox"
        checked={assign}
        onChange={changeAssignAssignment}
        style={{ display: "none" }}
      />
    </p>
  );
};

export default EachAssignmentAssign;
