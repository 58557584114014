import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const publicInfoAdapter = createEntityAdapter();

const initialState = publicInfoAdapter.getInitialState();

const publicInfoSlice = createSlice({
  name: "publicInfo",
  initialState,
  reducers: {
    addPublicInfo: publicInfoAdapter.setAll,
  },
});

export const { addPublicInfo } = publicInfoSlice.actions;
// export const getAllPublicInfo = (state) =>
//   publicInfoAdapter
//     ?.getSelectors()
//     ?.selectAll(state.publicInfo)
//     ?.sort((a, b) => b.id - a.id);

export const getPublicInfoById = (state, id) =>
  publicInfoAdapter.getSelectors().selectById(state.publicInfo, id);

// //   export const getNotificationEntities = (state) =>
// //     publicInfoAdapter.getSelectors().selectEntities(state.publicInfo);

// //   export const getPublicInfoByIds = createSelector(
// //     [getNotificationEntities, (state, ids) => ids],
// //     (entities, ids) => ids.map((id) => entities[id])
// //   );
export default publicInfoSlice.reducer;
