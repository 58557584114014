import React from "react";
import MainHeader from "./MainHeader";
import { Outlet } from "react-router-dom";
import Footer from "../panels/layout/Footer";
import Header from "../layout/Header";

const GeneralLayout = () => {
  return (
    <div>
      {/* <MainHeader /> */}
      <Header />
      <div className="bg-white">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default GeneralLayout;
