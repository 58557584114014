// import cyrusUniLogo from "../../../images/logoTextWhite.png";
// import React, { useEffect, useState } from "react";
// import { authWithGoogle, signInWithEmail } from "../../../firebase.config";
// import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
// import { toast } from "react-toastify";
// import {
//   TextField,
//   Container,
//   Box,
//   Typography,
//   IconButton,
//   InputAdornment,
//   Paper,
//   Button,
// } from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { makeStyles } from "@mui/styles";
// import { useSelector } from "react-redux";
// // import GoogleIcon from "./GoogleIcon";
// import GoogleIcon from "@mui/icons-material/Google";
// const useStyles = makeStyles({
//   root: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     margin: "auto",
//     gap: "40px",
//     // marginTop: "10px",
//   },
//   logo: {
//     width: "100px",
//     height: "100px",
//     marginBottom: "16px",
//   },
//   form: {
//     width: "100%",
//     marginTop: "8px",
//   },
//   submit: {
//     margin: "24px 0 40px",
//   },
//   paper: {
//     padding: "32px",
//     display: "flex",
//     alignItems: "center",
//     position: "relative",
//     height: "100vh",
//     backgroundColor: "#002868 !important",
//   },
// });

// const Login = () => {
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.user);

//   const classes = useStyles();
//   const [email, setEmail] = useState("");
//   const [pass, setPass] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const togglePassword = () => {
//     setShowPassword((prev) => !prev);
//   };

//   useEffect(() => {
//     if (user?.profile?.role === "student") {
//       return navigate("/studentpanel/profile");
//     } else if (user?.profile?.role === "instructor") {
//       return navigate("/adminpanel");
//     } else if (user?.profile?.role === "admin") {
//       navigate("/adminpanel");
//       return;
//     }
//   }, [navigate, user?.profile?.role]);

//   const login = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const profile = await signInWithEmail(email, pass);

//       if (profile.role === "student") {
//         navigate("/studentpanel/profile");
//       } else if (profile.role === "admin" || profile.role === "instructor") {
//         navigate("/adminpanel");
//       } else {
//         toast.error("ask university to specify your role");
//       }
//       toast.success(
//         `Welcome Back ${profile.name.toUpperCase()} ${profile.family.toUpperCase()}`
//       );
//     } catch (error) {
//       console.log(error);
//       toast.error(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const googleSignIn = async () => {
//     try {
//       await authWithGoogle();
//     } catch (error) {
//       console.log(error);
//       toast.error(error.message);
//     }
//   };

//   return (
//     <div className="bg-cu-blue w-full h-full">
//       <Container component="main" maxWidth="xl">
//         <Paper className={classes.paper} elevation={0}>
//           <div className="w-7/12 flex flex-col items-start gap-y-12">
//             <Typography
//               variant="h2"
//               component="h1"
//               className="text-white ps-12"
//             >
//               Student Portal
//             </Typography>
//             <img
//               src={cyrusUniLogo}
//               alt="Logo"
//               className="contain mb-10 mt-4 w-9/12"
//             />
//           </div>
//           <Box
//             className={`${classes.root} w-3/12 bg-white p-10 pb-32 rounded-lg`}
//           >
//             <Typography
//               variant="h5"
//               component="h1"
//               alignSelf="start"
//               fontWeight="bold"
//             >
//               Log in
//             </Typography>
//             <form className={classes.form} onSubmit={login}>
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type={showPassword ? "text" : "password"}
//                 id="password"
//                 value={pass}
//                 onChange={(e) => setPass(e.target.value)}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton onClick={togglePassword}>
//                         <FontAwesomeIcon icon={faEye} />
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <LoadingButton
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 onClick={signInWithEmail}
//                 sx={{
//                   backgroundColor: "#002868",
//                   color: "white",
//                   "&:hover": {
//                     backgroundColor: "#002868",
//                   },
//                   width: "100%",
//                   marginTop: "25px",
//                 }}
//                 color="primary"
//                 loading={loading}
//                 className={classes.submit}
//               >
//                 Log in now
//               </LoadingButton>
//             </form>
//             <div className="w-full">
//               <Typography
//                 alignSelf={"start"}
//                 sx={{ marginTop: "50px", marginBottom: "10px" }}
//               >
//                 Or continue with
//               </Typography>
//               <Button
//                 variant="contained"
//                 onClick={googleSignIn}
//                 sx={{
//                   backgroundColor: "#bf0a30",
//                   color: "white",
//                   "&:hover": {
//                     backgroundColor: "#bf0a30",
//                   },
//                   width: "100%",
//                 }}
//               >
//                 <GoogleIcon className="me-2" fontSize="small" /> SIGN IN WITH
//                 GOOGLE
//               </Button>
//             </div>
//           </Box>
//         </Paper>
//       </Container>
//     </div>
//   );
// };

// export default Login;
import cyrusUniLogo from "../../../images/logoTextWhite.png";
import cyrusUniLogoBackWhite from "../../../images/logo.webp";
import React, { useEffect, useState } from "react";
import { authWithGoogle, signInWithEmail } from "../../../firebase.config";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  TextField,
  Container,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Button,
} from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import GoogleIcon from "@mui/icons-material/Google";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/userSlice";
import LoadingButton from "../../../shared/comp/LoadingButton";
import { gapi } from "gapi-script";

// Styled components using @mui/system
const Root = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "auto",
  gap: "40px",
});

const Logo = styled("img")({
  width: "100px",
  height: "100px",
  marginBottom: "16px",
});

const FormContainer = styled(Box)({
  width: "100%",
  marginTop: "8px",
});

const PaperStyled = styled(Paper)({
  padding: "32px",
  display: "flex",
  alignItems: "center",
  position: "relative",
  height: "100vh",
  backgroundColor: "#002868",
});

const Login = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (!user.profile.role) return;
    navigate(
      `/${user.profile.role}panel/${
        user.profile.role === "admin" ? "" : "profile"
      }`
    );
  }, [navigate, user?.profile?.role]);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const profile = await signInWithEmail(email, pass);
      navigate(
        `/${profile.role}panel/${profile.role === "admin" ? "" : "profile"}`
      );
      // if (user?.profile?.role === "student") {
      //   return navigate("/studentpanel/profile");
      // } else if (user?.profile?.role === "instructor") {
      //   return navigate("/instructorpanel/profile");
      // } else if (user?.profile?.role === "admin") {
      //   navigate("/adminpanel");
      // }
      toast.success(
        `Welcome Back ${profile.name.toUpperCase()} ${profile.family.toUpperCase()}`
      );
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const googleSignIn = async () => {
    try {
      await authWithGoogle();
    } catch (error) {
      dispatch(logoutUser());
      console.log(error);
      toast.error(error.message);
    }
  };

  // const clientId =
  //   "777209034475-dl0vqqsa5lb2vmt4vo37ecua5kfnfcam.apps.googleusercontent.com";
  // const scope = "https://www.googleapis.com/auth/chat.spaces";

  useEffect(() => {
    getData();
    // function initClient() {
    //   gapi.load("client:auth2", () => {
    //     gapi.client
    //       .init({
    //         clientId: clientId,
    //         scope: scope,
    //       })
    //       .then(() => {
    //         gapi.auth2
    //           .getAuthInstance()
    //           .signIn()
    //           .then(() => {
    //             const accessToken = gapi.auth2
    //               .getAuthInstance()
    //               .currentUser.get()
    //               .getAuthResponse().access_token;
    //             console.log(accessToken);
    //             getData(accessToken);
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       });
    //   });
    // }

    // initClient();
  }, []);

  const getData = () => {
    fetch("https://chat.googleapis.com/v1/spaces", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="bg-cu-blue w-full h-full">
      <Container component="main" maxWidth="xl">
        <PaperStyled elevation={0}>
          <div className="w-7/12 hidden xl:flex flex-col items-start gap-y-10">
            <Typography
              variant="h2"
              component="h1"
              className="text-white ps-12"
            >
              LMS Portal
            </Typography>
            <img
              src={cyrusUniLogo}
              alt="Logo"
              className="contain mb-10 mt-4 w-9/12"
            />
          </div>
          <Root className="md:w-1/2 xl:w-3/12 bg-white p-10 pb-20 rounded-lg">
            <Typography
              variant="h5"
              component="h1"
              alignSelf="start"
              fontWeight="bold"
              className="xl:block hidden"
            >
              Log in
            </Typography>
            <div className=" xl:hidden flex flex-col items-center gap-y-1 w-full">
              <Typography className="self-start" variant="h4" component="h4">
                LMS Portal
              </Typography>
              <img src={cyrusUniLogoBackWhite} alt="Logo" className="contain" />
            </div>
            <form onSubmit={login}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePassword}>
                        <FontAwesomeIcon icon={faEye} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                loading={loading}
                disabled={!email || pass.length < 8 ? true : false}
                name={"Log In Now"}
                onClick={signInWithEmail}
              />
            </form>
            <Link className="text-cu-blue self-end" to={"/changepassword"}>
              Forget Password ?
            </Link>
            <div className="w-full">
              <Typography
                alignSelf={"start"}
                sx={{ marginTop: "50px", marginBottom: "10px" }}
              >
                Or continue with
              </Typography>
              <Button
                variant="contained"
                onClick={googleSignIn}
                sx={{
                  backgroundColor: "#bf0a30",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#bf0a30",
                  },
                  width: "100%",
                }}
              >
                <GoogleIcon className="me-2" fontSize="small" /> SIGN IN WITH
                GOOGLE
              </Button>
            </div>
          </Root>
        </PaperStyled>
      </Container>
    </div>
  );
};

export default Login;
