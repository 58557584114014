import React from "react";
import cyrusUniLogo from "../../images/logo.webp";
import { useNavigate } from "react-router-dom";
import {
  faBagShopping,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { faUser, faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MiddleHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="myc-middleHeader flex items-center justify-start ms-16">
      {/* <div className="md:flex hidden items-center ml-6 w-auto h-28 ">
        <img
          onClick={() => navigate("/")}
          className=" contain"
          src={cyrusUniLogo}
          alt="not available"
        />
      </div> */}

      {/* <div className="myc-searchBox">
        <input
          className="rounded-s-full border border-gray-500 pl-5"
          type="text"
          placeholder="Search Courses..."
        />
        <select
          className="md:inline-block hidden border-y border-gray-500"
          name=""
          id=""
        >
          <option value="">History</option>
          <option value="">Languages and Linguistics</option>
          <option value="">Mathematics and Statistics</option>
          <option value="">Physics</option>
          <option value="">popular</option>
          <option value="">shahname</option>
          <option value="">Software Development</option>
        </select>
        <button className="rounded-e-full border border-gray-500">
          <FontAwesomeIcon size="lg" icon={faMagnifyingGlass} />
        </button>
      </div> */}

      {/* <div className="flex text-lg hidden md:flex">
        <div className="myc-becomeTeacher rounded-full text-center py-3 me-3">
          Become A Teacher
        </div>
        <button className="me-2">
          <FontAwesomeIcon icon={faUser} />
        </button>
        <button className="me-2">
          <FontAwesomeIcon icon={faHeart} />
        </button>
        <button className="me-3">
          {" "}
          <FontAwesomeIcon icon={faBagShopping} />
        </button>
      </div> */}
    </div>
  );
};

export default MiddleHeader;
