import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { sendGrade } from "../../../firebase.config";
import { useSelector } from "react-redux";
import { getUsersByIds } from "../../../redux/usersSlice";

import {
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
} from "@mui/material";
import {} from "@mui/material";
import CustomConfirm from "../sharedComp/CustomConfirm";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import TimeShow from "../../../shared/utils/TimeShow";

const EachGradeToStudentRow = ({ course, selectedUser }) => {
  const user = useSelector((state) => state.user);
  const [currentGrade, setCurrentGrade] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [change, setChange] = useState(false);

  const [grade, setGrade] = useState(60);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const sendGradeToDb = async () => {
    try {
      const message = await sendGrade({
        courseId: course.info.courseId,
        groupCode: course.info.groupCode,
        uid: selectedUser.profile.uid,
        grade,
        gradeGiverId: user.profile.uid,
      });
      toast.success(message);
      setCurrentGrade(grade);
      setCurrentStatus(grade >= 60 ? "passed" : "failed");
    } catch (error) {
      toast.error(error.message);
    }
    // Handle the confirmation action here
    setChange(false);
    setOpen(false);
  };

  const handleConfirm = async () => {
    setOpen(true);
  };

  useEffect(() => {
    setCurrentGrade(
      (selectedUser?.reportCards &&
        selectedUser?.reportCards[course?.info?.courseId] &&
        selectedUser?.reportCards[course?.info?.courseId]?.grade) ||
        "---"
    );

    setCurrentStatus(
      (selectedUser?.reportCards &&
        selectedUser?.reportCards[course?.info?.courseId] &&
        selectedUser?.reportCards[course?.info?.courseId]?.status) ||
        "Under Process"
    );
  }, [course?.info?.courseId, selectedUser?.reportCards]);

  const instructorsId = course?.assignedInstructor
    ? Object.keys(course?.assignedInstructor)
    : [];
  const instructors = useSelector((state) =>
    getUsersByIds(state, instructorsId)
  );

  return (
    <TableRow key={course?.info.courseId} className="h-28 capitalize">
      <TableCell>{course?.info.title}</TableCell>
      <TableCell>
        {
          <Box>
            {instructors?.map((ins) => (
              <Typography className="capitalize">
                {ins?.profile.name} {ins?.profile.family}
              </Typography>
            ))}
          </Box>
        }
      </TableCell>
      <TableCell>
        <TimeShow date={new Date(course?.info.createAt)} />
      </TableCell>
      <TableCell>
        {course?.general.duration} {course?.general?.durationUnit}
      </TableCell>
      <TableCell>{course?.general?.unit}</TableCell>
      <TableCell>
        {currentStatus === "passed" ? (
          <div>
            <CheckBoxIcon color="success" />
            {currentStatus}
          </div>
        ) : currentStatus === "failed" ? (
          <div>
            <CloseIcon sx={{ color: "red" }} />
            {currentStatus}
          </div>
        ) : (
          <div>
            <HourglassTopIcon />
            {currentStatus}
          </div>
        )}
      </TableCell>
      {user.profile.role === "admin" ||
      ((user.profile.role === "instructor" ||
        user.profile.role === "leadInstructor") &&
        user?.assignedCourses &&
        Object.keys(user?.assignedCourses)?.includes(
          course?.info?.courseId
        )) ? (
        <TableCell className="w-60">
          {!change ? (
            <div className="flex items-center gap-x-4">
              <p>{currentGrade}</p>
              <Button onClick={() => setChange(true)}>Edit</Button>
            </div>
          ) : (
            <div className="flex items-center gap-x-4">
              <TextField
                type="number"
                id="outlined-basic"
                label="Grade"
                variant="outlined"
                size="small"
                className="w-20"
                onChange={(e) => setGrade(e.target.value)}
                value={grade}
              />
              <div className="flex flex-col">
                <Button onClick={handleConfirm}>Ok</Button>
                <Button onClick={() => setChange(false)}>Cancle</Button>
              </div>
            </div>
          )}
        </TableCell>
      ) : (
        <TableCell className="w-60">
          {currentGrade && currentGrade >= 90
            ? "A"
            : currentGrade >= 80
            ? "B+"
            : currentGrade >= 70
            ? "B"
            : currentGrade >= 60
            ? "C"
            : currentGrade < 60
            ? "D"
            : ""}
        </TableCell>
      )}
      <CustomConfirm
        open={open}
        onClose={handleClose}
        onConfirm={sendGradeToDb}
      />
    </TableRow>
  );
};

export default EachGradeToStudentRow;
