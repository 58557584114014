import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  assignCourseToSt,
  removeAssignedCourseToSt,
} from "../../../firebase.config";
import { Box, Typography, Switch, Card, FormControlLabel } from "@mui/material";
import LoadingOverlay from "../../../shared/comp/LoadingOverlay";

const EachCourseAssign = ({ course, selectedUser }) => {
  // Helper to determine the initial assigned state
  const determineAssignedState = () => {
    const userId = selectedUser?.profile?.uid;
    if (!userId) return false;
    return (
      (course?.assignedStudents && course.assignedStudents[userId]) ||
      (course?.assignedInstructor && course.assignedInstructor[userId])
    );
  };

  const [assigned, setAssigned] = useState(determineAssignedState());
  const [loading, setLoading] = useState(false);

  // Update assigned state when `selectedUser` or `course` changes
  useEffect(() => {
    setAssigned(determineAssignedState());
  }, [selectedUser, course]);

  const handleToggle = async () => {
    if (!selectedUser?.profile?.uid || !course?.info) {
      toast.error("Invalid user or course information");
      return;
    }

    setLoading(true); // Show loading overlay
    try {
      if (assigned) {
        // Unassign the course
        await removeAssignedCourseToSt({ user: selectedUser, course });
        toast.success("Course unassigned successfully!");
      } else {
        // Assign the course
        await assignCourseToSt({ user: selectedUser, course });
        toast.success("Course assigned successfully!");
      }
      setAssigned(!assigned); // Toggle the assigned state
    } catch (error) {
      console.error("Error updating course assignment:", error);
      toast.error(error.message || "Failed to update course assignment.");
    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  return (
    <>
      <LoadingOverlay
        loading={loading}
        message="Updating course assignment..."
      />
      <Card
        sx={{
          padding: 2,
          margin: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: 4,
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {course?.info?.courseId}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {course?.info?.title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControlLabel
            control={
              <Switch
                checked={assigned}
                onChange={handleToggle}
                color="primary"
              />
            }
            label={assigned ? "Unassign" : "Assign"}
            labelPlacement="start"
            sx={{
              "& .MuiTypography-root": {
                fontWeight: "bold",
                fontSize: "0.9rem",
                color: assigned ? "error.main" : "primary.main",
              },
            }}
          />
        </Box>
      </Card>
    </>
  );
};

export default EachCourseAssign;
