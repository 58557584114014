import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import allPdfReducer from "./allPdfsSlice";
import allVideosReducer from "./allVideosSlice";
import allImagesReducer from "./allImagesSlice";
import coursesReducer from "./coursesSlice";
import usersReducer from "./usersSlice";
import lessonsReducer from "./lessonsSlice";
import tablesReducer from "./tablesSlice";
import assignmentsReducer from "./assignmentsSlice";
import categoriesReducer from "./categoriesSlice";
import groupsReducer from "./groupsSlice";
import notificationsReducer from "./notificationsSlice";
import publicInfoReducer from "./publicInfoSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    allPdfs: allPdfReducer,
    allVideos: allVideosReducer,
    allImages: allImagesReducer,
    users: usersReducer,
    courses: coursesReducer,
    lessons: lessonsReducer,
    tables: tablesReducer,
    assignments: assignmentsReducer,
    categories: categoriesReducer,
    notifications: notificationsReducer,
    groups: groupsReducer,
    publicInfo: publicInfoReducer,
  },
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //       serializableCheck: false,
  //     }),
});

export default store;
