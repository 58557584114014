import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SearchItems = ({ searchFunc }) => {
  return (
    <div className="flex border items-center justify-center rounded-full h-8 w-60">
      <input
        className="ps-2"
        type="text"
        placeholder="Search"
        // ref={searchRef}
        onChange={searchFunc}
      />
      <button
        className="text-slate-500 me-2"
        // onClick={() => setSearch(searchRef.current.value)}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </div>
  );
};

export default SearchItems;
