import React, { useState } from "react";
import { Container, Typography, Paper, Grid, Button, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { getCourseById } from "../../redux/coursesSlice";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import ViewAddedResources from "../shared/sharedComp/showResources/ViewAddedResources";
import { getUsersByIds } from "../../redux/usersSlice";
import ViewFile from "../../layout/ViewFile";
import ShowCourseContent from "./ShowCourseContent";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const CoursePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeComp = (comp) => {
    setCurrentComp(comp);
  };
  const [currentComp, setCurrentComp] = useState(
    <ShowCourseContent changeComp={changeComp} />
  );
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };
  const { courseId } = useParams();
  const course = useSelector((state) => getCourseById(state, courseId));
  const instructorIds = course?.assignedInstructor
    ? Object.keys(course?.assignedInstructor)
    : [];

  const instructors =
    useSelector((state) => getUsersByIds(state, instructorIds)) || [];

  const openCourse = () => {
    setIsModalOpen(true);
    setCurrentComp(<ShowCourseContent changeComp={changeComp} />);
  };
  return (
    <div className=" w-full">
      {/* <div className="relative top-52"> */}

      <Container maxWidth="md">
        <Typography variant="h2" gutterBottom>
          {course?.info.title}
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            margin: "30px 0px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={2} style={{ position: "relative" }}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Title:</strong> {course?.info.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Instructor:</strong>{" "}
                {instructors.map((ins, index) => (
                  <span key={index}>
                    {ins?.profile.name} {ins?.profile.family} ,
                  </span>
                ))}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Categories:</strong> {course?.categoriesName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Duration:</strong> {course?.general.duration}{" "}
                {course?.general.durationUnit}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Prerequisite:</strong> {course?.general?.prerequisite}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Level:</strong> {course?.general?.level}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Unit:</strong> {course?.general?.unit}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Unit:</strong>{" "}
                {(course?.reportCards &&
                  course?.reportCards[course?.info.courseId] &&
                  course?.reportCards[course?.info.courseId]?.status) ||
                  "you didn't pass this course yet"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontFamily: "Arial, sans-serif", color: "#333" }}
              >
                <strong>Grade:</strong>{" "}
                {(course?.reportCards &&
                  course?.reportCards[course?.info.courseId] &&
                  course?.reportCards[course?.info.courseId]?.grade) ||
                  "---"}
              </Typography>
            </Grid>

            <div className="absolute top-3 right-3">
              <img
                src={course?.files.courseImage.url}
                alt="Course"
                style={{
                  width: "200px",
                  height: "auto",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <Button onClick={openCourse}>
                continue to lessons <KeyboardArrowRightIcon />
              </Button>
              {/* </div> */}

              <ViewFile
                isOpen={isModalOpen}
                onClose={closeModal}
                title={course?.info?.title || ""}
              >
                {currentComp}
              </ViewFile>
            </div>
          </Grid>
        </Paper>
        <div className="file-child-center">
          <ReactQuill
            value={course?.info?.explain}
            readOnly={true}
            theme="bubble"
          />
          <ViewAddedResources content={course} />
        </div>
      </Container>
    </div>
  );
};

export default CoursePage;
