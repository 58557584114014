import { useEffect, useState } from "react";
import EachPdf from "./EachPdf";
import { checkIfStoragePathExists } from "../../../../firebase.config";
import { Button, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const InputMultiplePdf = ({ stPath, realPath }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [list, setList] = useState([]);

  // useEffect(() => {
  //   getList(list);
  // }, [list]);

  const onInputFile = async (e) => {
    if (!e.target.files.length) return;

    const filesValue = Object.values(e.target.files);
    let aaa = [];
    let filesPromise = filesValue.map(async (file) => {
      const replacedString = file.name.replace(/[#$\/\[\].\s]/g, "_");
      const pathExist = await checkIfStoragePathExists(
        `/${stPath}/${replacedString}/pdf`
      );
      if (pathExist) {
        if (
          window.confirm(
            `File with name ${replacedString} already exists, are you sure to overwrite the file?`
          )
        ) {
          aaa.push(file);
        }
      } else {
        aaa.push(file);
      }
    });
    await Promise.all(filesPromise);

    setSelectedFiles((prev) => {
      const files = [...prev, ...aaa];
      const sortFiles = files.sort((a, b) => a.name <= b.name);
      return sortFiles;
    });
  };

  return (
    <div className="flex flex-col items-center justify-center rounded-lg p-5">
      {/* <span className="text-start w-full ps-1 pb-3 text-green-600">PDF :</span> */}
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        size="small"
        sx={{ fontWeight: "900" }}
      >
        <CloudUploadIcon sx={{ fontSize: "40px" }} className="text-cu-blue" />
        <VisuallyHiddenInput
          type="file"
          onChange={onInputFile}
          accept={".pdf"}
          multiple
        />
        <span className="ms-4 text-cu-blue">Upload Pdf</span>
      </Button>
      {/* <input
        // key={inputKey}
        type="file"
        accept={".pdf"}
        onChange={onInputFile}
        multiple
      /> */}
      <div className="grid grid-cols-4 gap-2 items-center">
        {selectedFiles?.map((file, index) => (
          <EachPdf
            key={file.name + index}
            file={file}
            stPath={stPath}
            realPath={realPath}
            // setList={setList}
          />
        ))}
      </div>
    </div>
  );
};

export default InputMultiplePdf;
