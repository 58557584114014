// import React, { useEffect } from "react";

// import VerticalInfiniteScroll from "./VerticalInfiniteScroll";
// import VerticalVideoScroll from "./VerticalVideoScroll";
// import VerticalProfessorScroll from "./VerticalProfessorScroll";
// import { useSelector } from "react-redux";
// import EditableImage from "../../shared/EditableImage";
// import { useNavigate } from "react-router-dom";

// import { Swiper, SwiperSlide } from "swiper/react";
// import homeImage1 from "../../images/home1.png";
// import homeImage2 from "../../images/home2.jpg";
// import homeImage3 from "../../images/home3.png";
// import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";
// import HomeCoursesLogos from "./HomeCoursesLogos";
// import "swiper/css";
// import "swiper/css/effect-fade";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// const images = [homeImage1, homeImage2, homeImage3];
// const Home = () => {
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.user);

//   return (
//     <div className="flex flex-col">
//       <div className="relative w-full h-44 sm:h-72 md:h-96 lg:h-80vh xl:h-90vh ">
//         {/* <div className=""> */}
//         <div className="absolute z-10 m-auto w-full h-full justify-between md:flex flex-col hidden">
//           <div className="flex flex-col w-full h-full justify-center items-center text-center text-lg">
//             <p className="">BEST ONLINE PLATFORM FROM HOME</p>
//             <p>
//               <span className="text-white">New Way</span> To Learn
//             </p>
//             <button
//               className="rounded-full bg-white p-3"
//               onClick={() => {
//                 navigate("/register");
//               }}
//             >
//               READY TO GET STARTED
//             </button>
//           </div>
//           {/* </div> */}
//         </div>
//         {/* <ImageCarousel /> */}
//         <Swiper
//           loop={true}
//           spaceBetween={30}
//           effect={"fade"}
//           autoplay={{ delay: 15000 }}
//           modules={[EffectFade, Autoplay]}
//           // allowTouchMove={false}
//           className="h-full"
//         >
//           {images.map((image, index) => (
//             <SwiperSlide key={index} className="h-full w-full">
//               <img
//                 src={image}
//                 alt={`carousel-${index}`}
//                 className="h-full w-full object-cover"
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>

//       <div className="bg-slate-100 h-full mt-5 pb-10 ">
//         <br />
//         <br />
//         <p className="text-center text-3xl mycu-titr">POPULAR ONLINE COURSES</p>
//         <br />
//         <HomeCoursesLogos />
//       </div>

//       <div
//         style={{ height: "600px" }}
//         className="flex items-center flex-col md:flex-row"
//       >
//         <div className="w-1/2 ">
//           <div className=" w-96 m-auto">
//             <p className="m-auto text-center mb-5">
//               RAISE AN ENQUIRY FOR BEST COURSES
//             </p>
//             <form
//               className="flex flex-col items-center h-96"
//               style={{ justifyContent: "space-around" }}
//             >
//               <input
//                 className="w-2/3  border p-2 rounded-md"
//                 type="text"
//                 placeholder="Name"
//               />
//               <input
//                 className="w-2/3  border p-2 rounded-md"
//                 type="text"
//                 placeholder="Subject"
//               />
//               <input
//                 className="w-2/3  border p-2 rounded-md"
//                 type="text"
//                 placeholder="Email"
//               />
//               <input
//                 className="w-2/3  border p-2 rounded-md"
//                 type="text"
//                 placeholder="Phone No."
//               />
//               <input
//                 className="w-2/3  border p-2 rounded-md"
//                 type="text"
//                 placeholder="Message"
//               />
//               <button className="mycu-backgroundBlue rounded-full p-3">
//                 SUBMIT REQUEST
//               </button>
//             </form>
//           </div>
//         </div>
//         <div className="w-1/2">
//           <p className="text-center mb-5 ">OUR TIMING</p>
//           <div className="w-72 h-96 border m-auto flex flex-col justify-around bg-slate-600 text-white p-3 rounded-md">
//             <div className="flex justify-around">
//               <span>Monday</span>
//               <span>9:00 to 19:00</span>
//             </div>
//             <div className="flex justify-around">
//               <span>Tuesday</span>
//               <span>9:00 to 19:00</span>
//             </div>
//             <div className="flex justify-around">
//               <span>Wednesday</span>
//               <span>9:00 to 19:00</span>
//             </div>
//             <div className="flex justify-around">
//               <span>Thursday</span>
//               <span>9:00 to 19:00</span>
//             </div>
//             <div className="flex justify-around">
//               <span>Friday</span>
//               <span>9:00 to 19:00</span>
//             </div>
//             <div className="flex justify-around">
//               <span>Saturday</span>
//               <span>9:00 to 19:00</span>
//             </div>
//             <div className="flex justify-around">
//               <span>Sunday</span>
//               <span>CLOSED</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import VerticalInfiniteScroll from "./VerticalInfiniteScroll";
import VerticalVideoScroll from "./VerticalVideoScroll";
import VerticalProfessorScroll from "./VerticalProfessorScroll";
import EditableImage from "../../shared/EditableImage";
import HomeCoursesLogos from "./HomeCoursesLogos";
import homeImage1 from "../../images/home1.png";
import homeImage2 from "../../images/home2.jpg";
import homeImage3 from "../../images/home3.png";

const images = [homeImage1, homeImage2, homeImage3];

const Home = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <div className="flex flex-col">
      <div className="relative w-full h-44 sm:h-72 md:h-96 lg:h-80vh xl:h-90vh">
        <div className="absolute z-10 w-full h-full flex flex-col justify-center items-center text-center text-lg">
          <Typography
            variant="h4"
            className="text-white font-black mb-4 text-stroke"
            fontWeight="fontWeightBold"
          >
            BEST ONLINE PLATFORM <br /> FROM HOME
          </Typography>
          <Typography variant="h6" className="text-white mb-4">
            <span className="text-cu-red">New Way</span> To Learn
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#002868",
              borderRadius: "50px",
              padding: "10px 30px",
            }}
            onClick={() => navigate("/register")}
          >
            READY TO GET STARTED
          </Button>
        </div>
        <Swiper
          loop={true}
          spaceBetween={30}
          effect={"fade"}
          autoplay={{ delay: 15000 }}
          modules={[EffectFade, Autoplay, Pagination, Navigation]}
          className="h-full"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="h-full w-full">
              <img
                src={image}
                alt={`carousel-${index}`}
                className="h-full w-full object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Container className="bg-slate-100 h-full mt-5 pb-10">
        <Typography
          variant="h3"
          sx={{ margin: "10px" }}
          className="text-center"
        >
          POPULAR ONLINE COURSES
        </Typography>
        <HomeCoursesLogos />
      </Container>

      <Box className="flex flex-row md:flex-row items-center justify-around   my-10 w-full">
        {/* <Box className=" md:w-1/3 p-5">
          <Typography variant="h5" className="text-center mb-5">
            RAISE AN ENQUIRY FOR BEST COURSES
          </Typography>
          <form className="flex flex-col items-center space-y-4">
            <TextField label="Name" variant="outlined" fullWidth />
            <TextField label="Subject" variant="outlined" fullWidth />
            <TextField label="Email" variant="outlined" fullWidth />
            <TextField label="Phone No." variant="outlined" fullWidth />
            <TextField label="Message" variant="outlined" fullWidth />
            <Button
              variant="contained"
              color="primary"
              className="rounded-full"
            >
              SUBMIT REQUEST
            </Button>
          </form>
        </Box> */}
        <Box className="md:w-1/3 p-5">
          <Typography variant="h5" className="text-center mb-5">
            OUR TIMING
          </Typography>
          <Paper className="p-5 bg-slate-600 text-white rounded-md">
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day, index) => (
              <Box key={index} className="flex justify-between py-2">
                <span>{day}</span>
                <span>{day === "Sunday" ? "CLOSED" : "9:00 to 19:00"}</span>
              </Box>
            ))}
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
