// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Collapse from "@mui/material/Collapse";
// import IconButton from "@mui/material/IconButton";
// import SchoolIcon from "@mui/icons-material/School";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import Button from "@mui/material/Button";
// import MenuIcon from "@mui/icons-material/Menu";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme, styled } from "@mui/material/styles";
// import { ListItemButton } from "@mui/material";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import SourceIcon from "@mui/icons-material/Source";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import MovieIcon from "@mui/icons-material/Movie";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import CastForEducationIcon from "@mui/icons-material/CastForEducation";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import AssignmentIcon from "@mui/icons-material/Assignment";
// import AssessmentIcon from "@mui/icons-material/Assessment";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import LockClockIcon from "@mui/icons-material/LockClock";
// import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
// import BiotechIcon from "@mui/icons-material/Biotech";
// import TranslateIcon from "@mui/icons-material/Translate";
// import KeyboardIcon from "@mui/icons-material/Keyboard";
// import { faPenNib } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useSelector } from "react-redux";
// import TableViewIcon from "@mui/icons-material/TableView";
// import WorkspacesIcon from "@mui/icons-material/Workspaces";
// import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
// import cyrusUniLogo from "../../../images/logoTextWhite.png";
// import CategoryIcon from "@mui/icons-material/Category";
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// // const useStyles = makeStyles({
// //   selected: {
// //     backgroundColor: "lightblue", // Change this to your desired color
// //     "&:hover": {
// //       backgroundColor: "lightblue", // Change this to your desired hover color
// //     },
// //   },
// // });
// const SelectedListItemButton = styled(ListItemButton)(({ theme }) => ({
//   backgroundColor: "#002868",
//   color: "#fff",
//   "&:hover": {
//     backgroundColor: "#003a99",
//   },
//   "&.Mui-selected": {
//     color: "#bf0a30",
//     borderLeft: "8px solid #003a99",
//     // backgroundColor: "#003a99",
//     "&:hover": {
//       backgroundColor: "#004bb3",
//     },
//   },
// }));
// const ListContainer = styled(List)(({ theme }) => ({
//   height: "100vh",
//   backgroundColor: "#002868",
//   color: "#fff",
// }));
// const AdminSideBar = () => {
//   const user = useSelector((state) => state.user);
//   const [resourcesOpen, setResourcesOpen] = useState(false);
//   const [toolsOpen, setToolsOpen] = useState(false);
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const [open, setOpen] = useState(false);
//   const [selectedIndex, setSelectedIndex] = useState(null);
//   // const classes = useStyles();
//   const [toolsIndex, setToolsIndex] = useState(null);
//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setOpen(open);
//   };

//   const handleListItemClick = (index, path) => {
//     setSelectedIndex(index);
//     navigate(path);
//   };

//   const handleToolsIndex = (index, path) => {
//     setToolsIndex(index);
//     navigate(path);
//   };

//   const handleNestedClick = () => {
//     setResourcesOpen(!resourcesOpen);
//   };

//   return (
//     <div className={`${isSmallScreen ? "z-100" : "z-20"}`}>
//       {isSmallScreen && (
//         <IconButton onClick={toggleDrawer(true)}>
//           <MenuIcon />
//         </IconButton>
//       )}

//       <Drawer
//         variant={isSmallScreen ? "temporary" : "permanent"}
//         open={open}
//         onClose={toggleDrawer(false)}
//         // classes={{ paper: "pb-12" }} // Adjust the margin-top to match the height of your header
//         className="w-72"
//         sx={{ "& .MuiDrawer-paper": { border: 0 } }}
//       >
//         <div className="z-20 fixed w-72 h-32 bg-cu-blue flex justify-center items-center ">
//           <img
//             onClick={() => navigate("/")}
//             src={cyrusUniLogo}
//             alt="cyrusuni logo"
//             className="object-contain h-16 cursor-pointer justify-self-start"
//           />
//         </div>
//         <ListContainer
//           className="w-72 relative top-32 overflow-auto"
//           style={{ height: "calc(100vh - 128px)" }}
//         >
//           <SelectedListItemButton
//             selected={selectedIndex === 0}
//             onClick={() => handleListItemClick(0, "/staffpanel/members")}
//           >
//             <ListItemIcon>
//               <PeopleAltIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Members" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 11}
//             onClick={() => handleListItemClick(11, "/staffpanel/groups")}
//           >
//             <ListItemIcon>
//               <WorkspacesIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Majors" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 1}
//             onClick={() => handleListItemClick(1, "/staffpanel/courses")}
//           >
//             <ListItemIcon>
//               <SchoolIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Courses" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 2}
//             onClick={() => handleListItemClick(2, "/staffpanel/lessons")}
//           >
//             <ListItemIcon>
//               <CastForEducationIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Lessons" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 3}
//             onClick={() =>
//               handleListItemClick(3, "/staffpanel/assigncoursetostudent")
//             }
//           >
//             <ListItemIcon>
//               <AttachmentIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Assign Course" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 4}
//             onClick={() =>
//               handleListItemClick(4, "/staffpanel/students/reportcards")
//             }
//           >
//             <ListItemIcon>
//               <AssessmentIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Report Card" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 5}
//             onClick={() => handleListItemClick(5, "/staffpanel/notifications")}
//           >
//             <ListItemIcon>
//               <NotificationsIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Notifications" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 16}
//             onClick={() => handleListItemClick(16, "/staffpanel/library")}
//           >
//             <ListItemIcon>
//               <LibraryBooksIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Library" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 6}
//             onClick={() => handleListItemClick(6, "/staffpanel/contentdrip")}
//           >
//             <ListItemIcon>
//               <LockClockIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Content Drip" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 13}
//             onClick={() => handleListItemClick(13, "/staffpanel/profiles")}
//           >
//             <ListItemIcon>
//               <PeopleOutlineIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Profiles" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 7}
//             onClick={() => handleListItemClick(7, "/staffpanel/assignments")}
//           >
//             <ListItemIcon>
//               <AssignmentIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Assignments" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 10}
//             onClick={() => handleListItemClick(10, "/staffpanel/tables")}
//           >
//             <ListItemIcon>
//               <TableViewIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Tables" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 14}
//             onClick={() => handleListItemClick(14, "/staffpanel/categories")}
//           >
//             <ListItemIcon>
//               <CategoryIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Categories" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 8}
//             onClick={() => {
//               handleListItemClick(8, "");
//               handleNestedClick();
//               navigate("/staffpanel/resources/pdf");
//             }}
//           >
//             <ListItemIcon>
//               <SourceIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Resources" />
//           </SelectedListItemButton>

//           <SelectedListItemButton
//             selected={selectedIndex === 9}
//             onClick={() => {
//               handleListItemClick(9, "");
//               setToolsOpen((prev) => !prev);
//             }}
//           >
//             <ListItemIcon>
//               <HistoryEduIcon style={{ color: "#fff" }} />
//             </ListItemIcon>
//             <ListItemText primary="Tools" />
//             {toolsOpen ? (
//               <ExpandLess style={{ color: "#fff" }} />
//             ) : (
//               <ExpandMore style={{ color: "#fff" }} />
//             )}
//           </SelectedListItemButton>

//           <Collapse in={toolsOpen} timeout="auto" unmountOnExit>
//             <List component="div" disablePadding>
//               <SelectedListItemButton
//                 selected={toolsIndex === 0}
//                 onClick={() =>
//                   handleToolsIndex(0, "/staffpanel/tools/shahname")
//                 }
//               >
//                 <Button
//                   fullWidth
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     color: "#fff",
//                   }}
//                 >
//                   <FontAwesomeIcon icon={faPenNib} />
//                   Shahname
//                 </Button>
//               </SelectedListItemButton>
//               <SelectedListItemButton
//                 selected={toolsIndex === 1}
//                 onClick={() =>
//                   handleToolsIndex(1, "/staffpanel/tools/parsiban")
//                 }
//               >
//                 <Button
//                   fullWidth
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     color: "#fff",
//                   }}
//                 >
//                   <TranslateIcon />
//                   Parsiban Dictionary
//                 </Button>
//               </SelectedListItemButton>
//               <SelectedListItemButton
//                 selected={toolsIndex === 2}
//                 onClick={() => handleToolsIndex(2, "/staffpanel/tools/pubmed")}
//               >
//                 <Button
//                   fullWidth
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     color: "#fff",
//                   }}
//                 >
//                   <BiotechIcon />
//                   Pubmed
//                 </Button>
//               </SelectedListItemButton>
//               <SelectedListItemButton
//                 selected={toolsIndex === 3}
//                 onClick={() =>
//                   handleToolsIndex(3, "/staffpanel/tools/avestakb")
//                 }
//               >
//                 <Button
//                   fullWidth
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     color: "#fff",
//                   }}
//                 >
//                   <KeyboardIcon />
//                   Avesta keyboard
//                 </Button>
//               </SelectedListItemButton>
//             </List>
//           </Collapse>
//         </ListContainer>
//       </Drawer>
//     </div>
//   );
// };

// export default AdminSideBar;

// AdminSideBar.js
import React, { useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SidebarItem from "../sharedSidebar/SidebarItem";
import CollapsibleSection from "../sharedSidebar/CollapsibleSection";
// import withNavigation from "../sharedSidebar/withNavigation";
import { ListContainer } from "../sharedSidebar/StyledComponent";
import icons from "../sharedSidebar/icons";
import cyrusUniLogo from "../../../images/logoTextWhite.png";
import { useNavigate } from "react-router-dom";

const StaffSidebar = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const onItemClick = (index, path) => {
    setSelectedIndex(index);

    navigate(path);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const mainItems = [
    // {
    //   text: "Public Info",
    //   path: "/staffpanel/publicinfo",
    //   icon: <icons.PeopleAltIcon />,
    // },
    {
      text: "Profile",
      path: "/staffpanel/profile",
      icon: <icons.PeopleOutlineIcon />,
    },
    {
      text: "Members",
      path: "/staffpanel/members",
      icon: <icons.PeopleAltIcon />,
    },
    {
      text: "Majors",
      path: "/staffpanel/groups",
      icon: <icons.WorkspacesIcon />,
    },
    {
      text: "Courses",
      path: "/staffpanel/courses",
      icon: <icons.SchoolIcon />,
    },
    {
      text: "Lessons",
      path: "/staffpanel/lessons",
      icon: <icons.CastForEducationIcon />,
    },
    {
      text: "Resources",
      path: "/staffpanel/resources/pdf",
      icon: <icons.SourceIcon />,
    },
    {
      text: "Assign Course",
      path: "/staffpanel/assigncoursetostudent",
      icon: <icons.AttachmentIcon />,
    },
    {
      text: "Report Card",
      path: "/staffpanel/students/reportcards",
      icon: <icons.AssessmentIcon />,
    },
    {
      text: "Notifications",
      path: "/staffpanel/notifications",
      icon: <icons.NotificationsIcon />,
    },
    {
      text: "Library",
      path: "/staffpanel/library",
      icon: <icons.LibraryBooksIcon />,
    },
    {
      text: "Content Drip",
      path: "/staffpanel/contentdrip",
      icon: <icons.LockClockIcon />,
    },
    {
      text: "Profiles",
      path: "/staffpanel/profiles",
      icon: <icons.PeopleOutlineIcon />,
    },
    {
      text: "Assignments",
      path: "/staffpanel/assignments",
      icon: <icons.AssignmentIcon />,
    },
    {
      text: "Tables",
      path: "/staffpanel/tables",
      icon: <icons.TableViewIcon />,
    },
    {
      text: "Categories",
      path: "/staffpanel/categories",
      icon: <icons.CategoryIcon />,
    },
  ];

  const toolsItems = [
    {
      text: "Shahname",
      path: "/staffpanel/tools/shahname",
      icon: <icons.FontAwesomeIcon icon={icons.faPenNib} />,
    },
    {
      text: "Parsiban Dictionary",
      path: "/staffpanel/tools/parsiban",
      icon: <icons.TranslateIcon />,
    },
    {
      text: "Pubmed",
      path: "/staffpanel/tools/pubmed",
      icon: <icons.BiotechIcon />,
    },
    {
      text: "Avesta keyboard",
      path: "/staffpanel/tools/avestakb",
      icon: <icons.KeyboardIcon />,
    },
  ];

  return (
    <div className={`${isSmallScreen ? "z-100" : "z-20"}`}>
      {isSmallScreen && (
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={open}
        onClose={toggleDrawer(false)}
        className="w-72"
        sx={{ "& .MuiDrawer-paper": { border: 0 } }}
      >
        <div className="z-20 fixed w-72 h-32 bg-cu-blue flex justify-center items-center">
          <img src={cyrusUniLogo} alt="Logo" className="h-16 cursor-pointer" />
        </div>
        <ListContainer
          className="w-72 relative top-32 overflow-auto"
          style={{ height: "calc(100vh - 128px)" }}
        >
          {mainItems.map((item, index) => (
            <SidebarItem
              key={item.text}
              selected={selectedIndex === index}
              onClick={() => onItemClick(index, item.path)}
              icon={item.icon}
              text={item.text}
            />
          ))}
          <CollapsibleSection
            title="Tools"
            icon={<icons.HistoryEduIcon />}
            items={toolsItems}
            selectedIndex={selectedIndex}
            handleItemClick={(index, path) => {
              setSelectedIndex(index); // Set the selected index here
              onItemClick(index, path); // Call onItemClick for navigation
            }}
          />
        </ListContainer>
      </Drawer>
    </div>
  );
};

export default StaffSidebar;
