import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ShowStudentProfile from "../../student/profile/ShowStudentProfile";
import ShowCourse from "../../student/ShowCourse";
import ShowLesson from "../../student/ShowLesson";
import { onValue, ref } from "firebase/database";
import { db } from "../../../firebase.config";
import { useDispatch } from "react-redux";
import { addCourses } from "../../../redux/coursesSlice";
import { addlessons } from "../../../redux/lessonsSlice";
import { addAssignments } from "../../../redux/assignmentsSlice";
import ShowAssignment from "../../student/ShowAssignment";

import Shahname from "../../../pages/resourses/Shahname";
import Parsiban from "../../../pages/resourses/Parsiban";
import Pubmed from "../../../pages/resourses/Pubmed";
import AvestaKeyboard from "../../../pages/resourses/AvestaKeyboard";
import GradeToStudent from "../../../panels/shared/reportCards/GradeToStudent";
import { addtables } from "../../../redux/tablesSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { addUsers } from "../../../redux/usersSlice";
import { setImages } from "../../../redux/allImagesSlice";
import { setPdfs } from "../../../redux/allPdfsSlice";
import { setVideos } from "../../../redux/allVideosSlice";
import PanelLayout from "../PanelLayout";
import ShowCourses from "../../student/ShowCourses";
import ShowAllGroups from "../../shared/group/ShowAllGroups";
import ReviewGroup from "../../shared/group/ReviewGroup";
import Library from "../../shared/Library/Library";
const StudentPanel = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route path="/" element={<PanelLayout />}>
        <Route path="profile" element={<ShowStudentProfile />} />
        <Route path="groups" element={<ShowAllGroups />} />
        <Route path="viewgroup/:groupId" element={<ReviewGroup />} />
        <Route path="reportcard" element={<GradeToStudent />} />
        {/* <Route path="profile/addnewessay/:pdfId?" element={<AddNewPdf />} /> */}
        <Route path="courses" element={<ShowCourses />} />
        <Route path="courses/viewcourse/:courseId?" element={<ShowCourse />} />
        <Route path="courses/:courseId/:lessonId?" element={<ShowLesson />} />
        <Route
          path="assignments/:courseId/:assignmentId?"
          element={<ShowAssignment />}
        />
        <Route path="library" element={<Library />} />
        <Route path="tools/shahname" element={<Shahname />} />
        <Route path="tools/parsiban" element={<Parsiban />} />
        <Route path="tools/pubmed" element={<Pubmed />} />
        <Route path="tools/avestakb" element={<AvestaKeyboard />} />
      </Route>
    </Routes>
  );
};

export default StudentPanel;
