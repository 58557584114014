// import { get, onValue, ref } from "firebase/database";
// import React, { useEffect, useState } from "react";
// import {
//   assignCourseToLesson,
//   createEmptyCourseInDb,
//   db,
// } from "../../../../firebase.config";
// import EachLessonAssign from "./EachLessonAssign";
// import { toast } from "react-toastify";
// import { Button } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllLessons,
//   getLessonsByGroupcode,
//   getLessonsByIds,
// } from "../../../../redux/lessonsSlice";
// import {
//   addOtherInfo,
//   updateCourseStatus,
// } from "../../../../redux/coursesSlice";
// import { useParams } from "react-router-dom";
// const LessonAssign = ({ course }) => {
//   const { courseId } = useParams();
//   const dispatch = useDispatch();
//   // const [courseName, setCourseName] = useState("");
//   const [editAssignedLessons, setEditAssignedLessons] = useState(false);
//   const assignedLessons = course.assignedLessons
//     ? Object.keys(course.assignedLessons)
//     : [];
//   const fullLessonsAs = useSelector((state) =>
//     getLessonsByIds(state, assignedLessons)
//   );

//   const [list, setList] = useState([]);

//   const lessons = useSelector((state) =>
//     getLessonsByGroupcode(state, course.info.groupCode)
//   );

//   // const lesson = useSelector((state)=>state.createEditLesson.info.)

// const lessonAddToCourse = async () => {
//   // const listObj = list.reduce((acc, item) => {
//   //   acc[item.lessonId] = item;
//   //   return acc;
//   // }, {});
//   // dispatch(
//   //   addOtherInfo({ id: courseId, changes: { assignedLessons: listObj } })
//   // );
//   if (course.info.status === "temp") {
//     dispatch(updateCourseStatus({ courseId, status: "draft" }));
//     await createEmptyCourseInDb({ course });
//   }
//   const res = await assignCourseToLesson({
//     courseId,
//     list,
//     assignedLessons,
//     groupCode: course.info.groupCode,
//   });
//   console.log(res);
//   setEditAssignedLessons(false);
//   toast.success("changes saved");
//   // try {
//   // } catch (error) {
//   //   toast.error(error.message);
//   // }
// };

// const changeList = ({ assign, lessonId }) => {
//   if (assign) {
//     setList((prev) => [...prev, lessonId]);
//   } else {
//     setList((prev) => {
//       return prev.filter((prevLessonId) => prevLessonId !== lessonId);
//     });
//   }
// };

//   return (
//     <div className="w-full">
//       {editAssignedLessons ? (
//         <div className="flex flex-col gap-y-3">
//           <div className="border rounded-md p-2 h-48 overflow-auto flex flex-col gap-y-2">
//             <div className="flex justify-between border-b pb-3">
//               <p>Lesson Code</p>
//               <p>Lesson Name</p>
//             </div>
//             {lessons.map((lesson) => {
//               return (
//                 <EachLessonAssign
//                   assignedLessons={assignedLessons}
//                   lesson={lesson}
//                   changeList={changeList}
//                 />
//               );
//             })}
//           </div>
//           {/* <button className="mycu-btnBlue text-xs" onClick={assignToCourse}>
//             Assign
//           </button> */}
//           <div className="flex justify-around">
//             <Button onClick={() => setEditAssignedLessons(false)}>
//               Cancle
//             </Button>
//             <Button onClick={lessonAddToCourse}>Ok</Button>
//           </div>
//         </div>
//       ) : (
//         <div className="flex flex-col gap-y-3">
//           <div className="flex justify-between">
//             <p className="text-center">Lesson Code</p>
//             <p className="me-8">Lesson Name</p>
//             <p></p>
//           </div>
//           <div>
//             {fullLessonsAs?.length ? (
//               fullLessonsAs.map((lesson) => (
//                 <div className="flex justify-between">
//                   <p>{lesson.info.title}</p>
//                 </div>
//               ))
//             ) : (
//               <p className="text-center">there isn't any assigned lessons</p>
//             )}
//           </div>
//           <button
//             className="mycu-btnBlue text-xs"
//             onClick={() => setEditAssignedLessons(true)}
//           >
//             Assign Lessons
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default LessonAssign;
import { get, onValue, ref } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";
import {
  assignCourseToLesson,
  createEmptyCourseInDb,
  db,
} from "../../../../firebase.config";
// import EachLessonAssign from "./EachLessonAssign";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLessons,
  getLessonsByGroupcode,
  getLessonsByIds,
} from "../../../../redux/lessonsSlice";
import {
  addOtherInfo,
  getCourseById,
  updateCourseStatus,
} from "../../../../redux/coursesSlice";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
      width: 250,
    },
  },
  disablePortal: true, // Disable the default search functionality
};

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}
function getStyles(lesson, selectedLesson, theme) {
  return {
    fontWeight: selectedLesson.includes(lesson)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function LessonAssign({ groupCode }) {
  const user = useSelector((state) => state.user);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const allLessons = useSelector(getAllLessons);
  const [lessons, setLessons] = useState([]);
  const course = useSelector((state) => getCourseById(state, courseId));
  const assignedLessons = course?.assignedLessons
    ? Object.keys(course.assignedLessons)
    : [];
  // const initSelectedLessons = useSelector((state) =>
  //   getLessonsByIds(state, assignedLessons)
  // );
  // console.log(initSelectedLessons);
  const theme = useTheme();
  const [selectedLesson, setSelectedLesson] = useState(
    [undefined, ...assignedLessons] || [undefined]
  );

  useEffect(() => {
    if (!groupCode) return;

    const lll = allLessons.filter(
      (lesson) => lesson.info.groupCode === groupCode
    );
    setLessons(lll);
  }, [groupCode]);

  // useEffect(() => {
  //   dispatch(getLessonsByGroupcode);
  // }, [dispatch, groupCode]);
  // const [titleForSelectedLesson, setTitleForSelectedLesson] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredLessons, setFilteredLessons] = useState(lessons);
  const inputRef = useRef(null);
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    console.log(value);
    setSelectedLesson(() => {
      if (!value.includes(undefined)) {
        return [undefined, ...value];
      } else {
        return value;
      }
    });
  };
  console.log(selectedLesson);
  // useEffect(() => {
  //   setTitleForSelectedLesson();
  //   const newSLessons = lessons.filter((lesson) =>
  //     selectedLesson.includes(lesson.info.lessonId)
  //   );
  //   console.log(newSLessons);
  // }, [selectedLesson]);

  const lessonAddToCourse = async () => {
    if (course.info.status === "temp" && user.profile.role === "admin") {
      if (
        window.confirm(
          "After assign lesson course you can change group code anymore, are you willing to proceed ?"
        )
      ) {
        try {
          await createEmptyCourseInDb({ course });
          const res = await assignCourseToLesson({
            courseId,
            list: selectedLesson.filter((lesson) => lesson !== undefined),
            assignedLessons,
            groupCode,
          });
          dispatch(updateCourseStatus({ courseId, status: "draft" }));
          console.log(res);
          toast.success("changes saved");
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        const res = await assignCourseToLesson({
          courseId,
          list: selectedLesson.filter((lesson) => lesson !== undefined),
          assignedLessons,
          groupCode,
        });
        console.log(res);
        toast.success("changes saved");
      } catch (error) {
        console.log(error);
      }
    }
    // const listObj = list.reduce((acc, item) => {
    //   acc[item.lessonId] = item;
    //   return acc;
    // }, {});
    // dispatch(
    //   addOtherInfo({ id: courseId, changes: { assignedLessons: listObj } })
    // );

    // try {
    // } catch (error) {
    //   toast.error(error.message);
    // }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = () => {
    setIsDropdownOpen(true);
  };
  useEffect(() => {
    if (!isDropdownOpen) return;
    if (inputRef.current && isDropdownOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [isDropdownOpen]);

  const handleClose = () => {
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    const newLessons = lessons.filter((lesson) =>
      lesson.info.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (searchTerm) {
      setFilteredLessons(newLessons);
    } else {
      setFilteredLessons(lessons);
    }
    // if (isDropdownOpen) {
    //   console.log("object");
    //   inputRef.current.focus();
    // }
  }, [lessons, searchTerm]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <FormControl className="w-full">
      <Select
        multiple
        displayEmpty
        value={selectedLesson}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 1) {
            return <em>Select Lessons For Course</em>;
          }
          const selLes = lessons.filter((lsn) =>
            selected.includes(lsn.info.lessonId)
          );

          return (
            <div className="text-wrap">
              {selLes.map(
                (lsn) =>
                  lsn !== undefined && (
                    <p>
                      {lsn.info.title} - {lsn.info?.name}
                    </p>
                  )
              )}
            </div>
          );
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {isDropdownOpen && (
          // <div className="px-3">
          <div
            className="px-3"
            onKeyDown={(e) => e.stopPropagation()}
            onClickCapture={stopImmediatePropagation}
          >
            <TextField
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              margin="dense"
              inputRef={inputRef}
            />
          </div>
          // </div>
        )}
        {filteredLessons?.map((lesson) => (
          <MenuItem
            key={lesson.info.lessonId}
            value={lesson.info.lessonId}
            style={getStyles(lesson, selectedLesson, theme)}
            // onKeyDown={(e) => e.stopPropagation()}
          >
            <Checkbox
              checked={selectedLesson.indexOf(lesson.info.lessonId) > -1}
            />
            <ListItemText primary={lesson.info.title} />
          </MenuItem>
        ))}
        <div className="flex w-full items-center justify-center sticky bottom-0 bg-white py-1">
          <Button
            variant="contained"
            color="primary"
            onClick={lessonAddToCourse}
            disabled={arraysEqual(
              [undefined, ...assignedLessons],
              selectedLesson
            )}
          >
            {" "}
            Save Changes{" "}
          </Button>
        </div>
      </Select>
    </FormControl>
  );
}
