import React, { useCallback, useMemo } from "react";
import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { db } from "../../../firebase.config";
import { get, onValue, ref, update } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LessonRow from "./LessonRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  createEmptyLesson,
  getAllLessons,
  removeNotPublishedLesson,
} from "../../../redux/lessonsSlice";
import { toast } from "react-toastify";
import { IconButton, TextField } from "@mui/material";

const ShowAllLessons = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lessons = useSelector(getAllLessons);

  const [filteredLessons, setFilteredLessons] = useState([]);
  const [sortType, setSortType] = useState("publishDate");
  const [order, setOrder] = useState(true);
  const [search, setSearch] = useState("");
  const [lessonsStatus, setLessonsStatus] = useState("published");
  // const searchRef = useRef();
  useEffect(() => {
    const filtered = lessons?.filter(
      (lesson) => lesson?.info?.status === "published"
    );
    setFilteredLessons(filtered);
  }, [lessons]);

  useEffect(() => {
    setFilteredLessons((prev) => {
      if (!prev?.length) return;
      if (order) {
        const sortedData = prev.sort((a, b) => {
          if (!b?.info || !a?.info) return 0;
          return b?.info[sortType]
            ?.toString()
            .localeCompare(a?.info[sortType]?.toString(), undefined, {
              numeric: true,
            });
        });
        return sortedData;
      } else {
        const sortedData = prev.sort((a, b) => {
          if (!b?.info || !a?.info) return 0;
          return a?.info[sortType]
            .toString()
            .localeCompare(b?.info[sortType].toString(), undefined, {
              numeric: true,
            });
        });
        return sortedData;
      }
    });
  }, [sortType, order]);

  const AddNewLesson = async () => {
    let lessonId;
    let lastLessonNum;
    const updateRef = ref(db, `/managementInfo/LessonInformation`);
    const getRef = ref(db, `/managementInfo/LessonInformation/lessonIdCounter`);
    try {
      let snap = await get(getRef);
      if (!snap.exists()) {
        await update(updateRef, { lessonIdCounter: 1 });
        lessonId = "lesson_1";
      } else {
        lastLessonNum = await snap.val();
        lastLessonNum += 1;
        await update(updateRef, { lessonIdCounter: lastLessonNum });
        lessonId = "lesson_" + lastLessonNum;
      }
      user.profile.role === "admin" &&
        navigate(`/adminpanel/addnewlesson/${lessonId}`);
      user.profile.role === "instructor" &&
        navigate(`/instructorpanel/addnewlesson/${lessonId}`);
    } catch (error) {
      toast.error(error.message, "problem assign lesson id");
    }
  };
  const clickOnSorting = (e) => {
    if (sortType === e.target.value) {
      setOrder((prev) => !prev);
    } else {
      setSortType(e.target.value);
      setOrder(true);
    }
  };

  const publishOrDraft = (e) => {
    setLessonsStatus(e.target.value);
    const arrOFilteredLessons = lessons.filter(
      (lesson) => lesson.info?.status === e.target.value
    );
    setFilteredLessons(arrOFilteredLessons);
  };

  const searchLessons = (e) => {
    setFilteredLessons(
      lessons.filter(
        (lesson) =>
          lesson.info.title.toLocaleLowerCase().includes(e.target.value) &&
          lesson.info.status === lessonsStatus
      )
    );
  };
  return (
    <div className="w-7xl m-auto mt-10 flex flex-col mx-10 ">
      <div className="mb-5 ">
        Lessons:
        <IconButton onClick={AddNewLesson} className="ms-3">
          <AddCircleIcon className="text-blue-500" />
        </IconButton>
      </div>
      <div className="flex gap-x-2 mb-2">
        <button
          onClick={publishOrDraft}
          value={"published"}
          className={lessonsStatus === "published" && "text-blue-500"}
        >
          Published
        </button>
        <span>|</span>
        <button
          onClick={publishOrDraft}
          value={"draft"}
          className={lessonsStatus === "draft" && "text-blue-500"}
        >
          Draft
        </button>
      </div>
      <table className="table-auto">
        <thead>
          <tr className="flex items-center border py-3">
            <th className="w-1/6">
              <button>Row ID</button>
            </th>
            {/* <th className="w-1/6">
              <button>Lesson ID</button>
            </th> */}
            <th className="w-1/6">
              {" "}
              <button>Title</button>
            </th>
            <th className="w-1/6">
              {" "}
              <button>Name</button>
            </th>
            <th className="w-1/6">
              <button>Assigned Course</button>
            </th>
            <th className="w-1/6">
              <button>Creation Date</button>
            </th>
            <th className="w-1/6 flex border items-center rounded-full h-8 px-2 me-2">
              {/* <div className=""> */}
              <input
                className="w-full mps-2"
                type="text"
                placeholder="Search"
                // ref={searchRef}
                onChange={searchLessons}
              />
              <button
                className="text-slate-500"
                // onClick={() => setSearch(searchRef.current.value)}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
              {/* </div> */}
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {filteredLessons?.map((item, index) => {
            return <LessonRow key={item.info.lessonId} item={item} />;
          })}
        </tbody>
      </table>

      {/* <div className='h-52  flex'>

      </div> */}
    </div>
  );
};

export default ShowAllLessons;
