import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import CourseNotification from "./CourseNotification";
import { useSelector } from "react-redux";
import { getAllCourses } from "../../../../redux/coursesSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    whiteSpace: "normal",
  },
});

const SelectCourse = ({ setSelectedCourses, selectedCourses }) => {
  const courses = useSelector(getAllCourses);

  // const coursesCheckChange = ({ courseToEdit, checked }) => {
  //   if (!checked) {
  //     setSelectedCourses((prev) =>
  //       prev.filter(
  //         (course) =>
  //           course.info.courseId !== courseToEdit.info.courseId
  //       )
  //     );
  //   } else {
  //     setSelectedCourses((prev) => [...prev, courseToEdit]);
  //   }
  // };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCourses(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-checkbox-label">Course</InputLabel>
      <StyledSelect
        labelId="multiple-checkbox-label"
        label="Group Code"
        multiple
        value={selectedCourses}
        onChange={handleChange}
        renderValue={(selected) => selected?.join(", ")}
        MenuProps={MenuProps}
      >
        {courses.map((course) => {
          return (
            <MenuItem key={course.info.courseId} value={course.info.courseId}>
              <Checkbox
                checked={selectedCourses.indexOf(course.info.courseId) > -1}
              />
              <ListItemText primary={course.info.title} />
            </MenuItem>
          );
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectCourse;
