import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getAllGroups } from "../../../redux/groupsSlice";

const GroupCodeSection = ({ groupCode, setGroupCode, disabled }) => {
  const groups = useSelector(getAllGroups);

  const handleChane = (e) => {
    setGroupCode(e.target.value);
  };
  return (
    <div>
      <Box className={"w-full "} sx={{ margin: "auto" }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="group-select-label">Group</InputLabel>
          <Select
            labelId="group-select-label"
            value={groupCode}
            onChange={handleChane}
            label="Group"
            disabled={Boolean(disabled)}
          >
            {groups.map((group) => (
              <MenuItem
                key={group.id}
                value={group.id}
                selected={groupCode === group.id}
              >
                {group.id + " - " + group.englishName}
              </MenuItem>
            ))}
          </Select>

          {!groupCode && (
            <FormHelperText sx={{ color: "#bf0a30" }}>
              You must specify group code
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </div>
  );
};

export default GroupCodeSection;
