import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import cyrusUniLogo from "../images/logoTextWhite.png";

const NotFound = () => {
  const location = useLocation();

  return (
    <Container sx={{ color: "#fff !important" }} className="bg-cu-blue">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <img
          src={cyrusUniLogo}
          alt="Logo"
          style={{ width: "150px", marginBottom: "20px" }}
        />
        <Typography variant="h4" component="h1" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h6" component="h2">
          The requested URL <code>{location.pathname}</code> was not found.
        </Typography>
        <Typography variant="body1" component="p" style={{ marginTop: "20px" }}>
          Cyrus University
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;
