import React from 'react'

const Register = () => {
    return (
        <div className='flex items-center justify-center mt-6'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfVKh5LMC92EkgmAUZK6kQKxRSUdXepizTef8D7TT6BzPV0WQ/viewform?embedded=true" width="800" height="1700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}

export default Register
