import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLessonById } from "../../../redux/lessonsSlice";
import ReactQuill from "react-quill";
import ViewAddedResources from "../../shared/sharedComp/showResources/ViewAddedResources";
import TimeShow from "../../../shared/utils/TimeShow";

const ReviewLesson = () => {
  const { lessonId } = useParams();
  const lesson = useSelector((state) => getLessonById(state, lessonId));

  const { title, explain, createAt, editAt, status, lessonCreator } =
    lesson.info;
  const assignedCourses = lesson.assignedCourses
    ? Object.keys(lesson.assignedCourses)
    : [];
  return (
    <Card className="w-11/12 mx-auto my-4">
      {/* <img
        src={course.files.courseImage.url}
        alt={title}
        className="w-full h-64 object-contain"
      /> */}
      <CardContent>
        {/* Group One */}
        <Typography variant="h6" component="div" className="pt-8">
          Lesson Details
        </Typography>
        <Typography variant="h5" component="div" className="text-center">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Explain: <ReactQuill value={explain} readOnly={true} theme="bubble" />
        </Typography>

        <ViewAddedResources content={lesson} />
        <Grid container spacing={1} className="pt-8">
          <Grid item xs={6}>
            <Typography variant="body2">Course ID: {lessonId}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Publish Date: <TimeShow date={new Date(createAt)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Edit At: <TimeShow date={new Date(editAt)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="flex gap-x-3">
              Create At:
              <TimeShow date={new Date(createAt)} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Status: {status}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Lesson Creator: {lessonCreator}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" component="div" className="pt-8">
          Assigned Courses
        </Typography>
        <Grid container spacing={1} className="mt-2">
          {assignedCourses.map((course) => (
            <Grid item xs={6}>
              <Typography variant="body2">{course.title}</Typography>
              <div variant="body2" className="text-xs text-slate-500">
                {course}
              </div>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {/* <ViewAddedResources /> */}
    </Card>
  );
};

export default ReviewLesson;
