import React from "react";
import { Route, Routes } from "react-router-dom";
import ChangePassword from "./auth/ChangePassword";
import About from "./InformationPages/About";
import TermsOfService from "./InformationPages/TermsOfService";
import PrivacyPolicy from "./InformationPages/PrivacyPolicy";
import NotFound from "./NotFound";
import GeneralLayout from "./GeneralLayout";
import Home from "./home/Home";
import Login from "./auth/login/Login";
import Register from "./auth/Register";
import Shahname from "./resourses/Shahname";
import Parsiban from "./resourses/Parsiban";
import Pubmed from "./resourses/Pubmed";
import ReviewHomeCourse from "./home/ReviewHomeCourse";
import Contact from "./InformationPages/Contact";
import LibraryOnline from "./resourses/LibraryOnline";

const GeneralPanel = () => {
  return (
    <Routes>
      <Route path="/" element={<GeneralLayout />}>
        <Route index element={<Home />} />

        <Route path="/register" element={<Register />} />
        <Route path="/resources/shahname" element={<Shahname />} />
        <Route path="/resources/parsibandictionary" element={<Parsiban />} />
        <Route path="/resources/pubmed" element={<Pubmed />} />
        <Route path="/resources/library" element={<LibraryOnline />} />

        <Route path="/information/about" element={<About />} />
        <Route path="/information/contact" element={<Contact />} />
        <Route
          path="/information/termsofservice"
          element={<TermsOfService />}
        />
        <Route
          path="/information/privacyandpolicy"
          element={<PrivacyPolicy />}
        />
        <Route path={`/view/course/:courseId`} element={<ReviewHomeCourse />} />
        <Route path={`/view/instructor/:userId`} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default GeneralPanel;
