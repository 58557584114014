import React from "react";
import { Backdrop, CircularProgress, Box, Typography } from "@mui/material";

const LoadingOverlay = ({ loading, message = "Loading..." }) => {
  return (
    <Backdrop
      open={loading}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <CircularProgress color="inherit" />
        <Typography variant="h6">{message}</Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;
