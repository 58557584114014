import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const coursesAdapter = createEntityAdapter();

const initialState = coursesAdapter.getInitialState();

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    addCourses: coursesAdapter.setAll,
    createCourse: coursesAdapter.setOne,
    removeNotPublishedCourse: coursesAdapter.removeOne,
    addOtherInfo: coursesAdapter.updateOne,
    updateGroupCode: (state, { payload }) => {
      const { courseId, groupCode } = payload;
      const entity = state.entities[courseId];
      entity.info.groupCode = groupCode;
    },
    addCourseCategory: (state, { payload }) => {
      const { id, category } = payload;
      const entity = state.entities[id];
      if (!entity.categoriesName) {
        entity.categoriesName = [];
      }
      entity.categoriesName = [...entity.categoriesName, category];
    },
    uploadResourceIdCourse: (state, { payload }) => {
      const { courseId, resourceId, item } = payload;
      const entity = state.entities[courseId];
      entity.resources[resourceId] = item;
    },
    removeCourseCategory: (state, { payload }) => {
      const { id, category } = payload;
      const entity = state.entities[id];
      if (!entity.categoriesName) {
        entity.categoriesName = [];
      }
      entity.categoriesName = entity.categoriesName?.filter(
        (item) => item !== category
      );
    },
    updateCourseStatus: (state, { payload }) => {
      const { courseId, status } = payload;
      state.entities[courseId].info.status = status;
    },
  },
});

export const {
  updateGroupCode,
  addCourses,
  createCourse,
  removeNotPublishedCourse,
  addOtherInfo,
  addCourseCategory,
  removeCourseCategory,
  updateCourseStatus,
  uploadResourceIdCourse,
} = coursesSlice.actions;
export const getAllCourses = (state) =>
  coursesAdapter
    .getSelectors()
    .selectAll(state.courses)
    .sort((a, b) => b.info.createAt - a.info.createAt);

export const getCourseById = (state, courseId) =>
  coursesAdapter.getSelectors().selectById(state.courses, courseId);

export const getCoursesEntities = (state) =>
  coursesAdapter.getSelectors().selectEntities(state.courses);

export const getCoursesByIds = createSelector(
  [getCoursesEntities, (state, ids) => ids],
  (entities, ids) => ids?.map((id) => entities[id])
);
// export const getCoursesByGroupCode = createSelector(
//   [getCoursesEntities, (state, ids) => ids],
//   (entities, ids) => ids?.map((id) => entities[id])
// );

export default coursesSlice.reducer;
