import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
// import TimeShow from "../../shared/sharedComp/TimeShow";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { toast } from "react-toastify";
import { changeRowIdInDb, deleteRealItem } from "../../../firebase.config";
import PreviewIcon from "@mui/icons-material/Preview";
import { Button, TableCell, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import TimeShow from "../../../shared/utils/TimeShow";

import ActionsMenuLesson from "./ActionsMenuLesson";
const LessonRow = ({ item }) => {
  const user = useSelector((state) => state.user);
  const coursesInstructor =
    (user?.assignedCourses && Object.keys(user.assignedCourses)) || [];
  const coursesLessson =
    (item?.assignedCourses && Object.keys(item.assignedCourses)) || [];

  const [enableEditRowId, setEnableEditRowId] = useState(false);
  const lessonId = item.info.lessonId;
  const [rowId, setRowId] = useState(item.info.rowId);
  const navigate = useNavigate();
  const goToEditLesson = () => {
    navigate(`/${user.profile.role}panel/editlesson/${item.info.lessonId}`);
  };

  const deleteLesson = async () => {
    const groupCode = item.info.groupCode;
    if (!lessonId || !groupCode) {
      toast.error("lesson Id does not exists");
      throw new Error("id is missed");
    }
    if (window.confirm("are you sure to remove course")) {
      const coursesId = item.assignedCourses
        ? Object.keys(item.assignedCourses)
        : [];

      const courseLessonsDeletePromise = coursesId?.map((courseId) => {
        if (!courseId || !groupCode) {
          toast.error(
            `course Id is not exists, an error take place please check db`
          );
          throw new Error("id not found");
        } else {
          const coursePath = `/courses/${groupCode}/${courseId}/assignedLessons/${lessonId}`;
          return deleteRealItem(coursePath);
        }
      });

      const assignmentsId = item.assignedAssignments
        ? Object.keys(item.assignedAssignments)
        : [];

      const assignmentLessonsDeletePromise = assignmentsId?.map((asId) => {
        if (!asId) {
          toast.error(
            `assignment Id is not exists, an error take place please check db`
          );
          throw new Error("id not found");
        } else {
          const assignmentPath = `/la/assignments/${groupCode}/${asId}/assignedLessons/${lessonId}`;
          return deleteRealItem(assignmentPath);
        }
      });

      const realPath = `/la/lessons/${groupCode}/${lessonId}`;
      try {
        await Promise.all(assignmentLessonsDeletePromise);
        await Promise.all(courseLessonsDeletePromise);
        await deleteRealItem(realPath);
        toast.success("successfully delete lesson");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  const viewLesson = () => {
    navigate(`/${user.profile.role}panel/lessons/viewlesson/${lessonId}`);
  };

  const handleCancleRowId = () => {
    setEnableEditRowId(false);
    setRowId(item.info.rowId);
  };

  const changeRowId = async () => {
    setEnableEditRowId(false);
    try {
      await changeRowIdInDb({
        lessonId,
        rowId,
        groupCode: item.info.groupCode,
      });
      toast.success("successfully change row ID");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <tr className="border-b h-14 flex items-center relative text-center">
      <ActionsMenuLesson
        onView={viewLesson}
        onDelete={deleteLesson}
        onEdit={goToEditLesson}
        lesson={item}
      />
      <td className="w-1/6 flex justify-center">
        <TableCell>
          {!enableEditRowId ? (
            <div className="group flex items-center relative">
              <p>{rowId || "---"}</p>
              <div className="hidden group-hover:block absolute">
                <Button onClick={() => setEnableEditRowId(true)}>
                  <EditIcon />
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-x-4">
              <TextField
                type="number"
                id="outlined-basic"
                label="Row Id"
                variant="outlined"
                size="small"
                className="w-20"
                onChange={(e) => setRowId(e.target.value)}
                value={rowId}
              />
              <div className="flex flex-col">
                <Button onClick={changeRowId}>Ok</Button>
                <Button onClick={handleCancleRowId}>Cancle</Button>
              </div>
            </div>
          )}
        </TableCell>
      </td>

      {/* <td className="w-1/6">
        <p>{item.info.lessonId}</p>
      </td> */}

      <td className="w-1/6">
        <p>{item.info?.title || "---"}</p>
      </td>
      <td className="w-1/6">
        <p>{item.info?.name || "---"}</p>
      </td>
      {/* <td className="text-blue-700 w-1/6">
        <p>{item.info?.lessonCreator}</p>
      </td> */}
      <td className="w-1/6">
        <div className="flex flex-wrap justify-center">
          {item?.assignedCourses
            ? Object.keys(item?.assignedCourses)?.map((course) => (
                <p>{course}</p>
              ))
            : "---"}
        </div>
      </td>
      <td className="w-1/6">
        <TimeShow date={new Date(item.info.createAt)} />
      </td>
    </tr>
  );
};

export default LessonRow;
