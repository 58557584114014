import React, { useCallback, useMemo } from "react";
import {
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faArrowUpAZ,
  faClock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { db } from "../../../firebase.config";
import { get, ref, update } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AssignmentRow from "./AssignmentRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getAllAssignments } from "../../../redux/assignmentsSlice";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
const ShowAllAssignments = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assignments = useSelector(getAllAssignments);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [sortType, setSortType] = useState("publishDate");
  const [order, setOrder] = useState(true);
  const [assignmentsStatus, setAssignmentsStatus] = useState("published");

  useEffect(() => {
    const filtered = assignments.filter(
      (assignment) => assignment.info?.status === "published"
    );
    setFilteredAssignments(filtered);
  }, [assignments]);

  useEffect(() => {
    setFilteredAssignments((prev) => {
      if (!prev?.length) return;
      if (order) {
        const sortedData = prev.sort((a, b) => {
          if (!b?.info || !a?.info) return 0;
          return b?.info[sortType]
            ?.toString()
            .localeCompare(a?.info[sortType]?.toString(), undefined, {
              numeric: true,
            });
        });
        return sortedData;
      } else {
        const sortedData = prev.sort((a, b) => {
          if (!b?.info || !a?.info) return 0;
          return a?.info[sortType]
            ?.toString()
            .localeCompare(b?.info[sortType]?.toString(), undefined, {
              numeric: true,
            });
        });
        return sortedData;
      }
    });
  }, [sortType, order]);

  const AddNewAssignment = async () => {
    let assignmentId;
    const updateRef = ref(db, `/managementInfo/AssignmentInformation`);
    const getRef = ref(
      db,
      `/managementInfo/AssignmentInformation/assignmentIdCounter`
    );
    try {
      let snap = await get(getRef);
      if (!snap.exists()) {
        await update(updateRef, { assignmentIdCounter: 1 });
        assignmentId = "assignment_1";
      } else {
        let lastAssignmentNum = await snap.val();
        lastAssignmentNum += 1;
        await update(updateRef, { assignmentIdCounter: lastAssignmentNum });
        assignmentId = "assignment_" + lastAssignmentNum;
      }
      user.profile.role === "admin" &&
        navigate(`/adminpanel/addnewassignment/${assignmentId}`);
      user.profile.role === "instructor" &&
        navigate(`/instructorpanel/addnewassignment/${assignmentId}`);
    } catch (error) {
      toast.error(error.message, "problem assign assignment id");
    }
  };
  const clickOnSorting = (e) => {
    if (sortType === e.target.value) {
      setOrder((prev) => !prev);
    } else {
      setSortType(e.target.value);
      setOrder(true);
    }
  };

  const publishOrDraft = (e) => {
    setAssignmentsStatus(e.target.value);
    const arrOFilteredAssignments = assignments.filter(
      (assignment) => assignment.info?.status === e.target.value
    );
    setFilteredAssignments(arrOFilteredAssignments);
  };
  const searchAssignments = (e) => {
    setFilteredAssignments(
      assignments.filter(
        (assignment) =>
          assignment.info.title.toLocaleLowerCase().includes(e.target.value) &&
          assignment.info.status === assignmentsStatus
      )
    );
  };
  return (
    <div className="w-7xl m-auto mt-10 flex flex-col mx-10">
      <div className="mb-5 ">
        Assignments:
        <IconButton onClick={AddNewAssignment} className="ms-3">
          <AddCircleIcon className="text-blue-500" />
        </IconButton>
      </div>
      <div className="flex gap-x-2 mb-2">
        <button
          onClick={publishOrDraft}
          value={"published"}
          className={assignmentsStatus === "published" && "text-blue-500"}
        >
          Published
        </button>
        <span>|</span>
        <button
          onClick={publishOrDraft}
          value={"draft"}
          className={assignmentsStatus === "draft" && "text-blue-500"}
        >
          Draft
        </button>
      </div>
      <table className="table-auto">
        <thead>
          <tr className="flex justify-start items-center border py-3">
            <th className="w-1/4">
              <button
                className={`${sortType === "assignmentId" && "text-blue-500"}`}
                value={"assignmentId"}
                onClick={clickOnSorting}
              >
                ID
                {sortType === "assignmentTitle" && (
                  <>
                    {order ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )}
                  </>
                )}
              </button>
            </th>
            <th className="w-1/4">
              <button>Title</button>
            </th>
            <th className="w-1/4">
              <button>Author</button>
            </th>

            <th className="w-1/4">
              <div className="flex border items-center rounded-full h-8 px-2 me-8">
                <input
                  className="ps-2"
                  type="text"
                  placeholder="Search"
                  onChange={searchAssignments}
                  // ref={searchRef}
                />
                <button
                  className="text-slate-500"
                  // onClick={() => setSearch(searchRef.current.value)}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {filteredAssignments?.map((item, index) => {
            return <AssignmentRow key={`itemname${index}`} item={item} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ShowAllAssignments;
