import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
// import InputFileComp from "./InputFileComp"
import NavigationLink from "../../../panels/shared/sharedComp/NavigationLink";
import CreateEmail from "./addnewmember/CreateEmail";
import ProfileImage from "./addnewmember/ProfileImage";
import RegisterAddress from "./addnewmember/RegisterAddress";
import RegisterationForm from "./addnewmember/RegisterationForm";
import { useSelector } from "react-redux";
import { getUserById } from "../../../redux/usersSlice";
import SelectMultipleMajors from "./addnewmember/SelectMultipleMajors";

const AddNewMember = () => {
  const location = useLocation();

  // const newUser = useSelector((state) => state.createEditUser);
  const { uid } = useParams();
  const newUser = useSelector((state) => getUserById(state, uid));
  const NewUserInfo = newUser?.profile;
  const disabled = NewUserInfo?.email ? false : true;
  const lastpart =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const pageType = location.pathname.includes("addnewmember")
    ? "Create"
    : "Edit";

  return (
    // <div className='flex flex-col items-center'>
    <div className="m-auto w-10/12 my-10">
      <div className=" flex justify-between">
        <p>{pageType + " Member : " + uid}</p>
        <p>
          {NewUserInfo?.name} {NewUserInfo?.family}
        </p>
      </div>
      <div className="border border-slate-400 flex">
        <ul className="flex flex-col bg-slate-100 w-60 border text-blue-500 pb-40">
          <NavigationLink path={"./createemail"} name="Email Information" />
          <NavigationLink
            path={"./profilephoto"}
            name="Profile Image"
            disabled={disabled}
          />
          <NavigationLink
            path={"./address"}
            name="Address"
            disabled={disabled}
          />
          <NavigationLink
            path={"./regitrationforms"}
            name="Registeration Form"
            disabled={disabled}
          />
          <NavigationLink
            path={"./addmajors"}
            name="Add Majors"
            disabled={disabled}
          />
        </ul>
        <div className="py-4 w-full">
          {lastpart === "createemail" && <CreateEmail />}
          {lastpart === "profilephoto" && <ProfileImage />}
          {lastpart === "address" && <RegisterAddress />}
          {lastpart === "regitrationforms" && <RegisterationForm />}
          {lastpart === "addmajors" && <SelectMultipleMajors />}
        </div>
      </div>
    </div>

    // </div>
  );
};

export default AddNewMember;
