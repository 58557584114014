import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PreviewIcon from "@mui/icons-material/Preview";
import { Avatar } from "@mui/material";
const UserRow = ({ item }) => {
  const navigate = useNavigate();
  const goToEditUser = () => {
    // dispatch(getSelectedUser({ item }));
    navigate(`/adminpanel/editmember/${item?.id}/createemail`);
  };
  const profile = item?.profile;

  const viewUser = () => {
    navigate(`viewuser/${item?.profile?.uid}`);
  };

  return (
    <tr className="border-b h-14 flex items-center gap-x-10 px-10 relative">
      <div className="absolute right-0 top-0 text-blue-600 me-1 mt-1 flex gap-x-3">
        <button onClick={viewUser}>
          <PreviewIcon className="text-blue-500" />
        </button>
      </div>

      {/* <p className="absolute left-2 top-0 text-slate-600 me-3 mt-3 ">
        <span>{profile.role}: </span>
        <span className="text-xs">{profile.uid}</span>
      </p> */}

      <td className="w-1/5 flex items-center">
        <Avatar
          className="rounded-full border w-14 h-14 object-contain"
          src={profile?.photoUrl?.url}
          alt={profile?.name}
        />
        <p className="ms-4">
          {profile?.name} {profile?.family}
        </p>
      </td>
      <td className="w-1/5">
        <p>{profile?.email}</p>
      </td>
      <td className="w-1/5">
        <p>{profile?.role}</p>
      </td>
      <td className="w-1/5">
        {profile?.role === "student" ? (
          <div>
            {" "}
            <p>{profile?.studentNumber}</p>
          </div>
        ) : (
          <p>---</p>
        )}
      </td>
    </tr>
  );
};

export default UserRow;
