import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  List,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ShowAssignment from "./ShowAssignment";
import ShowLesson from "./ShowLesson";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import {
  addAssignments,
  getAssignmentById,
  getAssignmentsByIds,
  upsertAssignments,
} from "../../redux/assignmentsSlice";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { getMissingAssignmentSt } from "../../firebase.config";

const EachLessonAcc = ({
  lesson,
  changeComp,
  index,
  expanded,
  setExpanded,
  courseId,
}) => {
  const dispatch = useDispatch();
  const assignmentIds = lesson?.assignedAssignments
    ? Object.keys(lesson.assignedAssignments)
    : [];
  const assignments = useSelector((state) =>
    getAssignmentsByIds(state, assignmentIds)
  );

  useEffect(() => {
    const fetchMissingAssignments = async () => {
      if (lesson?.assignedAssignments) {
        const assignmentIdsGr = Object.entries(lesson.assignedAssignments);

        // Filter out lessons that are not already in Redux
        const missingAssignmentIdsGr = assignmentIdsGr.filter(
          (idGr) =>
            !assignments.some(
              (assignment) => assignment?.info?.assignmentId === idGr[0]
            )
        );
        console.log(missingAssignmentIdsGr);
        if (missingAssignmentIdsGr.length > 0) {
          try {
            // Fetch missing lessons from Firebase
            const assignmentPromises = missingAssignmentIdsGr.map(
              async (idGr) => {
                return await getMissingAssignmentSt({
                  groupCode: "04",
                  assignmentId: idGr[0],
                });
              }
            );
            const fetchedAssignments = await Promise.all(assignmentPromises);

            // Dispatch action to addfetched lessons to Redux
            dispatch(upsertAssignments(fetchedAssignments));
          } catch (error) {
            console.error("Failed to fetch lessons:", error);
          }
        }
      }
    };

    fetchMissingAssignments();
  }, [dispatch]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const showAssignment = () => {
    changeComp(
      <ShowAssignment assignments={assignments} changeComp={changeComp} />
    );
  };
  const showLesson = (lesson) => {
    changeComp(
      <ShowLesson lesson={lesson} changeComp={changeComp} courseId={courseId} />
    );
  };

  return (
    <Accordion
      key={index}
      expanded={expanded === lesson?.info?.lessonId}
      onChange={handleChange(lesson?.info?.lessonId)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${lesson?.info?.lessonId}-content`}
        id={`${lesson?.info?.lessonId}-header`}
      >
        <Typography>{lesson?.info?.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          noWrap
          variant="body2"
          color="text.secondary"
          className="h-20"
        >
          <ReactQuill
            value={lesson?.info?.explain}
            readOnly={true}
            theme="bubble"
          />
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#002868" }}
            onClick={() => {
              showAssignment();
            }}
            disabled={!assignments?.length}
          >
            Show Assignment
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#002868" }}
            onClick={() => {
              showLesson(lesson);
            }}
          >
            Show Lesson
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default EachLessonAcc;
