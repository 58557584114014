import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserRow from "./UserRow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/usersSlice";
import { useRef, useState } from "react";
const ShowAllProfiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortUsers, setSortedUsers] = useState([]);
  const [sortType, setSortType] = useState("createAt");
  const [order, setOrder] = useState(true);
  const [role, setRole] = useState("all");
  //   const [filteredUser, setFilteredUser] = useState([]);
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const users = useSelector(getAllUsers);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const searchUser = (e) => {
    setFilteredUsers(
      users.filter(
        (user) =>
          user.profile.name.toLocaleLowerCase().includes(e.target.value) ||
          user.profile.family.toLocaleLowerCase().includes(e.target.value)
      )
    );
  };
  return (
    <div className="w-7xl m-auto my-10 flex flex-col">
      <table className="table-auto mx-10">
        <thead>
          <tr className="flex justify-between items-center border py-3">
            {/* <th className="flex px-3 py-2 gap-3">
              <button>Profile</button>
            </th> */}
            <th className="w-1/5">
              <button>Name</button>
            </th>
            <th className="w-1/5">
              <button>Email</button>
            </th>
            <th className="w-1/5">
              Role:{" "}
              <select
                className="border rounded-full p-1 w-40 text-center"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="all" defaultChecked>
                  All
                </option>
                <option value="student">Student</option>
                <option value="professor">Professor</option>
                <option value="staff">Staff</option>
              </select>
            </th>
            <th className="w-1/5">
              <button>Student Number</button>
            </th>

            <th className="flex border items-center rounded-full h-8 px-2 me-8 w-1/5">
              <input
                className="ps-2"
                type="text"
                placeholder="Search"
                // ref={searchRef}
                onChange={searchUser}
              />
              <button
                className="text-slate-500"
                // onClick={() => setSearch(searchRef.current.value)}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {filteredUsers
            ?.filter((user) =>
              role === "all" ? user : user.profile.role === role
            )
            ?.map((item, index) => {
              return <UserRow key={`itemname${index}`} item={item} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ShowAllProfiles;
