import React, { useState } from "react";
import {
  assignLessonToAssignment,
  createEmptyLessonInDb,
} from "../../../../firebase.config";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllAssignments } from "../../../../redux/assignmentsSlice";
import EachAssignmentAssign from "./EachAssignmentAssign";
import { updateLessonStatus } from "../../../../redux/lessonsSlice";
const AssignmentAssign = ({ lesson }) => {
  const { lessonId } = useParams();
  const dispatch = useDispatch();
  // const [assignmentName, setLessonName] = useState("");
  const [editAssignedAssignments, setEditAssignedAssignments] = useState(false);
  const assignedAssignments = lesson?.assignedAssignments
    ? Object.keys(lesson.assignedAssignments)
    : [];
  const [list, setList] = useState([]);
  const assignments = useSelector(getAllAssignments);

  const assignmentAddToLesson = async () => {
    // const listObj = list.reduce((acc, item) => {
    //   acc[item.assignmentId] = item;
    //   return acc;
    // }, {});
    // dispatch(
    //   addOtherInfo({ id: assignmentId, changes: { assignedAssignments: listObj } })
    // );
    console.log(lesson);
    if (lesson.info.status === "temp") {
      dispatch(updateLessonStatus({ lessonId, status: "draft" }));
      await createEmptyLessonInDb({ lesson });
    }
    const res = await assignLessonToAssignment({
      lesson,
      list,
      assignedAssignments,
    });

    setEditAssignedAssignments(false);
    toast.success("changes saved");
    // try {
    // } catch (error) {
    //   toast.error(error.message);
    // }
  };

  const changeList = ({ assign, assignmentId }) => {
    if (assign) {
      setList((prev) => [...prev, assignmentId]);
    } else {
      setList((prev) => {
        return prev.filter(
          (prevAssignmentId) => prevAssignmentId !== assignmentId
        );
      });
    }
  };

  return (
    <div>
      {editAssignedAssignments ? (
        <div className="flex flex-col gap-y-3">
          {/* <input
            type="text"
            value={assignmentName}
            onChange={(e) => setLessonName(e.target.value)}
          /> */}
          <div className="border rounded-md p-2 h-48 overflow-auto flex flex-col gap-y-2">
            <div className="flex justify-between border-b pb-3">
              <p className="me-8">Id</p>
              <p>Title</p>
              <p></p>
            </div>
            {assignments.map((assignment, index) => {
              return (
                <EachAssignmentAssign
                  key={index}
                  assignedAssignments={assignedAssignments}
                  assignment={assignment}
                  changeList={changeList}
                />
              );
            })}
          </div>
          {/* <button className="mycu-btnBlue text-xs" onClick={assignToLesson}>
            Assign
          </button> */}
          <div className="flex justify-around">
            <Button onClick={() => setEditAssignedAssignments(false)}>
              Cancle
            </Button>
            <Button onClick={assignmentAddToLesson}>Ok</Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-y-3">
          <div className="flex justify-between border-b ">
            <p className="me-8">Id</p>
            <p>Title</p>
          </div>
          <div>
            {assignedAssignments?.length ? (
              assignedAssignments.map((asid) => (
                <div className="flex justify-between">
                  <p>{asid}</p>
                </div>
              ))
            ) : (
              <p className="text-center">
                there isn't any assigned assignments
              </p>
            )}
          </div>
          <button
            className="mycu-btnBlue text-xs"
            onClick={() => setEditAssignedAssignments(true)}
          >
            Assign Assignments
          </button>
        </div>
      )}
    </div>
  );
};

export default AssignmentAssign;
