import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const NavigationLink = ({ path, name, disabled }) => {
  const handleClick = (e) => {
    if (disabled) {
      toast("please first fill above field");
      e.preventDefault();
      return;
    }
  };

  return (
    <NavLink
      onClick={(e) => handleClick(e)}
      to={path}
      className={({ isActive }) =>
        isActive
          ? "border-b p-3 cursor-pointer  bg-slate-200"
          : "border-b p-3 cursor-pointer "
      }
    >
      {name}
    </NavLink>
  );
};

export default NavigationLink;
