import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectAllVideos } from "../../../../redux/allVideosSlice";
import { Box, Button, Grid, Slider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoItem from "../LogoItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const ShowAllVideos = () => {
  const user = useSelector((state) => state.user);
  const videos = useSelector(selectAllVideos);

  const [size, setSize] = useState(150);
  console.log(videos);
  const navigate = useNavigate();
  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  };

  return (
    <Box className="w-11/12 m-auto">
      <div className="flex justify-between mb-5">
        <Button
          onClick={() =>
            navigate(`/${user.profile.role}panel/resources/video/addnewvideo`)
          }
        >
          Add New <AddCircleIcon />
        </Button>

        <Slider
          value={size}
          onChange={handleSizeChange}
          aria-labelledby="image-size-slider"
          min={100}
          max={300}
          valueLabelDisplay="auto"
          sx={{ width: 300 }} // Set the width to 300px
        />
      </div>
      <Grid container spacing={2}>
        {videos.map((video, index) => (
          <LogoItem
            key={video.info.videoId}
            size={size}
            src={video?.file?.url?.image}
            item={video}
            type={"video"}
            id={video?.info?.videoId}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default ShowAllVideos;
