import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const notificationsAdapter = createEntityAdapter();

const initialState = notificationsAdapter.getInitialState();

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotifications: notificationsAdapter.setAll,
    updateNotification: notificationsAdapter.updateOne,
  },
});

export const { addNotifications, updateNotification } =
  notificationsSlice.actions;
export const getAllNotifications = (state) =>
  notificationsAdapter
    ?.getSelectors()
    ?.selectAll(state.notifications)
    ?.sort((a, b) => b.id - a.id);

export const getNotificationById = (state, id) =>
  notificationsAdapter.getSelectors().selectById(state.notifications, id);

//   export const getNotificationEntities = (state) =>
//     notificationsAdapter.getSelectors().selectEntities(state.notifications);

//   export const getNotificationsByIds = createSelector(
//     [getNotificationEntities, (state, ids) => ids],
//     (entities, ids) => ids.map((id) => entities[id])
//   );
export default notificationsSlice.reducer;
