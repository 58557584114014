import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getAllGroups } from "../../../../redux/groupsSlice";
import LoadingButton from "../../../../shared/comp/LoadingButton";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../../redux/usersSlice";
import { toast } from "react-toastify";
import { editMajorInDb } from "../../../../firebase.config";

const SelectMultipleMajors = () => {
  const { uid } = useParams();
  const user = useSelector((state) => getUserById(state, uid));
  const [majors, setMajors] = useState(
    user?.profile?.majors || [user?.profile?.groupCode]
  ); // Local state for selected groups
  const groups = useSelector(getAllGroups);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setMajors(e.target.value); // `e.target.value` will now be an array when `multiple` is set
  };

  const sendMajors = async () => {
    setLoading(true);
    try {
      await editMajorInDb({ groupCode: user.profile.groupCode, uid, majors });
      toast.success("successfully set majors");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box className="w-96" sx={{ margin: "auto" }}>
      <Typography variant="h6" className="pb-5">
        Choose Member Majors
      </Typography>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="group-select-label">Major</InputLabel>
        <Select
          labelId="group-select-label"
          value={majors} // Local state used here
          onChange={handleChange}
          label="Major"
          multiple // Enable multiple selection
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
              }}
            >
              {selected.map((code) => {
                const selectedGroup = groups.find((group) => group.id === code);
                return (
                  <Box
                    key={code}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "4px",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      backgroundColor: "#f9f9f9",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                      },
                    }}
                  >
                    <Typography variant="body2">
                      {selectedGroup?.id} - {selectedGroup?.englishName}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        >
          {groups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.id + " - " + group.englishName}
            </MenuItem>
          ))}
        </Select>

        {majors.length === 0 && (
          <FormHelperText sx={{ color: "#bf0a30" }}>
            You must specify at least one group code
          </FormHelperText>
        )}
      </FormControl>
      <div onClick={sendMajors}>
        <LoadingButton
          loading={loading}
          disabled={!majors}
          name={"register majors"}
        />
      </div>
    </Box>
  );
};

export default SelectMultipleMajors;
