import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkIfStoragePathExists } from "../../../../firebase.config";
import { Button, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const InputImageStorage = ({ path, getImageUrl }) => {
  const [imagePath, setImagePath] = useState("");
  const [inputKey, setInputKey] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [progressBarContent, setProgressBarContent] = useState("");
  const storage = getStorage();

  const checkIfFileExists = useCallback(checkIfStoragePathExists, [storage]);

  const storeFile = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBarContent(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          toast("file successfully uploaded to storage");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  // "webp", "img", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "gif", "avif"

  const onInputFile = async (e) => {
    if (!e.target.files.length) return;
    // if (e.target.files[0].size > 500000) {
    //     toast.error("your image maximum allowed size is 500kb")
    //     return
    // }

    // const prePathImage = unique ? path : path + "/" + e.target.files[0].name;
    setSelectedFile(e.target.files[0]);
    const urlExists = await checkIfFileExists(path);

    if (!imagePath && e.target.files[0]) {
      if (urlExists) {
        if (
          window.confirm(
            "File with this name already exists, are you sure to overwrite the file?"
          )
        ) {
          setImagePath(path);
          setSelectedFile(e.target.files[0]);
        } else {
          setInputKey((prev) => !prev);
          return;
        }
      } else {
        setImagePath(path);
        setSelectedFile(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    if (!selectedFile || !imagePath) return;
    const saveFile = async () => {
      const url = await storeFile(selectedFile);
      getImageUrl({ url, path });
    };

    saveFile();
  }, [selectedFile, imagePath]);

  const removeOneSelectedFile = () => {
    const storageRef = ref(storage, path);
    deleteObject(storageRef)
      .then(() => {
        toast("file deleted successfully");
        setSelectedFile("");
        setImagePath("");
        getImageUrl({ url: "", path: "" });
        setProgressBarContent("");
        setInputKey((prev) => !prev);
      })
      .catch((error) => {
        toast(error);
      });
  };

  return (
    <div className="flex justify-start items-center">
      {/* <span className="w-44">Feature Image </span> */}

      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        size="small"
        sx={{ fontWeight: "900" }}
      >
        <CloudUploadIcon sx={{ fontSize: "40px" }} className="text-cu-blue" />
        <VisuallyHiddenInput
          type="file"
          onChange={onInputFile}
          accept={"image/*"}
          multiple={false}
        />
        <span className="ms-4 text-cu-blue">Upload Image</span>
      </Button>
      {selectedFile && (
        <div className="">
          {progressBarContent === 100 && (
            <span
              style={{ cursor: "pointer" }}
              className="text-red-600"
              onClick={(e) => removeOneSelectedFile()}
            >
              X
            </span>
          )}

          <img
            className="border"
            src={URL.createObjectURL(selectedFile)}
            width={200}
            height={200}
            alt={selectedFile?.name}
          />
          <div
            className={`w-full rounded-full bg-slate-200 mt-2 ${
              !progressBarContent ? "hidden" : "block"
            } }`}
          >
            <div
              className={` text-center bg-blue-500 rounded-full ${
                progressBarContent === 100 && "bg-green-500"
              }`}
              style={{ width: `${progressBarContent}%` }}
            >
              {Math.floor(progressBarContent)}%
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputImageStorage;
