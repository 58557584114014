import React from "react";
import { Container, Typography, Box } from "@mui/material";

const About = () => {
  return (
    <Box sx={{ bgcolor: "white", minHeight: "100vh", py: 4 }}>
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          About Cyrus University
        </Typography>
        <Typography variant="body1" paragraph>
          Cyrus University is a Non-profit Corporation – CA – Public Benefit At
          Cyrus University, we deliver more than just education; we shape
          futures through our one-of-a-kind courses that are internationally
          accessible online via remote education. Western luminaries identify
          the culture and history of ancient Persia with Cyrus the Great and his
          over 2,500 year-old cylinder that encapsulates the oldest human rights
          declaration known to man. About 1000 years ago, the Persian poet,
          Ferdowsi, composed “Shahnameh” (Kings book”) to save the Persian
          language and culture. The Odyssey in that book has created a Persian
          culture and language that has continued to this day. This outstanding
          history has inspired the creation of Cyrus University to deliver
          high-quality education in science and engineering, combined with the
          history of Persia to educate young generations all over the world.
          Cyrus University offers rare knowledge about Persian heritage, history
          and language from our numerous experts qualified in many diverse
          fields.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Who We Are
        </Typography>
        <Typography variant="body1" paragraph>
          At the moment Cyrus University is an unaccredited institution of
          higher education. Founded on the principles of excellence and
          commitment, Cyrus University is a non-religious institution focused
          exclusively on delivering special certification programs.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Our Vision
        </Typography>
        <Typography variant="body1" paragraph>
          To empower individuals with the skills, knowledge, and credentials to
          thrive in the rapidly evolving world of networking. Through our Cisco
          certification programs, we aim to provide our students with the
          resources they require to achieve their individual career objectives
          and knowledge in the tech industry.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Our Programs
        </Typography>
        <Typography variant="body1" paragraph>
          We offer a wide range of undergraduate and graduate programs in
          various fields, including Business, Technology, Health Sciences, and
          more. Our courses are designed to be flexible and accessible, allowing
          students to learn at their own pace.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Our Mission:
        </Typography>
        <Typography variant="body1" paragraph>
          To create a university that recognizes and values each student’s
          direction and growth through remote education and online learning,
          while providing an environment that guarantees a clear path to success
          and a solid foundation of knowledge.
        </Typography>
        {/* <Typography variant="h5" component="h2" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          For more information about our programs and admissions, please contact
          us at [email address] or call [phone number].
        </Typography> */}
        <Typography variant="body1" paragraph>
          Version : {process.env.REACT_APP_VERSION}
        </Typography>
      </Container>
    </Box>
  );
};

export default About;
