import React from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import cyrusUniversityLogo from "../images/logoTextWhite.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ViewFile = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null;
  const modifiedChildren = React.cloneElement(children, {
    // Add or modify props here
    additionalProp: "newValue",
  });
  return ReactDOM.createPortal(
    <div className="top-0 left-0 w-full h-full bg-white fixed z-30 flex flex-col overflow-auto">
      <div className="sticky top-0 text-white left-0 flex justify-between h-14 border-b-2 border-b-slate-100 bg-cu-blue z-40">
        <div className="flex items-center gap-5 ms-12 ">
          <img src={cyrusUniversityLogo} alt="logo" className="contain h-10" />
          <Typography variant="h5" className="text-slate-300">
            |
          </Typography>
          <Typography variant="h5">{title}</Typography>
        </div>
        <Button onClick={onClose}>
          <CloseIcon sx={{ fontSize: 35 }} className="text-cu-red" />
        </Button>
      </div>

      <div
        className="m-auto w-full h-full-minus-14 "
        // style={{ width: "85%", height: "85%" }}
      >
        {modifiedChildren}
      </div>
    </div>,
    document.getElementById("fileViewer")
  );
};

export default ViewFile;
