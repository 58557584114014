import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getAllGroups } from "../../../../redux/groupsSlice";
import { styled } from "@mui/system"; // or import { styled } from "@emotion/styled";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    whiteSpace: "normal",
  },
});

export default function SelectMultipleGroupCode({ groupCode, setGroupCode }) {
  const groups = useSelector(getAllGroups);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setGroupCode(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-checkbox-label">Group Code</InputLabel>
      <StyledSelect
        labelId="multiple-checkbox-label"
        label="Group Code"
        multiple
        value={groupCode}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {groups.map((group) => (
          <MenuItem key={group.englishName} value={group.id}>
            <Checkbox checked={groupCode.indexOf(group.id) > -1} />
            <ListItemText primary={group.id + "-" + group.englishName} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}
