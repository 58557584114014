import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { createAAssignment } from "../../../firebase.config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAssignmentById } from "../../../redux/assignmentsSlice";
import LessonAssign from "./LessonAssign";
import GroupCodeSection from "../sharedComp/GroupCodeSection";
import ShowAddedResources from "../sharedComp/addResource/ShowAddedResources";
import TextAreaComponent from "../sharedComp/TextAreaComponent";
import { serverTimestamp } from "firebase/database";

const EditAssignment = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { assignmentId } = useParams();
  let assignment = useSelector((state) =>
    getAssignmentById(state, assignmentId)
  );
  const [groupCode, setGroupCode] = useState(
    assignment?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "01")
  );

  const [inputDirection, setInputDirection] = useState(
    assignment?.info?.titleDirection || "rtl"
  );

  const creatorEmail = useSelector((state) => state.user.profile.email);

  // const [visibility, setVisbility] = useState(assignment.info.visibility);
  const [title, setTitle] = useState(assignment?.info?.title);
  const [explain, setExplain] = useState(assignment.info.explain);
  const [name, setName] = useState(assignment?.info?.name || "");
  const [nameDirection, setNameDirection] = useState(
    assignment?.info?.nameDirection || "rtl"
  );

  const publishAssignment = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    const assignmentToSend = {
      ...assignment,
      info: {
        assignmentId,
        // visibility,
        title,
        explain,
        editAt: serverTimestamp(),
        createAt: assignment.info.createAt,
        assignmentCreator: creatorEmail,
        status: "published",
        titleDirection: inputDirection,
        creatorId: user.profile.uid,
        groupCode: user.profile.groupCode || groupCode,
        name,
        nameDirection,
      },
    };
    await createAAssignment(assignmentToSend);
    navigate(`/${user.profile.role}panel/assignments`);
    toast.success("successfully add new assignment");
  };

  return (
    <div className="flex ">
      <div className="w-9/12 mx-5 mt-5">
        <div className="mb-3 flex justify-between">
          <h2>Edit Assignment</h2>
          <h2>{assignmentId}</h2>
        </div>
        <TextAreaComponent
          props={{
            explain,
            setTitle,
            title,
            inputDirection,
            setInputDirection,
            setExplain,
            groupCode,
            name,
            nameDirection,
            setName,
            setNameDirection,
          }}
        />
        <ShowAddedResources
          section={assignment}
          sectionName={"assignments"}
          sectionId={assignmentId}
          status={assignment.info.status}
          groupCode={assignment.info.groupCode}
        />
      </div>

      <div className="w-2/12 mt-12 flex flex-col gap-y-5">
        {user.profile.role === "admin" && (
          <GroupCodeSection groupCode={groupCode} setGroupCode={setGroupCode} />
        )}
        <div className="p-5 border flex flex-col gap-y-2">
          <LessonAssign assignmentId={assignmentId} />
        </div>

        <button className="border w-24 self-end" onClick={publishAssignment}>
          Publish
        </button>
      </div>
    </div>
  );
};

export default EditAssignment;
