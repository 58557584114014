import React, { useState } from "react";
import { Grid, Slider, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAllPdfs } from "../../../../redux/allPdfsSlice";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LogoItem from "../LogoItem";

const ShowAllPdf = () => {
  const [size, setSize] = useState(100);
  const user = useSelector((state) => state.user);
  const pdfs = useSelector(selectAllPdfs);
  const navigate = useNavigate();
  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  };

  return (
    <Box className="w-11/12 m-auto">
      <div className="flex justify-between mb-5">
        <Button
          onClick={() =>
            navigate(`/${user.profile.role}panel/resources/pdf/addnewpdf`)
          }
        >
          Add New <AddCircleIcon />
        </Button>

        <Slider
          value={size}
          onChange={handleSizeChange}
          aria-labelledby="image-size-slider"
          min={100}
          max={300}
          valueLabelDisplay="auto"
          sx={{ width: 300 }} // Set the width to 300px
        />
      </div>
      <Grid container spacing={2}>
        {pdfs.map((pdf) => (
          <LogoItem
            key={pdf.info.pdfId}
            size={size}
            src={pdf.file.url.image}
            item={pdf}
            type={"pdf"}
            id={pdf.info.pdfId}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default ShowAllPdf;
