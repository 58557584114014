import React from "react";
import { ListItemIcon, ListItemText } from "@mui/material";
import { SelectedListItemButton } from "./StyledComponent";

const SidebarItem = ({ selected, onClick, icon, text }) => {
  return (
    <SelectedListItemButton selected={selected} onClick={onClick}>
      <ListItemIcon sx={{ color: "white" }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </SelectedListItemButton>
  );
};

export default SidebarItem;
