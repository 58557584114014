import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";

const NotificationType = ({ notificationAlert, setNotificationAlert }) => {
  const [typeOpen, setTypeOpen] = useState(false);

  return (
    <div className="mt-5 ms-2">
      <FormControl component="fieldset" className="ps-2">
        <Button
          onClick={() => {
            setTypeOpen((prev) => !prev);
          }}
        >
          Type: {notificationAlert} {typeOpen ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={typeOpen}>
          <RadioGroup
            column
            value={notificationAlert}
            onChange={(e) => {
              setNotificationAlert(e.target.value);
            }}
          >
            <FormControlLabel
              value="success"
              control={<Radio />}
              label={"success"}
            />
            <FormControlLabel
              value="warning"
              control={<Radio />}
              label={"warning"}
            />
            <FormControlLabel value="info" control={<Radio />} label={"info"} />
            <FormControlLabel
              value="error"
              control={<Radio />}
              label={"error"}
            />
          </RadioGroup>
        </Collapse>
      </FormControl>
    </div>
  );
};

export default NotificationType;
