import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const imageAdaptor = createEntityAdapter();

const initialState = imageAdaptor.getInitialState({
  loading: false,
  error: null,
});

const allImageSlice = createSlice({
  name: "allImages",
  initialState,
  reducers: {
    setImages: imageAdaptor.setAll,
  },
  setImageLoading: (state, action) => {
    state.loading = action.payload;
  },
  setAllImageError: (state, action) => {
    state.error = action.payload;
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchImages.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(fetchImages.fulfilled, (state, action) => {
  //       state.loading = false;
  //       imageAdaptor.setAll(state, action.payload);
  //     })
  //     .addCase(fetchImages.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
});

export const { setImages, setImageLoading, setAllImageError } =
  allImageSlice.actions;

export const selectAllImages = (state) =>
  imageAdaptor
    .getSelectors()
    .selectAll(state.allImages)
    .sort((a, b) => b.info.createAt - a.info.createAt);
// .sort((a, b) => b.info.name.localeCompare(a.info.name));
export const selectImageById = (state, imageId) =>
  imageAdaptor.getSelectors().selectById(state.allImages, imageId);
export default allImageSlice.reducer;
