import CircularProgress from "@mui/material/CircularProgress";

import { useState, useEffect } from "react";

import { getUrlBlob } from "../../../../firebase.config";
import ShowModalGroupButton from "../../sharedComp/ShowModalGroupButton";

const PdfShow = ({ pdf }) => {
  const [blob, setBlob] = useState("");
  const [progress, setProgress] = useState(0);
  console.log(progress);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getBlob = async () => {
      const bbb = await getUrlBlob(pdf.file.path.pdf, signal, setProgress);
      setBlob(bbb);
    };
    getBlob();
    return () => {
      controller.abort();
    };
  }, [pdf.file.path.pdf]);

  return (
    <div className="flex flex-col w-full h-full z-20">
      {blob ? (
        <embed type="application/pdf" src={blob} className="w-full h-full" />
      ) : (
        <div className="m-auto">
          <CircularProgress disableShrink sx={{ color: "#002868" }} />
        </div>
      )}
      <div className="border-t-2 w-full flex items-center justify-center">
        <ShowModalGroupButton
          itemId={pdf?.info?.pdfId}
          type={"pdf"}
          itemCreatorId={pdf?.info?.creatorId}
          groupCode={pdf?.info?.groupCode}
        />
      </div>
    </div>
  );
};

export default PdfShow;
