import React from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const semesters = Array.from({ length: 8 }, (_, i) => i + 1);

const EachCourseSem = ({
  course,
  semesterSelections,
  setSemesterSelections,
}) => {
  const isChecked = (courseId) => Boolean(semesterSelections[courseId]);

  const toggleCourseCheck = (course) => {
    setSemesterSelections((prevSelections) => {
      const courseId = course.info.courseId;
      if (prevSelections[courseId]) {
        // Uncheck course: remove from selections
        const updatedSelections = { ...prevSelections };
        delete updatedSelections[courseId];
        return updatedSelections;
      } else {
        // Check course: add to selections with initial semester value
        return {
          ...prevSelections,
          [courseId]: { groupCode: course.info.groupCode, suggestedSem: "" },
        };
      }
    });
  };

  const handleSemesterChange = (courseId, semester) => {
    setSemesterSelections((prev) => ({
      ...prev,
      [courseId]: { ...prev[courseId], suggestedSem: semester },
    }));
  };

  return (
    <Box className="flex h-12 border-b items-center px-4 hover:bg-gray-100">
      {/* Image */}
      <Box className="w-1/12">
        <Avatar
          src={course.files.courseImage.url}
          alt={course.info.title}
          variant="rounded"
          className="h-10 w-10"
        />
      </Box>

      {/* Group Code */}
      <Box className="w-1/12">
        <Typography variant="body2">{course.info.groupCode}</Typography>
      </Box>
      <Box className="w-2/12">
        <Typography variant="body2">{course.info.title}</Typography>
      </Box>

      {/* Name */}
      <Box className="w-2/12">
        <Typography variant="body2">{course.info.name}</Typography>
      </Box>

      {/* Categories */}
      <Box className="w-2/12">
        <Typography variant="body2">{course.categoriesName}</Typography>
      </Box>

      {/* Semester Dropdown */}
      <Box className="w-2/12 text-center">
        <Select
          value={
            isChecked(course.info.courseId)
              ? semesterSelections[course.info.courseId].suggestedSem
              : ""
          }
          onChange={(e) =>
            handleSemesterChange(course.info.courseId, e.target.value)
          }
          displayEmpty
          size="small"
          disabled={!isChecked(course.info.courseId)}
          className="w-full"
        >
          <MenuItem value="" disabled>
            Choose Semester
          </MenuItem>
          {semesters.map((sem) => (
            <MenuItem key={sem} value={sem}>
              Semester {sem}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Toggle Button */}
      <Box className="w-2/12 text-center">
        <Button
          variant="contained"
          color={isChecked(course.info.courseId) ? "success" : "primary"}
          onClick={() => toggleCourseCheck(course)}
          size="small"
          className="capitalize"
          startIcon={
            isChecked(course.info.courseId) ? <CheckIcon /> : <CloseIcon />
          }
        >
          {isChecked(course.info.courseId) ? "Checked" : "Check"}
        </Button>
      </Box>
    </Box>
  );
};

export default EachCourseSem;
