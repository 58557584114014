import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsOfService = () => {
  return (
    <Box sx={{ bgcolor: "white", minHeight: "100vh", py: 4 }}>
      {/* <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to [University Name]. By accessing our website and using our
          services, you agree to comply with and be bound by the following terms
          and conditions.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          2. Use of Services
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to use our services only for lawful purposes and in
          accordance with these terms. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          3. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content on our website, including text, graphics, logos, and
          software, is the property of [University Name] and is protected by
          copyright and other intellectual property laws.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          4. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          [University Name] is not liable for any direct, indirect, incidental,
          or consequential damages arising from the use of our services.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          5. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify these terms at any time. Any changes
          will be posted on this page, and your continued use of our services
          constitutes acceptance of the modified terms.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          6. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, please contact us at
          [email address].
        </Typography>
      </Container> */}
    </Box>
  );
};

export default TermsOfService;
