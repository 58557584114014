import React, { useEffect, useRef, useState } from "react";
import ViewFile from "../../../layout/ViewFile";

const Library = () => {
  const iframeRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };
  const openModal = () => {
    document.body.style.overflowY = "hidden";
    setIsModalOpen(true);
  };

  useEffect(() => {
    openModal();
  }, []);
  return (
    <div>
      {/* <button onClick={openModal}>open model</button> */}
      <ViewFile isOpen={isModalOpen} onClose={closeModal} title={"Library"}>
        <iframe
          title="pubmed"
          ref={iframeRef}
          src="https://fliphtml5.com/bookcase/rvgqu/"
          // style={{ height: "100vh", width: "100vw" }}
          className="m-auto w-full h-full"
        ></iframe>
      </ViewFile>
    </div>
  );
};

export default Library;
