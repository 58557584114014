import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SchoolIcon from "@mui/icons-material/School";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ListItemButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesByIds } from "../../../redux/coursesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPenNib } from "@fortawesome/free-solid-svg-icons";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import BiotechIcon from "@mui/icons-material/Biotech";
import TranslateIcon from "@mui/icons-material/Translate";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import EachAssignmentSidebar from "./EachAssignmentSidebar";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { color, styled } from "@mui/system";
import cyrusUniLogo from "../../../images/logoTextWhite.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const SelectedListItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: "#002868",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#003a99",
  },
  "&.Mui-selected": {
    color: "#bf0a30",
    backgroundColor: "#003a99",
    "&:hover": {
      backgroundColor: "#004bb3",
    },
  },
}));

const SmallListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "30px", // Adjust the size as needed
  "& .MuiSvgIcon-root": {
    fontSize: "30px", // Adjust the icon size as needed
  },
}));
const ListContainer = styled(List)(({ theme }) => ({
  height: "100vh",
  backgroundColor: "#002868",
  color: "#fff",
}));
const StudentSideBar = () => {
  const [toolsOpen, setToolsOpen] = useState(false);
  const [toolsIndex, setToolsIndex] = useState(null);
  const [courseIndex, setCourseIndex] = useState(0);
  const [mainNavIndex, setMainNavIndex] = useState(0);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);
  const [nestedAssignment, setNestedAssignment] = useState(false);
  const [coursesId, setCoursesId] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme?.breakpoints?.down("sm"));
  const courses = useSelector((state) => getCoursesByIds(state, coursesId));
  // const courses = useSelector((state) => state.courseAndLessonStu?.entities);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  useEffect(() => {
    user.assignedCourses && setCoursesId(Object.keys(user.assignedCourses));
  }, [user.assignedCourses]);

  const handleToolsIndex = (index, path) => {
    setToolsIndex(index);
    navigate(path);
  };

  const handleCourseOpen = () => {
    handleMainNavIndex(2, "/studentpanel/courses");
    setNestedOpen(!nestedOpen);
    // if (!nestedOpen) {
    //   navigate("courses");
    // }
  };

  const handleAssignmentOpen = () => {
    handleMainNavIndex(3, "/studentpanel");
    setNestedAssignment(!nestedAssignment);
    // navigate("/studentpanel/courses");
  };

  const handleMainNavIndex = (index, path) => {
    setMainNavIndex(index);
    navigate(path);
  };
  const handleToolsOpen = () => {
    handleMainNavIndex(4, "/studentpanel");

    setToolsOpen((prev) => !prev);
  };

  const openCourse = (course, index) => {
    setCourseIndex(index);
    navigate(`courses/viewcourse/${course?.id}`);
  };

  console.log(user);

  return (
    // <div className=" w-52 pt-5 overflow-auto bg-slate-200 min-h-screen">
    //   <div className="absolute flex flex-col gap-y-6 ps-2 py-2 items-start">
    //     <button onClick={() => navigate("/studentpanel/profile")}>
    //       profile
    //     </button>
    //     <button onClick={openCoursePanel}>Courses</button>
    //   </div>
    // </div>
    <div className={`${isSmallScreen ? "z-100" : "z-0"}`}>
      {isSmallScreen && (
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      )}
      <div></div>
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={open}
        onClose={toggleDrawer(false)}
        // anchor="top"
        // classes={{ paper: "mt-12 pb-12" }} // Adjust the margin-top to match the height of your header
        className="w-72 "
        sx={{ "& .MuiDrawer-paper": { border: 0 } }}
      >
        <div className="z-20 fixed w-72 h-32 bg-cu-blue flex justify-center items-center">
          <img
            onClick={() => navigate("/")}
            src={cyrusUniLogo}
            alt="cyrusuni logo"
            className="object-contain h-16 cursor-pointer justify-self-start"
          />
        </div>
        <ListContainer
          className="w-72 relative top-32 overflow-auto"
          style={{ height: "calc(100vh - 128px)" }}
        >
          <SelectedListItemButton
            selected={mainNavIndex === 0}
            onClick={() => handleMainNavIndex(0, "/studentpanel/profile")}
          >
            <ListItemIcon>
              <AccountCircleIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </SelectedListItemButton>

          <SelectedListItemButton
            selected={mainNavIndex === 5}
            onClick={() => handleMainNavIndex(5, "/studentpanel/groups")}
          >
            <ListItemIcon>
              <AccountCircleIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Majors" />
          </SelectedListItemButton>

          <SelectedListItemButton
            selected={mainNavIndex === 1}
            onClick={() => handleMainNavIndex(1, "/studentpanel/reportcard")}
          >
            <ListItemIcon>
              <AssessmentIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="report card" />
          </SelectedListItemButton>

          <SelectedListItemButton
            selected={mainNavIndex === 2}
            onClick={handleCourseOpen}
          >
            <ListItemIcon>
              <SchoolIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Courses" />
            {nestedOpen ? <ExpandLess /> : <ExpandMore />}
          </SelectedListItemButton>
          <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {courses &&
                Object.values(courses).map((course, index) => (
                  <SelectedListItemButton
                    selected={courseIndex === index}
                    onClick={() => openCourse(course, index)}
                  >
                    <SmallListItemIcon style={{ width: "10px !important" }}>
                      {courseIndex === index && (
                        <ArrowRightIcon sx={{ color: "white" }} />
                      )}
                    </SmallListItemIcon>
                    <ListItemText> {course?.info.title}</ListItemText>
                  </SelectedListItemButton>
                ))}
            </List>
          </Collapse>

          {/* <SelectedListItemButton
            selected={mainNavIndex === 3}
            onClick={handleAssignmentOpen}
          >
            <ListItemIcon>
              <SchoolIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Assignments" />
            {nestedAssignment ? <ExpandLess /> : <ExpandMore />}
          </SelectedListItemButton>
          <Collapse in={nestedAssignment} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {Object.values(courses).map((course) => (
                <EachAssignmentSidebar course={course} />
              ))}
            </List>
          </Collapse> */}

          <SelectedListItemButton
            selected={mainNavIndex === 7}
            onClick={() => handleMainNavIndex(7, "/studentpanel/library")}
          >
            <ListItemIcon>
              <LibraryBooksIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Library" />
          </SelectedListItemButton>

          <SelectedListItemButton
            onClick={handleToolsOpen}
            selected={mainNavIndex === 4}
            // classes={{ selected: classes.selected }}
          >
            <ListItemIcon>
              <HistoryEduIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Tools" />
            {toolsOpen ? <ExpandLess /> : <ExpandMore />}
          </SelectedListItemButton>
          <Collapse in={toolsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SelectedListItemButton
                selected={toolsIndex === 0}
                onClick={() =>
                  handleToolsIndex(0, "/studentpanel/tools/shahname")
                }
              >
                <Button
                  fullWidth
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "white",
                  }}
                >
                  <FontAwesomeIcon icon={faPenNib} />
                  Shahname
                </Button>
              </SelectedListItemButton>
              <SelectedListItemButton
                selected={toolsIndex === 1}
                onClick={() =>
                  handleToolsIndex(1, "/studentpanel/tools/parsiban")
                }
              >
                <Button
                  fullWidth
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "white",
                  }}
                >
                  <TranslateIcon />
                  Parsiban Dictionary
                </Button>
              </SelectedListItemButton>
              <SelectedListItemButton
                selected={toolsIndex === 2}
                onClick={() =>
                  handleToolsIndex(2, "/studentpanel/tools/pubmed")
                }
              >
                <Button
                  fullWidth
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "white",
                  }}
                >
                  <BiotechIcon />
                  Pubmed
                </Button>
              </SelectedListItemButton>
              <SelectedListItemButton
                selected={toolsIndex === 3}
                onClick={() =>
                  handleToolsIndex(3, "/studentpanel/tools/avestakb")
                }
              >
                <Button
                  fullWidth
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "white",
                  }}
                >
                  <KeyboardIcon />
                  Avesta keyboard
                </Button>
              </SelectedListItemButton>
            </List>
          </Collapse>
        </ListContainer>
      </Drawer>
    </div>
  );
};

export default StudentSideBar;
