import React, { useEffect, useState } from "react";
import EachCourseAssign from "./EachCourseAssign";
import { toast } from "react-toastify";
import { assignStudentToCourse } from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssignedCourses,
  getAllUsers,
  getUsersByGroupcode,
} from "../../../redux/usersSlice";
import { getAllCourses } from "../../../redux/coursesSlice";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";

const CourseAssign = () => {
  // const [courseName, setCourseName] = useState("");

  const user = useSelector((state) => state.user);
  const [selectedUser, setSelectedUser] = useState("");
  const users = useSelector(getAllUsers);
  const filteredUsers =
    user.profile.role === "admin"
      ? users
      : user.profile.role === "leadInstructor"
      ? users.filter((u) => u.profile.role === "student")
      : [];

  const courses = useSelector(getAllCourses);
  const filteredCourses =
    user.profile.role === "admin"
      ? courses
      : user.profile.role === "leadInstructor"
      ? courses.filter((c) => c.info.groupCode === user.profile.groupCode)
      : [];

  const changeUser = (e) => {
    const user = users.find((user) => user.profile.uid === e.target.value);
    setSelectedUser(user);
  };

  // useEffect(() => {
  //   const filteredCourses =
  //   user.profile.role === "admin"
  //     ? allCourses
  //     : user.profile.role === "leadInstructor"
  //     ? courses.filter((c) => c.info.groupCode === user.profile.groupCode)
  //         : [];
  //   setCourses(filteredCourses)
  // },[allCourses, courses, user.profile.groupCode, user.profile.role])

  // const studentAddToCourse = async () => {
  //   try {
  //     await assignStudentToCourse({
  //       uid: selectedUser.profile.uid,
  //       // email: selectedUser.profile.email,
  //       list,
  //       assignedCourses,
  //       role: selectedUser.profile.role,
  //       groupCode: selectedUser.profile.groupCode,
  //     });
  //     toast.success("course assigned successfully");
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };
  console.log(filteredCourses);
  return (
    <div className="mt-10 w-9/12 m-auto">
      <FormControl fullWidth>
        <InputLabel id="user-select-label">Select User</InputLabel>
        <Select
          labelId="user-select-label"
          onChange={changeUser}
          label="Select User"
          className="mb-10"
        >
          {filteredUsers.map((user, index) => (
            <MenuItem key={index} value={user.profile.uid}>
              {user.profile.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="flex flex-col rounded-lg">
        {selectedUser &&
          filteredCourses
            .filter((course) => course.info.status === "published")
            .map((course, index) => (
              <div
                className={`${index % 2 ? "bg-slate-100" : "bg-slate-200"} p-3`}
              >
                <EachCourseAssign
                  key={course.id}
                  course={course}
                  selectedUser={selectedUser}
                  // assignedCourses={assignedCourses}
                  // course={course}
                  // uid={selectedUser.profile.uid}
                />
              </div>
            ))}
      </div>
      {/* {selectedUser && courses?.length ? (
        <div className="mt-10 flex justify-center">
          <Button onClick={studentAddToCourse}>update user courses</Button>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default CourseAssign;

// {editAssignedCourses ? (
//   <div className="flex flex-col gap-y-3">
//     {/* <TextField
//       type="text"
//       value={courseName}
//       onChange={(e) => setCourseName(e.target.value)}
//       fullWidth
//     /> */}
//     <Box className="border rounded-md p-2 h-48 overflow-auto flex flex-col gap-y-2">
//       <Box className="flex justify-between border-b pb-3">
//         <Typography className="me-8">Id</Typography>
//         <Typography>Title</Typography>
//         <Typography></Typography>
//       </Box>
//       {courses.map((course) => (
//         <EachCourseAssign
//           key={course.id}
//           assignedCourses={assignedCourses}
//           course={course}
//           changeList={changeList}
//         />
//       ))}
//     </Box>
//     {/* <Button className="mycu-btnBlue text-xs" onClick={assignToCourse}>
//       Assign
//     </Button> */}
//     <Box className="flex justify-around">
//       <Button onClick={() => setEditAssignedCourses(false)}>
//         Cancel
//       </Button>
//       <Button onClick={studentAddToCourse}>Ok</Button>
//     </Box>
//   </div>
// ) : (
//   <div className="flex flex-col gap-y-3">
//     <Box className="flex justify-between border-b">
//       <Typography className="me-8">Id</Typography>
//       <Typography>Title</Typography>
//     </Box>
//     <Box>
//       {assignedCourses?.length ? (
//         assignedCourses?.map((courseId) => (
//           <EachCourseDemo courseId={courseId} />
//         ))
//       ) : (
//         <Typography className="text-center">
//           there isn't any assigned courses
//         </Typography>
//       )}
//     </Box>
//     <Button
//       className="mycu-btnBlue text-xs"
//       onClick={() => setEditAssignedCourses(true)}
//     >
//       Assign Courses
//     </Button>
//   </div>
// )}
