import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { set, update, ref as realRef } from "firebase/database";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkIfStoragePathExists, db } from "../../../../firebase.config";

const InputImageComp = ({ path, getImageUrl, unique, dbPath }) => {
  const [imagePath, setImagePath] = useState("");
  const [inputKey, setInputKey] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [progressBarContent, setProgressBarContent] = useState("");
  const storage = getStorage();
  const checkIfFileExists = useCallback(checkIfStoragePathExists, [storage]);

  const storeFile = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBarContent(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          toast("file successfully uploaded to storage");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  // "webp", "img", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "gif", "avif"

  const onInputFile = async (e) => {
    if (!e.target.files.length) return;
    // if (e.target.files[0].size > 500000) {
    //     toast.error("your image maximum allowed size is 500kb")
    //     return
    // }

    const prePathImage = unique ? path : path + "/" + e.target.files[0].name;
    setSelectedFile(e.target.files[0]);
    const urlExists = await checkIfFileExists(prePathImage);

    if (!imagePath && e.target.files[0]) {
      if (urlExists) {
        if (
          window.confirm(
            "File with this name already exists, are you sure to overwrite the file?"
          )
        ) {
          setImagePath(prePathImage);
          setSelectedFile(e.target.files[0]);
        } else {
          setInputKey((prev) => !prev);
          return;
        }
      } else {
        setImagePath(prePathImage);
        setSelectedFile(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    if (!selectedFile || !imagePath) return;
    const saveFile = async () => {
      const url = await storeFile(selectedFile);
      const docRef = unique
        ? realRef(db, dbPath)
        : realRef(db, dbPath + selectedFile.name);
      await set(docRef, {
        url,
        path: imagePath,
      });
      //   const response = await uploadPicture();
      getImageUrl({ url, path });
    };

    saveFile();
  }, [selectedFile, imagePath]);

  const removeOneSelectedFile = () => {
    const storageRef = ref(storage, path);
    deleteObject(storageRef)
      .then(() => {
        toast("file deleted successfully");
        const docRef = unique
          ? realRef(db, dbPath)
          : realRef(db, dbPath + selectedFile.name);
        set(docRef, {
          path: "",
          url: "",
        });
        setSelectedFile("");
        setImagePath("");
        getImageUrl({ url: "", path: "" });
        setProgressBarContent("");
        setInputKey((prev) => !prev);
      })
      .catch((error) => {
        toast(error);
      });
  };

  return (
    <div className="flex flex-col items-center border p-3">
      <span className="text-start w-full ps-1 pb-3 text-green-600">
        IMAGE :{" "}
      </span>
      <input
        key={inputKey}
        disabled={!path}
        type="file"
        accept={"image/*"}
        onChange={onInputFile}
      />
      {selectedFile && (
        <div className="m-auto text-center">
          {progressBarContent === 100 && (
            <span
              style={{ cursor: "pointer" }}
              className="text-red-600"
              onClick={(e) => removeOneSelectedFile()}
            >
              X
            </span>
          )}

          <img
            className="border"
            src={URL.createObjectURL(selectedFile)}
            width={200}
            height={200}
            alt={selectedFile?.name}
          />
          <div
            className={`w-full rounded-full bg-slate-200 mt-2 ${
              !progressBarContent ? "hidden" : "block"
            } }`}
          >
            <div
              className={` text-center bg-blue-500 rounded-full ${
                progressBarContent === 100 && "bg-green-500"
              }`}
              style={{ width: `${progressBarContent}%` }}
            >
              {Math.floor(progressBarContent)}%
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputImageComp;
