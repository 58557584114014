import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const groupsAdapter = createEntityAdapter();

const initialState = groupsAdapter.getInitialState();

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    addgroups: groupsAdapter.setAll,
    // updateImages: (state, { payload }) => {
    //   const { groupId, images } = payload;
    //   const entity = state.entities[groupId];
    //   entity.files.images = images;
    // },
    // updateVideoId: (state, { payload }) => {
    //   const { groupId, video } = payload;
    //   const entity = state.entities[groupId];
    //   entity.files.video = video;
    // },
    updateGroup: groupsAdapter.updateOne,
  },
});

export const { addgroups, updateGroup } = groupsSlice.actions;
export const getAllGroups = createSelector(
  [(state) => state.groups],
  (groupsState) =>
    groupsAdapter
      .getSelectors()
      .selectAll(groupsState)
      .sort((a, b) => a.id - b.id)
);

export const getGroupById = (state, id) =>
  groupsAdapter.getSelectors().selectById(state.groups, id);

//   export const getGroupEntities = (state) =>
//     groupsAdapter.getSelectors().selectEntities(state.groups);

//   export const getGroupsByIds = createSelector(
//     [getGroupEntities, (state, ids) => ids],
//     (entities, ids) => ids.map((id) => entities[id])
//   );
export default groupsSlice.reducer;
