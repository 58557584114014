import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  postVideoDataToDb,
  deleteRealItem,
  deleteStorageRecursively,
} from "../../../../firebase.config";
import { ReactComponent as PostLoadingComp } from "../../../../images/svg/postsvgLoading.svg";
import AddNamesInput from "../../sharedComp/AddNamesInput";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectVideoById } from "../../../../redux/allVideosSlice";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import LoadingButton from "../../../../shared/comp/LoadingButton";
import { v4 as uuidv4 } from "uuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { serverTimestamp } from "firebase/database";
import AddLinkIcon from "@mui/icons-material/AddLink";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddNewVideo = () => {
  const inputRef = useRef();
  const { videoId: paramsVideoId } = useParams();
  const [videoId, setVideoId] = useState(paramsVideoId);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const video = useSelector((state) => selectVideoById(state, videoId));
  const [name, setName] = useState(video?.info?.name || "");
  const [category, setCategory] = useState(video?.info?.category || []);
  const [postLoading, setPostLoading] = useState(false);
  const [explain, setExplain] = useState(video?.info?.explain || "");
  const [authors, setAuthors] = useState(video?.info?.authors || []);
  const [labels, setLabels] = useState(video?.info?.labels || []);
  const [videoUrl, setVideoUrl] = useState(video?.file?.url?.video || "");
  const [imageUrl, setImageUrl] = useState(video?.file?.url?.image || "");
  const [imagePath, setImagePath] = useState(video?.file?.path?.image || "");
  const [videoPath, setVideoPath] = useState(video?.file?.path?.video || "");
  const [videoDuration, setVideoDuration] = useState(
    video?.file?.videoDuration || ""
  );

  const [groupCode, setGroupCode] = useState(
    video?.info?.groupCode ||
      (user.profile.role === "instructor" ||
      user.profile.role === "leadInstructor"
        ? user.profile.groupCode
        : "")
  );
  const videoRef = useRef();
  const canvasRef = useRef();
  const [selectedFrameUrl, setSelectedFrameUrl] = useState(null);
  const [localUrl, setLocalUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [progressBarContent, setProgressBarContent] = useState(0);
  const [imageBlob, setImageBlob] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [link, setLink] = useState(video?.file?.url?.video);
  const storage = getStorage();

  const saveLocalUrl = useCallback(async () => {
    const videoElement = videoRef.current;
    videoElement.src = localUrl;
    videoElement.currentTime = 1;
    videoElement.onloadedmetadata = () => {
      setVideoDuration(videoElement.duration);
    };
    videoElement.onseeked = () => {
      const canvas = canvasRef.current;
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        setImageBlob(blob);
        setSelectedFrameUrl(url);
      }, "image/png");
    };
  }, [localUrl]);

  const storeImageFile = async (videoId) => {
    if (!videoId) return;
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/resources/video/${videoId}/image.png`);
      const uploadTask = uploadBytesResumable(storageRef, imageBlob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("uploading image ...");
        },
        (error) => {
          toast.error(`error in upload image${error}`);
          reject(error);
        },
        () => {
          console.log("image successfully uploaded");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            setImagePath(`/resources/video/${videoId}/image.png`);
            setImageUrl(downloadURL);
            // setImageUrlOnStorage(downloadURL);
          });
        }
      );
    });
  };

  const storeVideoFile = () => {
    if (!videoId) return;
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/resources/video/${videoId}/video.mp4`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBarContent(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            setVideoPath(`/resources/video/${videoId}/video.mp4`);
            setVideoUrl(downloadURL);
          });
        }
      );
    });
  };
  useEffect(() => {
    if (!selectedFile) return;
    saveLocalUrl();
  }, [saveLocalUrl, selectedFile]);

  useEffect(() => {
    if (!videoId) {
      const id = uuidv4();
      setVideoId(id);
    }
  }, [videoId, video]);

  useEffect(() => {
    if (!imageBlob) return;
    const saveDataInDb = async () => {
      try {
        const videoLink = await storeVideoFile();
        const imageLink = await storeImageFile(videoId);
        const videoToUpload = {
          info: {
            videoId,
            name,
            category,
            explain,
            authors,
            labels,
            createAt: serverTimestamp(),
            creatorId: user.profile.uid,
            groupCode,
          },
          file: {
            path: {
              image: imagePath,
              video: videoPath,
            },
            url: { image: imageLink, video: videoLink },
            videoDuration,
          },
          id: videoId,
        };
        await postVideoDataToDb(videoToUpload, groupCode);
        toast.success("successfully upload file");
      } catch (error) {
        console.log(error);
        toast.error("upload failed");
      }
    };
    saveDataInDb();
  }, [imageBlob]);
  // Modal state

  const handleClickOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleOk = () => {
    setVideoUrl(link);
    handleClose();
  };

  const onInputFile = async (e) => {
    if (!e.target.files.length || !videoId) return;
    if (!groupCode) {
      toast.error("specify groupcode first");
      inputRef.current.value = "";
      return;
    }
    const lclUrl = URL.createObjectURL(e.target.files[0]);

    setLocalUrl(lclUrl);
    setSelectedFile(e.target.files[0]);
  };

  const removeOneSelectedFile = async () => {
    if (!videoId || !groupCode) {
      toast.error("id not defined");
      return;
    }
    if (window.confirm("are you want to proceed removing video ?")) {
      try {
        await deleteRealItem(`/resources/video/${videoId}/file`);
        await deleteStorageRecursively(`/resources/video/${videoId}`);
        setVideoUrl("");
        setSelectedFile("");
        setProgressBarContent("");
        setImagePath("");
        setVideoPath("");
        setImageUrl("");
        setVideoDuration("");
        // setInputKey((prev) => !prev);
        inputRef.current.value = "";
        toast.success("file deleted successfully");
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const submitData = async (e) => {
    e.preventDefault();

    const file = !videoDuration
      ? { url: { video: videoUrl } }
      : {
          path: {
            image: imagePath,
            video: videoPath,
          },
          url: { image: imageUrl, video: videoUrl },
          videoDuration,
        };

    const videoToUpload = {
      info: {
        videoId,
        name,
        category,
        explain,
        authors,
        labels,
        creatorId: user.profile.uid,
        groupCode,
        createAt: serverTimestamp(),
      },
      file,
      id: videoId,
    };
    try {
      setPostLoading(true);
      const response = await postVideoDataToDb(videoToUpload);
      toast(response.message);
      setPostLoading(false);
      navigate(`/${user.profile.role}panel/resources/video`);
    } catch (error) {
      toast.error(error.message);
      setPostLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={submitData} className="w-11/12 m-auto">
        <div style={{ padding: "10px", marginTop: "20px" }}>
          <Box className="flex items-center gap-x-3 mb-5">
            <Typography>VIDEO ID : {videoId}</Typography>
            <Button
              component="label"
              role={undefined}
              tabIndex={-1}
              size="small"
            >
              <CloudUploadIcon
                sx={{ fontSize: "40px" }}
                className="text-cu-blue"
              />
              <VisuallyHiddenInput
                type="file"
                onChange={onInputFile}
                accept={"video/*"}
                multiple={false}
                ref={inputRef}
              />
            </Button>
            <button
              type="button"
              disabled={videoDuration}
              onClick={handleClickOpen}
            >
              <AddLinkIcon
                sx={{ fontSize: "40px" }}
                className="text-cu-blue cursor-pointer"
              />
            </button>
            <div className="ml-auto w-80">
              {/* {user.profile.role === "admin" && ( */}
              <GroupCodeSection
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                disabled={
                  user.profile.role === "instructor" ||
                  user.profile.role === "leadInstructor"
                }
              />
              {/* )} */}
            </div>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  variant="outlined"
                  fullWidth
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Category"
                  variant="outlined"
                  fullWidth
                  // required
                />
              </Grid>
            </Grid>
          </Box>

          <div className="flex justify-between">
            <Box mt={5} display="flex" flexDirection="column" gap={2}>
              <AddNamesInput
                placeholder="Authors"
                parentList={authors}
                setParentList={setAuthors}
              />
              <AddNamesInput
                placeholder="Labels"
                parentList={labels}
                setParentList={setLabels}
              />
            </Box>
            {(selectedFile || videoUrl) && (
              <div className="flex flex-col items-center p-3 mt-3">
                <div className="m-auto text-center">
                  {videoUrl && imageUrl && (
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-red-600"
                      onClick={(e) => removeOneSelectedFile()}
                    >
                      X
                    </span>
                  )}

                  <div>
                    <video className="hidden" ref={videoRef} />
                    <canvas className="hidden" ref={canvasRef}></canvas>

                    {selectedFrameUrl && !imageUrl && (
                      <div className="flex items-center justify-center">
                        <img
                          width={200}
                          height={200}
                          src={selectedFrameUrl}
                          alt="Selected frame"
                        />
                      </div>
                    )}
                    {imageUrl && (
                      <div className="flex items-center justify-center">
                        <img
                          width={200}
                          height={200}
                          src={imageUrl}
                          alt="Selected frame"
                        />
                      </div>
                    )}
                    {videoDuration && (
                      <p className="text-end py-5">
                        {" "}
                        Duration: {videoDuration.toFixed(2)} seconds
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={`w-full rounded-full bg-slate-200 mt-2 ${
                    !progressBarContent ? "hidden" : "block"
                  } }`}
                >
                  <div
                    className={` text-center bg-blue-500 rounded-full ${
                      progressBarContent === 100 && "bg-green-500"
                    }`}
                    style={{ width: `${progressBarContent}%` }}
                  >
                    {Math.floor(progressBarContent)}%
                  </div>
                </div>
              </div>
            )}
          </div>

          <TextField
            value={explain}
            onChange={(e) => setExplain(e.target.value)}
            label="Explanation"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
          {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="m-auto"
          disabled={!videoUrl}
        >
          {postLoading && <PostLoadingComp />}
          Submit
        </Button> */}
          <div className="w-96 m-auto">
            <LoadingButton
              loading={postLoading}
              disabled={!videoUrl}
              name={"SUBMIT"}
            />
          </div>
        </div>
      </form>
      {/* Modal for link input */}
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Enter Video Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the URL for the video you want to upload.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Video URL"
            type="url"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewVideo;
