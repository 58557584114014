import { Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import ViewFile from "../../../layout/ViewFile";
import VideoShow from "./showFile/VideoShow";
import PdfShow from "./showFile/PdfShow";
import ImageShow from "./showFile/ImageShow";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

const LogoItem = ({ src, size, item, id, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const lastpart =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const openModal = () => {
    document.body.style.overflowY = "hidden";
    setIsModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };

  // const reqToRemove = async () => {
  //   if (!id || !type) throw new Error("id not found");
  //   if (window.confirm("Are you sure you want to delete this file?")) {
  //     try {
  //       await deleteRealItem(`/${type}/${id}`);
  //       await deleteStorageRecursively(`/resources/${type}/${id}`);
  //       toast.success("successfully deleted");
  //     } catch (error) {
  //       toast.error(error.message);
  //     }

  //     // if (response.status === 200) {
  //     //   // setPdfsData((prev) => prev.filter((pdf) => pdf.id !== item.id));
  //     //   toast.success(response.message);
  //     // } else {
  //     //   toast.error(response.message);
  //     // }
  //   }
  // };
  // const goToEditItem = () => {
  //   navigate(`/adminpanel/resources/${type}/addnew${type}/${id}`);
  // };
  // console.log(item);

  return (
    <Grid
      item
      className="cursor-pointer"
      sx={{
        width: type === "video" ? size * 1.5 : size,
        height: type === "image" || type === "pdf" ? size * 1.5 : size,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* <div
        style={{
          width: size,
          height: size,
          position: "relative",
          border: "1px solid #999",
          overflow: "hidden",
        }}
      > */}
      <div
        title={item?.info?.name}
        className="flex flex-col h-full w-full text-center  rounded-md p-1"
      >
        <div className="h-5/6">
          {src ? (
            <img
              src={src}
              alt="logo"
              onClick={openModal}
              className="contain m-auto max-h-full min-h-full"
              // style={{ maxHeight: "90%" }}
            />
          ) : !src && type === "video" ? (
            <div className="relative w-full h-full" onClick={openModal}>
              <iframe
                // width="1080"
                // height="720"
                src={item?.file?.url?.video}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;modestbranding: 1; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                className="h-full w-full"
              ></iframe>
              <div
                className="absolute inset-0 w-full h-full bg-transparent cursor-pointer"
                onClick={() => {
                  // Your custom function here
                  console.log("Iframe clicked!");
                }}
              ></div>
            </div>
          ) : (
            <div
              className="flex flex-col items-center justify-center"
              onClick={openModal}
            >
              <BrokenImageIcon sx={{ fontSize: `${size / 2}px` }} />
              <Typography>image not found</Typography>
            </div>
          )}
        </div>
        <div className="h-1/6 flex items-center">
          <Typography noWrap>{item?.info?.name}</Typography>
        </div>
      </div>

      <ViewFile
        isOpen={isModalOpen}
        onClose={closeModal}
        title={item?.info?.name || ""}
      >
        {type === "video" ? (
          <VideoShow videoData={item} />
        ) : type === "pdf" ? (
          <PdfShow pdf={item} />
        ) : type === "image" ? (
          <ImageShow imageData={item} />
        ) : (
          ""
        )}
        {/* {type === "pdf" && <PdfShow pdf={item} />}
        {type === "image" && <ImageShow imageData={item} />} */}
      </ViewFile>
    </Grid>
  );
};

export default LogoItem;
