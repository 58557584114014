import React, { useState } from "react";
import ViewFile from "../../../layout/ViewFile";

import { Grid } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import TableShow from "./showFile/TableShow";

const TableLogo = ({ table }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    document.body.style.overflowY = "hidden";
    setIsModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };

  return (
    <Grid
      item
      className="cursor-pointer"
      sx={{
        // width: size,
        // height: size,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* <div
    style={{
      width: size,
      height: size,
      position: "relative",
      border: "1px solid #999",
      overflow: "hidden",
    }}
  > */}
      <div
        onClick={openModal}
        style={{
          width: "200px",
          height: "200px",
          objectFit: "contain",
          margin: "auto",
          textAlign: "center",
          border: "1px solid #999",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TableViewIcon />
        {table.info.title}
      </div>

      <div
        style={{
          // fontSize: size / 10 + "px",
          position: "absolute",
          top: "0px",
          right: "0px",
        }}
        className="text-cu-red bg-slate-300 rounded"
      >
        {/* <DeleteIcon onClick={reqToRemove} />
    <EditIcon className="text-cu-blue" onClick={goToEditItem} /> */}
      </div>
      {/* </IconButton> */}
      {/* </div> */}
      <ViewFile
        isOpen={isModalOpen}
        onClose={closeModal}
        title={table?.info?.title || ""}
      >
        <TableShow table={table} />
      </ViewFile>
    </Grid>
  );
};

export default TableLogo;
