import React from "react";
import { getCourseById } from "../../../redux/coursesSlice";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Link } from "react-router-dom";

const CourseStudentProfile = ({ courseId }) => {
  const course = useSelector((state) => getCourseById(state, courseId));
  const user = useSelector((state) => state.user);

  return (
    <div>
      {/* <div className="border-b h-32 flex items-center relative text-center">
        <div className="flex items-center w-1/5 justify-center">
          <Avatar
            className="object-contain rounded-lg h-16"
            sx={{ width: "64px", objectFit: "cover", height: "auto" }}
            src={course?.files?.courseImage?.url}
            alt={course?.info?.title}
            variant="rounded"
          >
            {!course?.files?.courseImage?.url && (
              <InsertPhotoIcon sx={{ fontSize: "40px" }} />
            )}
          </Avatar>
        </div>

        <div className="w-1/5 text-blue-500"> */}
      <Link
        className="text-blue-500"
        to={`/${user.profile.role}panel/courses/viewcourse/${courseId}`}
      >
        {course?.info?.title}
      </Link>
      ,
      {/* </div>

        <div className="flex flex-col gap-y-2 w-1/5">
          {course?.categoriesName?.length
            ? course?.categoriesName?.map((catName) => <span>, {catName}</span>)
            : "---"}
        </div>


      </div> */}
    </div>
  );
};

export default CourseStudentProfile;
