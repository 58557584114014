import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";

const UserNotification = ({
  user,
  selectedUsers,
  setSelectedUsers,
  setCustomizeSelection,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const isUserSelected =
      selectedUsers &&
      selectedUsers?.some(
        (selectedUser) => selectedUser.profile?.uid === user.profile?.uid
      );
    setChecked(isUserSelected);
  }, [selectedUsers, user.profile?.uid]);

  const clickSingleUser = () => {
    setCustomizeSelection(true);
    setChecked((prevCheck) => {
      if (prevCheck === false) {
        setSelectedUsers((prev) => [...prev, user]);
      } else {
        setSelectedUsers((prev) =>
          prev.filter(
            (selectedUser) => selectedUser.profile.uid !== user.profile.uid
          )
        );
      }
      return !prevCheck;
    });
  };
  return (
    <FormControlLabel
      className="ps-4"
      control={
        <Checkbox
          name={`${user.profile?.name}`}
          checked={checked}
          onChange={() => clickSingleUser()}
        />
      }
      label={`${user.profile?.name} ${user.profile?.family}`}
    />
  );
};

export default UserNotification;
