import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { sendErrorToDb } from "../firebase.config";

function ErrorHandlerComp({ error, resetErrorBoundary }) {
  useEffect(() => {
    const handleError = async () => {
      await sendErrorToDb(error);
    };
    handleError();
  }, []);

  return (
    <Container maxWidth="sm">
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Something went wrong.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {error.message}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          we will resolve your problem soon
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {error.info}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={resetErrorBoundary}
          sx={{ mt: 3 }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  );
}

export default ErrorHandlerComp;
