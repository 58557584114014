import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { getAllNotifications } from "../../../redux/notificationsSlice";
import { ref } from "firebase/storage";
import NotificationRow from "./NotificationRow";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";

const ShowAllNotifications = () => {
  const notifications = useSelector(getAllNotifications);
  const navigate = useNavigate();
  // console.log(notifications);
  return (
    <Paper className="border p-5 m-5">
      <Box className="flex justify-between mb-5">
        <div className="mb-5">
          Notifications :
          <IconButton
            onClick={() => {
              navigate("pushnewnotification");
            }}
            className="ms-3"
          >
            <AddCircleIcon className="text-blue-500" />
          </IconButton>
        </div>
      </Box>
      {notifications?.map((notification, index) => (
        <NotificationRow notification={notification} index={index} />
      ))}
    </Paper>
  );
};

export default ShowAllNotifications;
