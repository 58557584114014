import React from "react";

const LibraryOnline = () => {
  return (
    <div>
      <iframe
        id="myIframe"
        title="shahname"
        src="https://fliphtml5.com/bookcase/rvgqu/"
        style={{ height: "calc(100vh - 88px)" }}
        className="w-full m-auto mt-2"
      ></iframe>
    </div>
  );
};

export default LibraryOnline;
