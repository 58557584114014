import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState();

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUsers: usersAdapter.setAll,
    addManyUsers: usersAdapter.addMany,
    addAssignedCourses: usersAdapter.updateOne,
  },
});

export const { addUsers, addAssignedCourses, addManyUsers } =
  usersSlice.actions;

const selectUsersState = (state) => state.users;

export const getAllUsers = createSelector([selectUsersState], (usersState) =>
  usersAdapter
    .getSelectors()
    .selectAll(usersState)
    .sort((a, b) => b.profile.createAt - a.profile.createAt)
);
export const getUserById = (state, uid) =>
  usersAdapter.getSelectors().selectById(state.users, uid);

export const getUserEntities = (state) =>
  usersAdapter.getSelectors().selectEntities(state.users);

export const getUsersByIds = createSelector(
  [getUserEntities, (state, ids) => ids],
  (entities, ids) =>
    ids
      ?.map((id) => entities[id])
      .sort((a, b) => a.profile.createAt - b.profile.createAt)
);

export const getUsersByGroupcode = (state, groupCode) => {
  const users = state.users.entities && Object.values(state.users.entities);
  return users.filter(
    (user) => user.profile.groupCode && user.profile.groupCode === groupCode
  );
};
export const getUsersByRole = (state, role) => {
  const users = Object.values(state.users.entities);
  return users.filter(
    (user) => user.profile.role && user.profile.role === role
  );
};
export default usersSlice.reducer;
