import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createALesson } from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import CourseAssign from "./CourseAssign";
import { getLessonById } from "../../../redux/lessonsSlice";
import AssignmentAssign from "./assignmentAssign/AssignmentAssign";
import TextAreaComponent from "../sharedComp/TextAreaComponent";
import ShowAddedResources from "../../../panels/shared/sharedComp/addResource/ShowAddedResources";
import GroupCodeSection from "../../../panels/shared/sharedComp/GroupCodeSection";
import { Button } from "@mui/material";

const EditLesson = () => {
  const { lessonId } = useParams();
  let lesson = useSelector((state) => getLessonById(state, lessonId));
  const user = useSelector((state) => state.user);
  const [groupCode, setGroupCode] = useState(
    lesson?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "01")
  );
  const [loading, setLoading] = useState(false);
  const [inputDirection, setInputDirection] = useState(
    lesson?.info?.titleDirection || "rtl"
  );

  const navigate = useNavigate();
  const location = useLocation();
  const creatorEmail = useSelector((state) => state.user.profile.email);

  const lastpart =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const dispatch = useDispatch();
  // const [visibility, setVisbility] = useState(lesson.info.visibility);
  const [title, setTitle] = useState(lesson?.info.title);
  const [explain, setExplain] = useState(lesson?.info.explain);
  const [nameDirection, setNameDirection] = useState(
    lesson?.info?.nameDirection || "rtl"
  );
  const [name, setName] = useState(lesson?.info?.name);
  const publishLesson = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    const lessonToSend = {
      ...lesson,
      info: {
        lessonId,
        // visibility,
        creatorId: user.profile.uid,
        title,
        explain,
        titleDirection: inputDirection,
        editAt: Date.now(),
        createAt: lesson.info.createAt,
        lessonCreator: creatorEmail,
        status: "published",
        rowId: lesson.info.rowId,
        groupCode,
        name,
        nameDirection,
      },
    };
    setLoading(true);
    await createALesson(lessonToSend);
    setLoading(false);
    toast.success("successfully add new lesson");
    navigate(`/${user.profile.role}panel/lessons`);
  };

  return (
    <div className="flex ">
      <div className="w-8/12 mx-5 mt-5">
        <div className="mb-3 flex justify-between">
          <h2>Edit Lesson</h2>
          <h2>{lessonId}</h2>
        </div>
        <TextAreaComponent
          props={{
            explain,
            setTitle,
            title,
            inputDirection,
            setInputDirection,
            setExplain,
            groupCode,
            name,
            nameDirection,
            setName,
            setNameDirection,
          }}
        />
        <ShowAddedResources
          section={lesson}
          sectionName={"lessons"}
          sectionId={lessonId}
          groupCode={lesson.info.groupCode}
        />
      </div>

      <div className="w-3/12 mt-12 flex flex-col gap-y-5">
        {user.profile.role === "admin" && (
          <GroupCodeSection groupCode={groupCode} setGroupCode={setGroupCode} />
        )}
        <div className="p-5 border flex flex-col gap-y-2">
          <CourseAssign lessonId={lessonId} />
        </div>
        <div className="p-5 border flex flex-col gap-y-2">
          <AssignmentAssign lesson={lesson} />

          <Button
            variant="contained"
            className="flex w-24 self-end"
            sx={{ marginTop: "20px" }}
            onClick={publishLesson}
          >
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditLesson;
