import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { timeAgo } from "../../../shared/utils/timeAgo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { setNotificationSeen } from "../../../firebase.config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";
const EachNotification = ({ notification }) => {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const handleToggle = async () => {
    setOpen(!open);
    if (!notification.seen) {
      try {
        await setNotificationSeen({ notification, uid: user.profile.uid });
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  return (
    <Alert
      onClick={handleToggle}
      severity={notification.alert}
      className="relative break-words cursor-pointer border-b-2 border-b-cu-blue"
      style={{ paddingTop: "25px" }}
    >
      <AlertTitle
        sx={{ fontWeight: "700", fontSize: "18px" }}
        dir={notification.inputDirction}
      >
        {notification.title}
      </AlertTitle>
      <div className="absolute top-0 left-3 text-slate-500 text-xs">
        <span>{timeAgo(new Date(notification.createAt))}</span>
        <IconButton
          aria-label="expand"
          size="small"
          // onClick={handleToggle}
          className=""
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <div className="absolute top-0 right-3 text-slate-500 text-xs">
        {notification.seen ? (
          <DoneAllIcon sx={{ color: "green" }} />
        ) : (
          <CheckIcon />
        )}
      </div>
      <Collapse in={open} dir={notification.textDirection}>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {notification.text}
        </Typography>
      </Collapse>
      <Box dir={notification.textDirection}>
        {!open && <Typography noWrap>{notification.text}</Typography>}
      </Box>
    </Alert>
  );
};

export default EachNotification;
