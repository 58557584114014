import React, { useEffect, useRef, useState } from 'react'

const Pubmed = () => {


    const iframeRef = useRef()
    // const [iframeHeight, setIframeHeight] = useState("500px")

    // useEffect(() => {
    //     const handleMessage = (e) => {
    //         console.log(e.data)
    //         // if (e.origin === "https://pubmed-160b9.firebaseapp.com/") {
    //         var message = e.data;
    //         let iheight = message.height + 200 + 'px';
    //         setIframeHeight(iheight)
    //         // }
    //     }

    //     window.addEventListener("message", handleMessage)

    //     return () => {
    //         window.removeEventListener('message', handleMessage);
    //     };
    // }, [])

    return (
        <div>
            <iframe title='pubmed' ref={iframeRef} src="https://pubmed-160b9.firebaseapp.com/" style={{ height: "600px" }} className='w-11/12 m-auto'></iframe>
        </div>
    )
}

export default Pubmed
