import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPublicInfoById } from "../../redux/publicInfoSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faUser } from "@fortawesome/free-regular-svg-icons";
const HomeCoursesLogos = () => {
  const courses = useSelector((state) =>
    getPublicInfoById(state, "homeCourses")
  );
  const modiCourse = courses
    ? Object.values(courses).filter((course) => typeof course !== "string")
    : [];
  const navigate = useNavigate();
  const viewCourse = (course) => {
    navigate(`/view/course/${course.info.courseId}`);
  };
  return (
    <div className="flex gap-10 flex-wrap px-3 justify-center">
      {modiCourse.map((course) => (
        <div className="bg-white w-72 cursor-pointer shadow-lg ">
          <div
            className="h-48 flex justify-center items-center"
            onClick={() => {
              viewCourse(course);
            }}
          >
            <img
              className="contain overflow-hidden h-full"
              src={course?.files?.courseImage?.url}
              alt={course.info.title}
            />
          </div>
          <div className="mt-3 mb-5 mx-6">
            <p className="flex flex-wrap">{course.categoriesName}</p>
            <p>{course.info.title}</p>
            <br />
            <div className="border"></div>
            <div className="flex justify-between text-sm">
              {/* <del>S0.00</del>
                  <span> Free </span>
                  <span>
                    <FontAwesomeIcon icon={faMessage} /> 0
                  </span> */}
              <span>
                <FontAwesomeIcon icon={faClock} />
                {course.general.duration + " " + course.general.durationUnit}
              </span>
              <span>
                <FontAwesomeIcon icon={faUser} />
                {course?.assignedStudent &&
                  Object.keys(course?.assignedStudent).length}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeCoursesLogos;
