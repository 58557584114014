import React, { useState } from "react";
import { Box, Typography, Button, Stack, Avatar } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getAllCourses } from "../../../redux/coursesSlice";
import { sendHomeData } from "../../../firebase.config";
import { toast } from "react-toastify";
import { getUsersByRole } from "../../../redux/usersSlice";

const PuShowAllInstructors = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const instructors = useSelector((state) =>
    getUsersByRole(state, "instructor")
  );
  const toggleCourseCheck = (instructor) => {
    setCheckedItems((prevCheckedItems) => {
      const isChecked = prevCheckedItems.some(
        (item) => item?.profile?.uid === instructor?.profile?.uid
      );
      if (isChecked) {
        // Uncheck: Filter out the item
        return prevCheckedItems.filter(
          (item) => !(item?.profile?.uid === instructor?.profile?.uid)
        );
      } else {
        // Check: Add the item
        return [...prevCheckedItems, instructor];
      }
    });
  };

  const isChecked = (uid) => {
    return checkedItems.some((item) => item?.profile?.uid === uid);
  };

  const sendData = async () => {
    try {
      await sendHomeData(checkedItems, "/publicInfo/homeInstructors");
      toast.success("saved data successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Box className="w-full">
      <Box className="h-96 border rounded-md overflow-auto">
        {/* Header Row */}
        <Box className="flex h-12 bg-gray-200 items-center px-4 font-semibold">
          <Box className="w-1/12">pic</Box>
          <Box className="w-3/12">name</Box>
          <Box className="w-3/12">email</Box>
          {/* <Box className="w-2/12">Categories</Box> */}
          <Box className="w-2/12">Group Code</Box>
          <Box className="w-2/12 text-center">Select</Box>
        </Box>

        {/* Data Rows */}
        {instructors.map((ins) => (
          <Box
            key={ins.profile.uid}
            className="flex h-12 border-b items-center px-4 hover:bg-gray-100"
          >
            {/* Image */}
            <Box className="w-1/12">
              <Avatar
                src={ins.profile.photoUrl.url}
                alt={ins.profile.email}
                // variant="rounded"
                className="h-10 w-10"
              />
            </Box>

            {/* Title */}
            <Box className="w-3/12">
              <Typography variant="body2">{ins.profile.name}</Typography>
            </Box>

            {/* Name */}
            <Box className="w-3/12">
              <Typography variant="body2">{ins.profile.email}</Typography>
            </Box>

            {/* Categories */}
            {/* <Box className="w-2/12">
              <Typography variant="body2">{course.categoriesName}</Typography>
            </Box> */}

            {/* Group Code */}
            <Box className="w-2/12">
              <Typography variant="body2">{ins.profile.groupCode}</Typography>
            </Box>

            {/* Toggle Button */}
            <Box className="w-2/12 text-center">
              <Button
                variant="contained"
                color={isChecked(ins.profile.uid) ? "success" : "primary"}
                onClick={() => toggleCourseCheck(ins)}
                size="small"
                className="capitalize"
                startIcon={
                  isChecked(ins.profile.uid) ? <CheckIcon /> : <CloseIcon />
                }
              >
                {isChecked(ins.profile.uid) ? "Checked" : "Check"}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <div className="flex items-center justify-center mt-4">
        <Button onClick={sendData} className="">
          Save Changes
        </Button>
      </div>
    </Box>
  );
};

export default PuShowAllInstructors;
