import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createAAssignment } from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LessonAssign from "./LessonAssign";
import {
  createEmptyAssignment,
  removeNotPublishedAssignment,
} from "../../../redux/assignmentsSlice";
import { getAssignmentById } from "../../../redux/assignmentsSlice";

import GroupCodeSection from "../sharedComp/GroupCodeSection";
import { Button } from "@mui/material";
import ShowAddedResources from "../sharedComp/addResource/ShowAddedResources";
import TextAreaComponent from "../sharedComp/TextAreaComponent";
import { serverTimestamp } from "firebase/database";

const AddNewAssignment = () => {
  const { assignmentId } = useParams();
  let assignment = useSelector((state) =>
    getAssignmentById(state, assignmentId)
  );
  const user = useSelector((state) => state.user);
  const [groupCode, setGroupCode] = useState(
    assignment?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "")
  );

  const newAssignmentInitialState = {
    info: {
      assignmentId,
      // visibility: "Public",
      title: "",
      explain: "",
      editAt: "",
      createAt: serverTimestamp(),
      assignmentCreator: "",
      status: "temp",
      titleDirection: "rtl",
      creatorId: user.profile.uid,
      groupCode: user.profile.groupCode || groupCode,
      name: "",
      nameDirection: "rtl",
    },

    releaseDate: { immediately: true, date: serverTimestamp() },
    resources: "",
    assignedLessons: "",
  };
  const [inputDirection, setInputDirection] = useState(
    assignment?.info?.titleDirection || "rtl"
  );

  const navigate = useNavigate();
  const location = useLocation();
  const creatorEmail = useSelector((state) => state.user?.profile?.email);
  if (!assignment) {
    assignment = newAssignmentInitialState;
  }

  const dispatch = useDispatch();
  // const [visibility, setVisbility] = useState(assignment.info.visibility);
  const [title, setTitle] = useState(assignment?.info?.title);
  const [nameDirection, setNameDirection] = useState(
    assignment?.info?.nameDirection || "rtl"
  );
  const [name, setName] = useState(assignment?.info?.name);
  const [explain, setExplain] = useState(assignment?.info?.explain);

  // const [assignmentId, setAssignmentId] = useState(assignment.info.assignmentId);

  useEffect(() => {
    if (assignmentId) {
      dispatch(
        createEmptyAssignment({
          id: assignmentId,
          ...newAssignmentInitialState,
        })
      );
    }
    return () => {
      if (assignment?.info?.status === "temp") {
        dispatch(removeNotPublishedAssignment(assignmentId));
      }
    };
  }, [assignmentId, dispatch, groupCode]);

  const publishAssignment = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    if (!groupCode) {
      toast.error("you must specify group code");
      return;
    }
    const assignmentToSend = {
      ...assignment,
      info: {
        assignmentId,
        title,
        explain,
        editAt: "",
        createAt: serverTimestamp(),
        assignmentCreator: creatorEmail,
        status: "published",
        titleDirection: inputDirection,
        creatorId: user.profile.uid,
        groupCode,
        name,
        nameDirection,
      },
    };
    await createAAssignment(assignmentToSend);
    navigate(`/${user.profile.role}panel/assignments`);
    toast.success("successfully add new assignment");
  };

  return (
    <div className="flex">
      <div className="w-9/12 mx-5 mt-5">
        <div className="mb-3 flex justify-between">
          <h2>Add New Assignment</h2>
          <h2>{assignmentId}</h2>
        </div>
        <TextAreaComponent
          props={{
            explain,
            setTitle,
            title,
            inputDirection,
            setInputDirection,
            setExplain,
            groupCode,
            name,
            setName,
            nameDirection,
            setNameDirection,
          }}
        />
        <ShowAddedResources
          section={assignment}
          sectionName={"assignments"}
          sectionId={assignmentId}
          status={assignment?.info?.status}
          groupCode={assignment?.info?.groupCode}
        />
      </div>

      <div className="w-2/12 mt-12 flex flex-col gap-y-5">
        {user.profile.role === "admin" && (
          <GroupCodeSection groupCode={groupCode} setGroupCode={setGroupCode} />
        )}
        <div className="p-5 border flex flex-col gap-y-2">
          <LessonAssign assignmentId={assignmentId} />
        </div>

        <Button className="border w-24 self-end" onClick={publishAssignment}>
          Publish
        </Button>
      </div>
    </div>
  );
};

export default AddNewAssignment;
