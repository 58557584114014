import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  styled,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = ["instructor", "student", "staff"];
const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    whiteSpace: "normal",
  },
});
export default function SelectRole({ selectedRole, setSelectedRole }) {
  //   const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRole(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-checkbox-label">Role</InputLabel>
      <StyledSelect
        labelId="multiple-checkbox-label"
        label="Role"
        multiple
        value={selectedRole}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedRole.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}
