import React, { useEffect, useState } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { getUsersByIds } from "../../redux/usersSlice";
import ViewFile from "../../layout/ViewFile";
import ShowCourseContent from "./ShowCourseContent";
import { useNavigate } from "react-router-dom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
const CourseRowSt = ({ course }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeComp = (comp) => {
    setCurrentComp(comp);
  };
  const [currentComp, setCurrentComp] = useState(
    <ShowCourseContent
      changeComp={changeComp}
      courseId={course.info.courseId}
    />
  );
  const navigate = useNavigate();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const changeComp = (comp) => {
  //   setCurrentComp(comp);
  // };
  // const [currentComp, setCurrentComp] = useState("");
  // const closeModal = () => {
  //   document.body.style.overflowY = "scroll";
  //   setIsModalOpen(false);
  // };

  const openCourse = () => {
    navigate(`/studentpanel/courses/viewcourse/${course?.info.courseId}`);
  };

  const openCourseContent = () => {
    setIsModalOpen(true);
    setCurrentComp(
      <ShowCourseContent
        changeComp={changeComp}
        courseId={course.info.courseId}
      />
    );
    // window.history.pushState(
    //   {},
    //   "",
    //   `/studentpanel/courses/viewcourse/${course?.info.courseId}`
    // );
  };

  const instructorIds = course?.assignedInstructor
    ? Object.keys(course?.assignedInstructor)
    : [];
  const instructors = useSelector((state) =>
    getUsersByIds(state, instructorIds)
  );

  const closeModal = () => {
    document.body.style.overflowY = "scroll";
    setIsModalOpen(false);
  };
  return (
    <TableRow key={course?.info.courseId}>
      <TableCell>{course?.info.title}</TableCell>
      <TableCell>
        {" "}
        {instructors?.map((ins, index) => (
          <span key={index}>
            {ins?.profile?.name} {ins?.profile?.family} ,
          </span>
        ))}
      </TableCell>
      {/* <TableCell>{course?.categoriesName}</TableCell> */}
      {/* <TableCell>{course?.info?.createAt}</TableCell> */}
      <TableCell>
        {course?.general?.duration} {course?.general?.durationUnit}
      </TableCell>
      {/* <TableCell>{course?.general?.prerequisite}</TableCell> */}
      <TableCell>{course?.general?.level}</TableCell>
      <TableCell>{course?.general?.unit}</TableCell>
      <TableCell>
        {(course?.reportCards &&
          course?.reportCards[course?.info?.courseId] &&
          course?.reportCards[course?.info?.courseId]?.status) || (
          <HourglassTopIcon sx={{ color: "#002868" }} />
        )}
      </TableCell>
      <TableCell>
        {(course?.reportCards &&
          course?.reportCards[course?.info.courseId] &&
          course?.reportCards[course?.info.courseId]?.grade) ||
          "---"}
      </TableCell>
      <TableCell>
        {/* <div className="flex  gap-x-2 "> */}
        <Button title="continue to course" size="small" onClick={openCourse}>
          <VisibilityIcon className="text-cu-blue" />
        </Button>
      </TableCell>

      <TableCell>
        <Button
          title="continue to content of course"
          size="small"
          onClick={openCourseContent}
        >
          <SmartDisplayIcon className="text-cu-blue" />
        </Button>
      </TableCell>
      <ViewFile
        isOpen={isModalOpen}
        onClose={closeModal}
        title={course?.info?.title || ""}
      >
        {currentComp}
      </ViewFile>
      {/* </div> */}
      {/* <ViewFile
          isOpen={isModalOpen}
          onClose={closeModal}
          title={course?.info?.title || ""}
        >
          {currentComp}
        </ViewFile> */}
    </TableRow>
  );
};

export default CourseRowSt;
