import React, { useState } from "react";
import AddResources from "./AddResources";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  addNewResource,
  getLessonById,
  uploadResourceId,
} from "../../../../redux/lessonsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addResourceToLCADB,
  createEmptyAssignmentInDb,
  createEmptyCourseInDb,
  createEmptyLessonInDb,
} from "../../../../firebase.config";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toast } from "react-toastify";
const ShowAddedResources = ({
  section,
  sectionName,
  sectionId,
  status,
  groupCode,
}) => {
  const addNewResources = async () => {
    if (!sectionId || !groupCode) {
      toast.error("please specify group code and id and try again");
      return;
    }
    try {
      if (status === "temp" && sectionName === "lessons") {
        await createEmptyLessonInDb({ lesson: section });
      } else if (status === "temp" && sectionName === "courses") {
        await createEmptyCourseInDb({ course: section });
      } else if (status === "temp" && sectionName === "assignments") {
        await createEmptyAssignmentInDb({ assignment: section });
      }
      const itemNum = section?.resources
        ? Object.values(section?.resources).length + 1
        : 1;
      await addResourceToLCADB({
        resourceData: {
          type: "",
          id: "",
          resourceId: itemNum,
        },
        sectionId,
        sectionName,
        groupCode,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  // uploadResourceId({
  //     lessonId,
  //     resourceId,
  //     item: { type: "", id: "", resourceId },
  //   })
  return (
    <div>
      {section?.resources &&
        Object.values(section.resources).map((resource) => (
          <AddResources
            resource={resource}
            sectionName={sectionName}
            sectionId={sectionId}
            section={section}
            key={resource?.resourceId}
            groupCode={groupCode}
          />
        ))}
      <div className="flex items-center justify-center my-6">
        <Button onClick={addNewResources}>
          Add Resources <AddCircleOutlineIcon />
        </Button>
      </div>
    </div>
  );
};

export default ShowAddedResources;
