import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAssignmentsByIds } from "../../../redux/assignmentsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EachAssignmentContentDrip from "./EachAssignmentContentDrip";

const EachLessonContentDrip = ({
  lesson,
  setLessonDateList,
  setAssignmentDateList,
  lessonDateList,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(lesson?.releaseDate.date)
  );
  const [immediately, setImmediately] = useState(
    lesson?.releaseDate.immediately
  );

  const assignedAssignments = lesson?.assignedAssignments
    ? Object.keys(lesson.assignedAssignments)
    : [];
  const assignmentInLesson = useSelector((state) =>
    getAssignmentsByIds(state, [...assignedAssignments])
  );

  const changePublishType = (e) => {
    let currentImmediate;
    if (e.target.value === "immediately") {
      setImmediately(true);
      currentImmediate = true;
    } else {
      setImmediately(false);
      currentImmediate = false;
    }
    setLessonDateList((prev) => {
      const lessonInList = prev?.find((ll) => ll.id === lesson.info.lessonId);

      if (lessonInList && currentImmediate === lesson.releaseDate.immediately) {
        return prev?.filter((ll) => ll.id !== lesson.info.lessonId);
      } else if (
        !lessonInList &&
        currentImmediate !== lesson.releaseDate.immediately
      ) {
        return [
          ...prev,
          {
            id: lesson.info.lessonId,
            date: Date.now(selectedDate),
            immediately: currentImmediate,
          },
        ];
      } else {
        return prev;
      }
    });
  };

  const changeDate = (date) => {
    if (!immediately) {
      setSelectedDate(new Date(date));
      setLessonDateList((prev) => {
        const lessonInList = prev?.find((ll) => ll.id === lesson.info.lessonId);

        if (lessonInList && date.getTime() === lesson?.releaseDate.date) {
          return prev?.filter((ll) => ll.id !== lesson.info.lessonId);
        } else if (
          lessonInList &&
          date.getTime() !== lesson?.releaseDate.date
        ) {
          return prev?.map((ll) =>
            ll.id === lesson.info.lessonId
              ? {
                  ...ll,
                  date: date.getTime(),
                }
              : ll
          );
        } else if (
          !lessonInList &&
          date.getTime() !== lesson?.releaseDate.date
        ) {
          return [
            ...prev,
            {
              id: lesson.info.lessonId,
              date: date.getTime(),
              immediately,
            },
          ];
        } else {
          return prev;
        }
      });
    }
  };

  //   useEffect(() => {

  //   }, [
  //     immediately,
  //     lesson.info.lessonId,
  //     lesson.releaseDate.immediately,
  //     selectedDate,
  //     setLessonDateList,
  //   ]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-3">
        <div className="flex justify-around w-96">
          <p>{lesson?.info.lessonId}</p>
          <p>{lesson?.info.title}</p>
        </div>
        <div className="w-96 flex justify-between">
          <select
            onChange={changePublishType}
            defaultValue={immediately ? "immediately" : "specificDate"}
          >
            <option value="immediately">Immediately</option>
            <option value="specificDate">Specific Date</option>
          </select>
          {!immediately ? (
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                changeDate(date);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="Pp"
            />
          ) : (
            <p></p>
          )}
        </div>
      </div>
      {assignmentInLesson.map((assignment, index) => (
        <EachAssignmentContentDrip
          key={`assignment-${index}`}
          assignment={assignment}
          setAssignmentDateList={setAssignmentDateList}
        />
      ))}
    </div>
  );
};

export default EachLessonContentDrip;
